import {combineReducers} from 'redux';

import Auth from '../ducks/auth';
import Bookings from '../ducks/bookings';
import Buildings from '../ducks/buildings';
import Companies from '../ducks/companies';
import Events from '../ducks/events';
import Instructors from '../ducks/instructors';
import Session from '../ducks/session';
import Tags from '../ducks/tags';
import Templates from '../ducks/templates';
import Tenants from '../ducks/tenants';
import User from '../ducks/user';

export default combineReducers({
  auth: Auth.reducer,
  bookings: Bookings.reducer,
  buildings: Buildings.reducer,
  companies: Companies.reducer,
  events: Events.reducer,
  instructors: Instructors.reducer,
  session: Session.reducer,
  tags: Tags.reducer,
  templates: Templates.reducer,
  tenants: Tenants.reducer,
  user: User.reducer
});
