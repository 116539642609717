import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

const FloatingActionButton = ({disabled, title, userClassName, userStyle, onClick}) => {
  const fabClassNames = classNames({
    [userClassName]: userClassName,
    [styles.fab]: true,
    [styles.disabled]: disabled
  });

  return (
    <div
      className={fabClassNames}
      style={userStyle}
      title={title}
      onClick={disabled ? null : onClick}
    />
  );
};

FloatingActionButton.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onClick: PropTypes.func
};

FloatingActionButton.defaultProps = {
  disabled: false,
  title: '',
  userClassName: null,
  userStyle: null,
  onClick: null
};

export default FloatingActionButton;
