// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2_jySa2_{position:fixed;top:0;left:0;width:100%;height:100%;z-index:1000;-js-display:flex;display:flex;justify-content:center;align-items:center;background-color:rgba(0,0,0,.78)}._2w51Xw0r{background-color:#fff}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,cAAe,CACf,KAAM,CACN,MAAO,CACP,UAAW,CACX,WAAY,CACZ,YAAa,CACb,gBAAa,CAAb,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,gCACF,CACA,WACE,qBACF","file":"styles.less","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 1000;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.78);\n}\n.modal {\n  background-color: #fff;\n}\n"]}]);
// Exports
exports.locals = {
	"overlay": "_2_jySa2_",
	"modal": "_2w51Xw0r"
};
module.exports = exports;
