import {put, takeLatest} from 'redux-saga/effects';

import Session from '../ducks/session';
import User from '../ducks/user';

function * handleFetchCommonData() {
  // TODO
}

function * handleLogIn() {
  yield put(Session.actions.fetchCommonData());
}

function * handleLogOut() {
  yield put(User.actions.clear());
}

export default function * session() {
  yield takeLatest(Session.actionTypes.FETCH_COMMON_DATA.ACTION, handleFetchCommonData);
  yield takeLatest(Session.actionTypes.LOG_IN.ACTION, handleLogIn);
  yield takeLatest(Session.actionTypes.LOG_OUT.ACTION, handleLogOut);
}
