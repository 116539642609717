import firebase from 'firebase/app';
import React, {useRef, useState} from 'react';

import {getCreateInstructorErrors} from '../../../lib/form/error-getters';
import {ADD_INSTRUCTOR_FORM_FIELDS} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';

import {createFullName, exposeFields, generateUid} from '../../../lib/helper';

import useFirebaseEntity from '../../../hooks/use-firebase-entity';

import ContentContainer from '../../common/content-container';
import ContentTable from '../../common/content-table';
import Dialog from '../../common/dialog';
import Form from '../../common/form';
import Page from '../../common/page';
import Sidebar from '../../common/sidebar';

import styles from './styles.less';

function _getPhotoUrlObject(photoUrl) {
  if (!photoUrl) {
    return null;
  }

  const fileName = photoUrl.split('/userPhotos%2F').slice(-1)[0].split('?')[0];

  return {
    name: fileName,
    size: 30720, // Hard-coded to not change data structure (size is not stored)
    type: (fileName.toLowerCase() === 'png') ? 'image/png' : 'image/jpeg',
    url: photoUrl
  };
}

const UsersPage = () => {
  const [
    items,
    {hasFetched, isCreating, isUpdating},
    {create, remove, update}
  ] = useFirebaseEntity('users', [['roles', 'instructor', 'array-contains']]);

  const formSubmitCallbackRef = useRef(null);

  const [search, setSearch] = useState('');
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [{
    style: {
      textAlign: 'left'
    },
    title: 'Name'
  }];

  const rows = items.map(({id, ...rest}) => ({
    key: id,
    data: {
      id,
      ...rest
    }
  }));

  const filterRow = data => {
    return data.name.toLowerCase().includes(search.toLowerCase());
  };

  const renderRowCell = (data, index) => {
    switch (index) {
    case 0:
      return (
        <div className={styles['cell-name']}>
          <div className={styles['photo-wrapper']}>
            {
              data.photoUrl ? (
                <img className={styles.photo} src={data.photoUrl}/>
              ) : (
                <div className={styles['photo-placeholder']}/>
              )
            }
          </div>
          <div className={styles.name}>{data.name}</div>
        </div>
      );

    default:
      return null;
    }
  };

  const handleAddButtonClick = () => {
    setSelectedRow(null);
    setDialogVisible(true);
  };

  const handleRowClick = ({data}) => {
    setSelectedRow(data);
    setDialogVisible(true);
  };

  const handleFormSubmit = async data => {
    const name = createFullName(data.firstName, data.lastName);
    const email = `${name.replace(/[^a-zA-Z0-9]+/g, '.')}@example.com`;
    const photoUrl = data?.photoUrl?.url || null;

    if (selectedRow) {
      const success = await update(selectedRow.id, {
        ...data,
        email,
        name,
        photoUrl
      });

      if (!success) {
        throw new Error('Please, try again');
      }
    } else {
      const id = await create({
        ...data,
        email,
        name,
        photoUrl,
        buildingId: null,
        companyId: null,
        gender: null,
        roles: ['instructor'],
        tenantId: null
      }, generateUid());

      if (!id) {
        throw new Error('Please, try again');
      }
    }

    setDialogVisible(false);
  };

  const handleRemove = async () => {
    const eventsSnapshot = await firebase.firestore().collection('events')
      .where('instructor.id', '==', selectedRow.id)
      .get();

    if (eventsSnapshot.docs.length > 0) {
      // eslint-disable-next-line no-alert
      alert('There are some Events referencing this Instructor. Deletion is not possible');

      return;
    }

    const templatesSnapshot = await firebase.firestore().collection('templates')
      .where('instructorId', '==', selectedRow.id)
      .get();

    if (templatesSnapshot.docs.length > 0) {
      // eslint-disable-next-line no-alert
      alert('There are some Templates referencing this Instructor. Deletion is not possible');

      return;
    }

    const success = await remove(selectedRow.id);

    if (!success) {
      throw new Error('Please, try again');
    }

    setDialogVisible(false);
  };

  const defaultValues = selectedRow ? {
    ...exposeFields(selectedRow, 'firstName', 'lastName'),
    photoUrl: _getPhotoUrlObject(selectedRow.photoUrl)
  } : {
    firstName: '',
    lastName: '',
    photoUrl: null
  };

  return (
    <Page title="Instructors">
      <div className={styles.content}>
        <Sidebar
          items={[{
            path: '/instructors',
            text: 'Instructors'
          }]}
        />
        <ContentContainer
          addButtonText="New Instructor"
          search={search}
          title="Manage Instructors"
          onAddButtonClick={handleAddButtonClick}
          onSearchChange={setSearch}
        >
          <ContentTable
            columns={columns}
            hasFetched={hasFetched}
            rows={rows}
            filterRow={filterRow}
            renderRowCell={renderRowCell}
            onRowClick={handleRowClick}
          />
        </ContentContainer>
        {
          isDialogVisible && (
            <Dialog
              buttonAcceptDisabled={isCreating || isUpdating}
              buttonAcceptText={selectedRow ? 'Save' : 'Create Instructor'}
              buttonRemoveText="Delete Instructor"
              title={selectedRow ? 'Edit Instructor' : 'Add a New Instructor'}
              onAccept={() => formSubmitCallbackRef.current()}
              onReject={() => setDialogVisible(false)}
              onRemove={selectedRow && handleRemove}
            >
              <div className={styles['dialog-content']}>
                <div className={styles['dialog-form-wrapper']}>
                  <Form
                    defaultValues={defaultValues}
                    fields={ADD_INSTRUCTOR_FORM_FIELDS}
                    submitCallbackRef={formSubmitCallbackRef}
                    onSubmit={handleFormSubmit}
                    onSubmitFailure={handleSubmitFailure(getCreateInstructorErrors)}
                  />
                </div>
              </div>
            </Dialog>
          )
        }
      </div>
    </Page>
  );
};

export default UsersPage;
