import React, {useRef, useState} from 'react';

import useFirebaseEntity from '../../../hooks/use-firebase-entity';

import {getCreateAuthorErrors} from '../../../lib/form/error-getters';
import {CREATE_AUTHOR_FORM_FIELDS} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';

import {exposeFields, formatDate} from '../../../lib/helper';

import Dialog from '../../common/dialog';
import Form from '../../common/form';
import ContentContainer from '../../common/content-container';
import ContentSidebar from '../../common/content-sidebar';
import ContentTable from '../../common/content-table';
import Page from '../../common/page';

import styles from './styles.less';

const AuthorsPage = () => {
  const [
    items,
    {hasFetched, isCreating, isRemoving, isUpdating},
    {create, remove, update}
  ] = useFirebaseEntity('authors', [], item => ({
    ...item,
    createdAt: item.createdAt.toDate()
  }));

  const formSubmitCallbackRef = useRef(null);

  const [search, setSearch] = useState('');
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [{
    style: {
      textAlign: 'left'
    },
    title: 'Name'
  }, {
    style: {
      width: '150px'
    },
    title: 'Date Created'
  }, {
    style: {
      width: '120px'
    },
    title: '# of Content Posts'
  }];

  const rows = items.map(({id, ...rest}) => ({
    key: id,
    data: {
      id,
      ...rest
    }
  }));

  const filterRow = data => {
    return data.name.toLowerCase().includes(search.toLowerCase());
  };

  const renderRowCell = (data, index) => {
    switch (index) {
    case 0:
      return (
        <div className={styles['cell-name']}>
          <div className={styles['photo-wrapper']}>
            <img className={styles.photo} src={data.photo.url}/>
          </div>
          <div className={styles.name}>{data.name}</div>
        </div>
      );
    case 1:
      return formatDate(data.createdAt);
    case 2:
      return data.contentCount || (
        <div className={styles['cell-content-count-zero']}>-</div>
      );
    default:
      return null;
    }
  };

  const handleAddButtonClick = () => {
    setSelectedRow(null);
    setDialogVisible(true);
  };

  const handleRowClick = ({data}) => {
    setSelectedRow(data);
    setDialogVisible(true);
  };

  const handleRemove = async () => {
    const success = await remove(selectedRow.id);

    if (!success) {
      throw new Error('Please, try again');
    }

    setDialogVisible(false);
  };

  const handleFormSubmit = async data => {
    if (selectedRow) {
      const success = await update(selectedRow.id, data);

      if (!success) {
        throw new Error('Please, try again');
      }
    } else {
      const id = await create({
        ...data,
        contentCount: 0,
        createdAt: new Date()
      });

      if (!id) {
        throw new Error('Please, try again');
      }
    }

    setDialogVisible(false);
  };

  return (
    <Page title="Authors">
      <div className={styles.content}>
        <ContentSidebar/>
        <ContentContainer
          addButtonText="New Author"
          search={search}
          title="Manage Content Authors"
          onAddButtonClick={handleAddButtonClick}
          onSearchChange={setSearch}
        >
          <ContentTable
            columns={columns}
            hasFetched={hasFetched}
            rows={rows}
            filterRow={filterRow}
            renderRowCell={renderRowCell}
            onRowClick={handleRowClick}
          />
        </ContentContainer>
        {
          isDialogVisible && (
            <Dialog
              buttonAcceptDisabled={isCreating || isRemoving || isUpdating}
              buttonAcceptText={selectedRow ? 'Save' : 'Create Author'}
              buttonRemoveText="Delete Author"
              title={selectedRow ? 'Edit Author' : 'Add a New Author'}
              onAccept={() => formSubmitCallbackRef.current()}
              onReject={() => setDialogVisible(false)}
              onRemove={selectedRow && handleRemove}
            >
              <div className={styles['dialog-content']}>
                <div className={styles['dialog-form-wrapper']}>
                  <Form
                    defaultValues={selectedRow ? exposeFields(selectedRow, 'name', 'photo') : {}}
                    fields={CREATE_AUTHOR_FORM_FIELDS}
                    submitCallbackRef={formSubmitCallbackRef}
                    onSubmit={handleFormSubmit}
                    onSubmitFailure={handleSubmitFailure(getCreateAuthorErrors)}
                  />
                </div>
              </div>
            </Dialog>
          )
        }
      </div>
    </Page>
  );
};

export default AuthorsPage;
