// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".DiLTEcgt{padding:40px 56px;flex-grow:1}.IsewVZC7{-js-display:flex;display:flex;justify-content:space-between;align-items:center}._2W2fkIYg{margin-top:40px}._2EBTsB0h{font-size:31px;font-weight:700;font-stretch:normal;font-style:normal;line-height:40px;letter-spacing:-.3px;color:#111214}._1M0zQSeW{-js-display:flex;display:flex;align-items:center}.Hwu-4tRf{margin-right:11px;width:328px}._3-boJqJ1{height:48px}.c-VD_nGA{margin-top:40px;width:100%}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,UACE,iBAAkB,CAClB,WACF,CACA,UACE,gBAAa,CAAb,YAAa,CACb,6BAA8B,CAC9B,kBACF,CACA,WACE,eACF,CACA,WACE,cAAe,CACf,eAAgB,CAChB,mBAAoB,CACpB,iBAAkB,CAClB,gBAAiB,CACjB,oBAAsB,CACtB,aACF,CACA,WACE,gBAAa,CAAb,YAAa,CACb,kBACF,CACA,UACE,iBAAkB,CAClB,WACF,CACA,WACE,WACF,CACA,UACE,eAAgB,CAChB,UACF","file":"styles.less","sourcesContent":[".component {\n  padding: 40px 56px;\n  flex-grow: 1;\n}\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.subheader {\n  margin-top: 40px;\n}\n.title {\n  font-size: 31px;\n  font-weight: 700;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 40px;\n  letter-spacing: -0.3px;\n  color: #111214;\n}\n.search-container {\n  display: flex;\n  align-items: center;\n}\n.search-field {\n  margin-right: 11px;\n  width: 328px;\n}\n.search-field-input {\n  height: 48px;\n}\n.children {\n  margin-top: 40px;\n  width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"component": "DiLTEcgt",
	"header": "IsewVZC7",
	"subheader": "_2W2fkIYg",
	"title": "_2EBTsB0h",
	"search-container": "_1M0zQSeW",
	"search-field": "Hwu-4tRf",
	"search-field-input": "_3-boJqJ1",
	"children": "c-VD_nGA"
};
module.exports = exports;
