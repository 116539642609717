import createImageBlobReduce from 'image-blob-reduce';
import uuid from 'uuid/v4';

import FormField from '../../components/common/form/form-field';

import {RX_EMAIL} from '../constants';
import {capitalizeFirstLetter, createTimeString, parseTime as parseTimeRaw} from '../helper';
import JustADate from '../just-a-date';

import {defaultFormFieldFilter} from './helper';

const imageBlobReduce = createImageBlobReduce();

function _transformUserPhoto(file) {
  return imageBlobReduce.toBlob(file, {max: 200});
}

function normalizeDuration(v) {
  const value = Number(v) / 60;

  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  const hoursString = hours ? `${hours} hour${(hours > 1) ? 's' : ''}` : '';
  const minutesString = minutes ? `${minutes} min${(minutes > 1) ? 's' : ''}` : '';

  return `${hoursString}${(hoursString && minutesString) ? ' ' : ''}${minutesString}`;
}

function normalizeTime(v) {
  return createTimeString(v);
}

function parseDuration(s) {
  const rx = /^\s*(\d+)\s*(h|hours?|m|mins?|minutes?),?\s*((\d+)\s*(h|hours?|m|mins?|minutes?)\s*)?$/i;

  const [, v1, d1, , v2, d2] = s.match(rx);

  const value1 = Number(v1);
  const dimension1 = d1[0];
  const value2 = Number(v2);
  const dimension2 = d2 && d2[0];

  if (isNaN(value1)) {
    return '2700';
  }

  if (dimension1 === dimension2) {
    return '2700';
  }

  if (dimension2 && isNaN(value2)) {
    return '';
  }

  let v = value1 * ((dimension1 === 'm') ? 60 : 3600);

  if (dimension2) {
    v += value2 * ((dimension2 === 'm') ? 60 : 3600);
  }

  if (v <= 0) {
    return '2700';
  }

  return v.toString();
}

function parseTime(s) {
  return (parseTimeRaw(s.trim()) || 50400).toString();
}

const DURATION_OPTIONS = (() => {
  const list = [];

  for (let s = 900; s <= 5400; s += 900) {
    const value = s.toString();

    list.push({
      value,
      label: normalizeDuration(value)
    });
  }

  return list;
})();

const TIME_START_OPTIONS = (() => {
  const list = [];

  for (let s = 21600; s <= 75600; s += 1800) {
    const d = (new JustADate()).toDate();

    d.setSeconds(s);

    list.push({
      label: d.toLocaleTimeString('en', {
        hour: '2-digit',
        minute: '2-digit'
      }).toLowerCase().replace(/\s+/g, ''),
      value: s.toString()
    });
  }

  return list;
})();

const CONTENT_TYPE_OPTIONS = ['article', 'course', 'video', 'podcast'].map(type => ({
  label: capitalizeFirstLetter(type),
  value: type
}));

export function createLoginFormFields({renderForgotPasswordField}) {
  return [{
    name: 'email',
    type: FormField.TYPE.EMAIL,
    label: 'Email',
    validate: value => {
      if (!value) {
        return 'Email is required';
      }

      if (!RX_EMAIL.test(value)) {
        return 'Invalid email';
      }
    }
  }, {
    name: 'password',
    type: FormField.TYPE.PASSWORD,
    label: 'Password',
    validate: value => {
      if (!value) {
        return 'Password is required';
      }

      if (value.length < 8) {
        return 'Password is too short';
      }
    }
  }, {
    name: 'forgot',
    type: FormField.TYPE.CUSTOM,
    render: renderForgotPasswordField,
    margin: 12
  }];
}

export const FORGOT_PASSWORD_FORM_FIELDS = [{
  name: 'email',
  type: FormField.TYPE.EMAIL,
  label: 'Email',
  validate: value => {
    if (!value) {
      return 'Email is required';
    }

    if (!RX_EMAIL.test(value)) {
      return 'Invalid email';
    }
  }
}];

export function createAddTemplateFormFields(params = {}) {
  const {
    instructors,
    renderDeleteTemplateField = null,
    renderSaveChangesField,
    renderTemplateNameField,
    tags,
    handleCustomTagAdded
  } = params;

  const instructorIdOptions = instructors.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const topRow = {
    type: 'row',
    columns: [{
      fields: [{
        name: 'name',
        type: FormField.TYPE.CUSTOM,
        placeholder: 'Enter Class Name',
        render: renderTemplateNameField,
        validate: value => {
          if (!value) {
            return 'Class Name is required';
          }
        }
      }]
    }],
    spacing: 24
  };

  if (renderDeleteTemplateField) {
    topRow.columns.push({
      fields: [{
        type: FormField.TYPE.CUSTOM,
        name: 'delete',
        render: renderDeleteTemplateField
      }],
      weight: 0
    });
  }

  return [topRow, {
    type: 'row',
    columns: [{
      fields: [{
        name: 'imageUrl',
        type: FormField.TYPE.UPLOAD,
        label: 'Image',
        createPath: name => `eventImages/${uuid()}-${name}`,
        validate: value => {
          if (!value) {
            return 'Image is required';
          }
        }
      }]
    }, {
      fields: [{
        type: 'row',
        columns: [{
          fields: [{
            name: 'timeStart',
            type: FormField.TYPE.SELECT,
            options: TIME_START_OPTIONS,
            normalize: normalizeTime,
            parse: parseTime,
            label: 'Start Time',
            placeholder: 'Select...',
            validate: value => {
              if (!value) {
                return 'Start Time is required';
              }
            }
          }]
        }, {
          fields: [{
            name: 'duration',
            type: FormField.TYPE.SELECT,
            options: DURATION_OPTIONS,
            normalize: normalizeDuration,
            parse: parseDuration,
            label: 'Duration',
            placeholder: 'Select...',
            validate: value => {
              if (!value) {
                return 'Duration is required';
              }
            }
          }]
        }],
        spacing: 20
      }, {
        type: 'row',
        columns: [{
          fields: [{
            name: 'instructorId',
            type: FormField.TYPE.SELECT,
            options: instructorIdOptions,
            label: 'Instructor',
            placeholder: 'Select...',
            filter: defaultFormFieldFilter,
            validate: value => {
              if (!value) {
                return 'Instructor is required';
              }
            }
          }],
          weight: 324
        }, {
          fields: [{
            name: 'capacity',
            type: FormField.TYPE.NUMBER,
            label: 'Max Spots',
            min: 1,
            validate: value => {
              if (!value) {
                return 'Capacity is required';
              }
            }
          }],
          weight: 80
        }],
        spacing: 20,
        zeroBasis: true
      }, {
        name: 'location',
        type: FormField.TYPE.TEXT,
        label: 'Location',
        placeholder: 'Breakout area...',
        validate: value => {
          if (!value) {
            return 'Location is required';
          }
        }
      }, {
        name: 'description',
        type: FormField.TYPE.TEXT_MULTILINE,
        rowCount: 6,
        label: 'Description',
        validate: value => {
          if (!value) {
            return 'Description is required';
          }
        }
      }, {
        tags,
        name: 'tags',
        type: FormField.TYPE.TAGS,
        label: 'Tags',
        placeholder: 'Enter tags...',
        onCustomTagAdded: handleCustomTagAdded
      }, {
        type: 'row',
        columns: [{
          fields: [{
            type: FormField.TYPE.SPACER,
            name: 'spacer'
          }]
        }, {
          fields: [{
            type: FormField.TYPE.CUSTOM,
            name: 'save',
            render: renderSaveChangesField
          }],
          weight: 0
        }]
      }]
    }],
    spacing: 56
  }];
}

export function createEventForm1Fields({buildings, companies, templates, tenants, selectBuildingId, selectCompanyId}) {
  const companyIdOptions = companies.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const buildingIdOptions = buildings.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const tenantIdTags = tenants.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const templateIdOptions = templates.map(({id, name}) => ({
    label: name,
    value: id
  }));

  return [{
    name: 'companyId',
    type: FormField.TYPE.SELECT,
    options: companyIdOptions,
    label: 'Company',
    placeholder: 'Please select...',
    filter: defaultFormFieldFilter,
    onChange: (value, setValue) => {
      setValue(value);
      selectCompanyId(value);
    }
  }, {
    disabled: buildings.length < 1,
    name: 'buildingId',
    type: FormField.TYPE.SELECT,
    options: buildingIdOptions,
    label: 'Building',
    placeholder: 'Please select...',
    filter: defaultFormFieldFilter,
    onChange: (value, setValue) => {
      setValue(value);
      selectBuildingId(value);
    }
  }, {
    disabled: tenants.length < 1,
    name: 'tenantIds',
    type: FormField.TYPE.TAGS,
    label: 'Tenants',
    tags: tenantIdTags,
    description: 'By default, this new class will be available to all tenants of the Building you choose. ' +
      'If you would like the class to only be available for specific tenants, you can choose them here.'
  }, {
    name: 'templateId',
    type: FormField.TYPE.SELECT,
    options: templateIdOptions,
    label: 'Class Template',
    placeholder: 'Optional...',
    filter: defaultFormFieldFilter,
    description: 'Choosing a template will pre-populate some data on the next step. ' +
      'This can easily be edited afterwards.'
  }];
}

export function createEventForm2Fields(params) {
  const {
    dateStart,
    instructors,
    repeat,
    tags,
    renderDeleteEventField = null,
    handleCustomTagAdded,
    handleDateStartChange,
    handleRepeatChange
  } = params;

  const instructorIdOptions = instructors.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const topRow = {
    type: 'row',
    columns: [{
      fields: [{
        name: 'name',
        type: FormField.TYPE.TEXT,
        label: 'Class Name',
        placeholder: 'New Class...',
        validate: value => {
          if (!value) {
            return 'Name is required';
          }
        }
      }]
    }],
    spacing: 24
  };

  if (renderDeleteEventField) {
    topRow.columns.push({
      fields: [{
        type: FormField.TYPE.CUSTOM,
        name: 'delete',
        render: renderDeleteEventField
      }],
      weight: 0
    });
  }

  const fields = [{
    type: 'row',
    columns: [{
      fields: [{
        name: 'imageUrl',
        type: FormField.TYPE.UPLOAD,
        label: 'Image',
        createPath: name => `eventImages/${uuid()}-${name}`,
        validate: value => {
          if (!value) {
            return 'Image is required';
          }
        }
      }]
    }, {
      fields: [topRow, {
        name: 'date',
        type: FormField.TYPE.DATE,
        label: 'Start Date',
        placeholder: 'Enter date...',
        minDate: new JustADate(),
        onChange: handleDateStartChange,
        validate: value => {
          if (!value) {
            return 'Start Date is required';
          }
        }
      }, {
        type: 'row',
        columns: [{
          fields: [{
            name: 'timeStart',
            type: FormField.TYPE.SELECT,
            options: TIME_START_OPTIONS,
            normalize: normalizeTime,
            parse: parseTime,
            label: 'Start Time',
            placeholder: 'Select...',
            validate: value => {
              if (!value) {
                return 'Start Time is required';
              }
            }
          }]
        }, {
          fields: [{
            name: 'duration',
            type: FormField.TYPE.SELECT,
            options: DURATION_OPTIONS,
            normalize: normalizeDuration,
            parse: parseDuration,
            label: 'Duration',
            placeholder: 'Select...',
            validate: value => {
              if (!value) {
                return 'Duration is required';
              }
            }
          }]
        }],
        spacing: 20
      }, {
        type: 'row',
        columns: [{
          fields: [{
            name: 'instructorId',
            type: FormField.TYPE.SELECT,
            options: instructorIdOptions,
            label: 'Instructor',
            placeholder: 'Select...',
            filter: defaultFormFieldFilter,
            validate: value => {
              if (!value) {
                return 'Instructor is required';
              }
            }
          }],
          weight: 324
        }, {
          fields: [{
            name: 'capacity',
            type: FormField.TYPE.NUMBER,
            label: 'Max Spots',
            min: 1,
            validate: value => {
              if (!value) {
                return 'Capacity is required';
              }
            }
          }],
          weight: 80
        }],
        spacing: 20,
        zeroBasis: true
      }, {
        name: 'location',
        type: FormField.TYPE.TEXT,
        label: 'Location',
        placeholder: 'Breakout area...',
        validate: value => {
          if (!value) {
            return 'Location is required';
          }
        }
      }, {
        name: 'description',
        type: FormField.TYPE.TEXT_MULTILINE,
        rowCount: 6,
        label: 'Description',
        placeholder: 'Describe the class. What is involved, what people can hope to get out of it, etc…',
        validate: value => {
          if (!value) {
            return 'Description is required';
          }
        }
      }, {
        tags,
        name: 'tags',
        type: FormField.TYPE.TAGS,
        label: 'Tags',
        placeholder: 'Enter tags...',
        onCustomTagAdded: handleCustomTagAdded
      }, {
        name: 'repeat',
        type: FormField.TYPE.RADIO,
        options: [{
          label: 'Don’t repeat',
          value: 'once'
        }, {
          label: 'Repeat Daily',
          value: 'daily'
        }, {
          label: 'Repeat Weekly',
          value: 'weekly'
        }],
        label: 'Repeating Class',
        onChange: handleRepeatChange
      }]
    }],
    spacing: 56
  }];

  if (repeat !== 'once') {
    const minDate = new JustADate(dateStart);

    fields[0].columns[1].fields.push({
      minDate,
      name: 'dateEnd',
      type: FormField.TYPE.DATE,
      label: 'End Date',
      placeholder: 'Enter date...',
      validate: value => {
        if (!value) {
          return 'End Date is required';
        }
      }
    });
  }

  return fields;
}

export const CREATE_CATEGORY_FORM_FIELDS = [{
  name: 'name',
  type: FormField.TYPE.TEXT,
  label: 'Name',
  placeholder: 'Enter name...',
  validate: value => {
    if (!value) {
      return 'Name is required';
    }
  }
}];

export const CREATE_AUTHOR_FORM_FIELDS = [{
  name: 'name',
  type: FormField.TYPE.TEXT,
  label: 'Name',
  placeholder: 'Enter name...',
  validate: value => {
    if (!value) {
      return 'Name is required';
    }
  }
}, {
  name: 'photo',
  type: FormField.TYPE.UPLOAD_COMPACT,
  label: 'Image',
  createPath: name => `authorPhotos/${uuid()}-${name}`,
  validate: value => {
    if (!value) {
      return 'Image is required';
    }
  }
}];

export function createContentSelectFormFields({buildings, companies, tenants, selectBuildingId, selectCompanyId}) {
  const companyIdOptions = companies.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const buildingIdOptions = buildings.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const tenantIdTags = tenants.map(({id, name}) => ({
    label: name,
    value: id
  }));

  return [{
    name: 'companyId',
    type: FormField.TYPE.SELECT,
    options: companyIdOptions,
    label: 'Company',
    placeholder: 'Please select...',
    filter: defaultFormFieldFilter,
    onChange: (value, setValue) => {
      setValue(value);
      selectCompanyId(value);
    }
  }, {
    disabled: buildings.length < 1,
    name: 'buildingId',
    type: FormField.TYPE.SELECT,
    options: buildingIdOptions,
    label: 'Building',
    placeholder: 'Please select...',
    filter: defaultFormFieldFilter,
    onChange: (value, setValue) => {
      setValue(value);
      selectBuildingId(value);
    }
  }, {
    disabled: tenants.length < 1,
    name: 'tenantIds',
    type: FormField.TYPE.TAGS,
    label: 'Tenants',
    tags: tenantIdTags,
    description: 'By default, this new content will be available to all tenants of the Building you choose. ' +
      'If you would like the content to only be available for specific tenants, you can choose them here.'
  }];
}

export function createAddContentFormFields({authors, categories, tenants, renderCompanyAndBuilding}) {
  const authorIdOptions = authors.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const categoryIdsOptions = categories.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const tenantIdTags = tenants.map(({id, name}) => ({
    label: name,
    value: id
  }));

  return [{
    name: 'companyAndBuilding',
    type: FormField.TYPE.CUSTOM,
    render: renderCompanyAndBuilding,
    predicate: () => Boolean(renderCompanyAndBuilding)
  }, {
    name: 'tenantIds',
    type: FormField.TYPE.TAGS,
    label: 'Tenants',
    tags: tenantIdTags,
    description: 'By default, this content will be available to all tenants of the Building you choose. ' +
      'If you would like the content to only be available for specific tenants, you can choose them here.',
    predicate: () => Boolean(renderCompanyAndBuilding)
  }, {
    name: 'name',
    type: FormField.TYPE.TEXT,
    label: 'Name',
    validate: value => {
      if (!value) {
        return 'Name is required';
      }
    }
  }, {
    name: 'type',
    type: FormField.TYPE.SELECT,
    options: CONTENT_TYPE_OPTIONS,
    label: 'Type',
    placeholder: 'Please select...',
    validate: value => {
      if (!value) {
        return 'Type is required';
      }
    }
  }, {
    name: 'authorId',
    type: FormField.TYPE.SELECT,
    options: authorIdOptions,
    label: 'Author',
    placeholder: 'Please select...',
    filter: defaultFormFieldFilter,
    validate: value => {
      if (!value) {
        return 'Author is required';
      }
    }
  }, {
    name: 'image',
    type: FormField.TYPE.UPLOAD_COMPACT,
    label: 'Image',
    createPath: name => `contentImages/${uuid()}-${name}`,
    validate: value => {
      if (!value) {
        return 'Image is required';
      }
    }
  }, {
    tags: categoryIdsOptions,
    name: 'categoryIds',
    type: FormField.TYPE.TAGS,
    label: 'Categories',
    placeholder: 'Please select...',
    description: 'Please select at least 1 category. Choosing multiple categories will mean this content is ' +
      'displayed multiple times. Featuring this content will also display it in the top section of the app.',
    validate: value => {
      if (!value || (value.length < 1)) {
        return 'At least 1 category is required';
      }
    }
  }, {
    accept: 'audio/mpeg',
    name: 'data.audioFile',
    type: FormField.TYPE.UPLOAD_COMPACT,
    label: 'Audio File',
    description: 'Upload the audio file for the podcast (15 MB max file size). Currenty only MP3 format is supported.',
    createPath: name => `contentAudioFiles/${uuid()}-${name}`,
    predicate: ({values}) => values.type === 'podcast',
    validate: value => {
      if (!value) {
        return 'Audio file is required';
      }
    }
  }, {
    name: 'data.videoUrl',
    type: FormField.TYPE.TEXT,
    label: 'Video Link',
    description: 'Enter the video link (e.g. YoutTube, Vimeo, etc).',
    predicate: ({values}) => values.type === 'video',
    validate: value => {
      if (!value) {
        return 'Video link is required';
      }
    }
  }, {
    name: 'data.introCopy',
    type: FormField.TYPE.TEXT_MULTILINE,
    rowCount: 3,
    label: 'Intro Copy',
    description: 'This is the short description that is displayed on the home screen of the course.',
    predicate: ({values}) => values.type === 'course',
    validate: value => {
      if (!value) {
        return 'Intro copy is required';
      }
    }
  }, {
    name: 'data.modules',
    type: FormField.TYPE.CONTENT_MODULES,
    label: 'Modules',
    predicate: ({values}) => values.type === 'course',
    validate: value => {
      if (!value || (value.length < 1)) {
        return 'At least 1 module is required';
      }
    }
  }, {
    name: 'data.duration',
    type: FormField.TYPE.NUMBER,
    label: 'Duration (mins)',
    predicate: ({values}) => ['podcast', 'video'].includes(values.type),
    validate: value => {
      if (!value) {
        return 'Duration is required';
      }
    }
  }, {
    name: 'content',
    type: FormField.TYPE.RICH_TEXT,
    label: 'Content',
    predicate: ({values}) => values.type !== 'course'
  }, {
    accept: 'image/png, image/jpeg, application/pdf',
    name: 'file',
    type: FormField.TYPE.UPLOAD_COMPACT,
    label: 'File Upload (optional)',
    description: 'Optionally, you can upload a file here for the user to download, which will apear below the ' +
      'content. File formats supported are images (jpg, png, etc) as well as PDF.',
    createPath: name => `contentFiles/${uuid()}-${name}`
  }];
}

export const CREATE_COMPANY_FORM_FIELDS = [{
  name: 'name',
  type: FormField.TYPE.TEXT,
  label: 'Name',
  placeholder: 'Enter name...',
  validate: value => {
    if (!value) {
      return 'Name is required';
    }
  }
}, {
  name: 'isSubscribed',
  type: FormField.TYPE.CHECKBOX,
  label: 'Subscribed',
  description: 'If checked, Content will be available for every user within Company'
}];

export function createAddBuildingFormFields({companies}) {
  const companyIdOptions = companies.map(({id, name}) => ({
    label: name,
    value: id
  }));

  return [{
    name: 'name',
    type: FormField.TYPE.TEXT,
    label: 'Name',
    placeholder: 'Enter name...',
    validate: value => {
      if (!value) {
        return 'Name is required';
      }
    }
  }, {
    name: 'companyId',
    type: FormField.TYPE.SELECT,
    label: 'Company',
    options: companyIdOptions,
    validate: value => {
      if (!value) {
        return 'Company is required';
      }
    }
  }, {
    name: 'isSubscribed',
    type: FormField.TYPE.CHECKBOX,
    label: 'Subscribed',
    description: 'If checked, Content will be available for every user within Building'
  }, {
    name: 'waiver',
    type: FormField.TYPE.TEXT_MULTILINE,
    rowCount: 12,
    label: 'Waiver',
    validate: value => {
      if (!value) {
        return 'Waiver is required';
      }
    }
  }];
}

export function createAddTenantFormFields({buildings}) {
  const buildingIdsTags = buildings.map(({id, name}) => ({
    label: name,
    value: id
  }));

  return [{
    name: 'name',
    type: FormField.TYPE.TEXT,
    label: 'Name',
    placeholder: 'Enter name...',
    validate: value => {
      if (!value) {
        return 'Name is required';
      }
    }
  }, {
    name: 'emailDomain',
    type: FormField.TYPE.TEXT,
    label: 'Email domain',
    placeholder: 'Enter domain...',
    validate: value => {
      if (!value) {
        return 'Email domain is required';
      }
    }
  }, {
    name: 'buildingIds',
    type: FormField.TYPE.TAGS,
    label: 'Buildings',
    tags: buildingIdsTags,
    validate: value => {
      if (!value || (value.length < 1)) {
        return 'At least 1 building is required';
      }
    }
  }, {
    name: 'isSubscribed',
    type: FormField.TYPE.CHECKBOX,
    label: 'Subscribed',
    description: 'If checked, Content will be available for every user within Tenant'
  }, {
    name: 'waiver',
    type: FormField.TYPE.TEXT_MULTILINE,
    rowCount: 12,
    label: 'Waiver',
    validate: value => {
      if (!value) {
        return 'Waiver is required';
      }
    }
  }];
}

export const ADD_INSTRUCTOR_FORM_FIELDS = [{
  name: 'firstName',
  type: FormField.TYPE.TEXT,
  label: 'First Name',
  placeholder: 'Enter first name...',
  validate: value => {
    if (!value) {
      return 'First name is required';
    }
  }
}, {
  name: 'lastName',
  type: FormField.TYPE.TEXT,
  label: 'Last Name',
  placeholder: 'Enter last name...',
  validate: value => {
    if (!value) {
      return 'Last name is required';
    }
  }
}, {
  name: 'photoUrl',
  type: FormField.TYPE.UPLOAD_COMPACT,
  label: 'Profile Photo',
  createPath: name => `userPhotos/${uuid()}-${name}`,
  transform: _transformUserPhoto
}];
