import firebase from 'firebase/app';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import useFirebaseEntity from '../../../hooks/use-firebase-entity';
import useOrderedFirebaseEntity from '../../../hooks/use-ordered-firebase-entity';

import {getCreateContentErrors} from '../../../lib/form/error-getters';
import {createAddContentFormFields, createContentSelectFormFields} from '../../../lib/form/fields';
import {defaultFormFieldFilter, handleSubmitFailure} from '../../../lib/form/helper';

import {capitalizeFirstLetter, excludeFields, exposeFields, formatDate} from '../../../lib/helper';

import Buildings from '../../../redux/ducks/buildings';
import Companies from '../../../redux/ducks/companies';
import Tenants from '../../../redux/ducks/tenants';

import Button from '../../common/button';
import ContentContainer from '../../common/content-container';
import ContentSidebar from '../../common/content-sidebar';
import ContentTable from '../../common/content-table';
import Dialog from '../../common/dialog';
import Form from '../../common/form';
import SelectFormField from '../../common/form/form-field/select';
import Page from '../../common/page';

import styles from './styles.less';

const MAX_CATEGORIES_STRING_LENGTH = 25;

function _getCategoriesString(data, categories) {
  const list = data.categoryIds
    .map(categoryId => categories.find(({id}) => id === categoryId))
    .filter(category => category);

  let string = '';
  let extraCount = list.length;

  for (const {name} of list) {
    if ((string.length + name.length) > MAX_CATEGORIES_STRING_LENGTH) {
      break;
    }

    if (string.length > 0) {
      string += ', ';
    }

    string += name;

    --extraCount;
  }

  return {extraCount, string};
}

const Badge = ({color, text}) => {
  return (
    <div className={styles.badge} style={{backgroundColor: color}}>{text}</div>
  );
};

Badge.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

const ContentPage = () => {
  const dispatch = useDispatch();

  const [
    items,
    {hasFetched, isCreating, isRemoving, isUpdating},
    {create, remove, update}
  ] = useOrderedFirebaseEntity('content', [], {
    getOrderingKeys: ({categoryIds}) => categoryIds,
    mapData: item => ({
      ...item,
      createdAt: item.createdAt.toDate()
    })
  });

  const [authors, {hasFetched: hasFetchedAuthors}] = useFirebaseEntity('authors');
  const [categories, {hasFetched: hasFetchedCategories}] = useFirebaseEntity('categories');

  const selectFormSubmitCallbackRef = useRef(null);
  const formSubmitCallbackRef = useRef(null);

  const [search, setSearch] = useState('');
  const [isSelectDialogVisible, setSelectDialogVisible] = useState(false);
  const [selectFormData, setSelectFormData] = useState({});
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const companies = useSelector(state => state.companies.items);
  const selectedCompanyId = useSelector(state => state.companies.companyId);

  const buildings = useSelector(state => state.buildings.items);
  const selectedBuildingId = useSelector(state => state.buildings.buildingId);

  const tenants = useSelector(state => state.tenants.items);
  const selectedTenantId = useSelector(state => state.tenants.tenantId || '-');

  const columns = [{
    style: {
      textAlign: 'left'
    },
    title: 'Name'
  }, {
    style: {
      textAlign: 'left',
      width: '150px'
    },
    title: 'Author'
  }, {
    style: {
      textAlign: 'left',
      width: '80px'
    },
    title: 'Type'
  }, {
    style: {
      textAlign: 'left',
      width: '200px'
    },
    title: 'Categories'
  }, {
    style: {
      width: '80px'
    },
    title: 'Publish Date'
  }, {
    style: {
      width: '80px'
    },
    title: 'Status'
  }];

  const rows = items
    .filter(item => {
      if (!selectedBuildingId) {
        return false;
      }

      if (item.buildingId !== selectedBuildingId) {
        return false;
      }

      if (selectedTenantId === '-') {
        return true;
      }

      return item.wholeBuilding || item.tenantIds.includes(selectedTenantId);
    })
    .map(({id, ...rest}) => ({
      key: id,
      data: {
        id,
        ...rest
      }
    }));

  const filterRow = data => {
    return data.name.toLowerCase().includes(search.toLowerCase());
  };

  const renderRowCell = (data, index) => {
    switch (index) {
    case 0:
      return (
        <div className={styles['cell-name']}>
          <div className={styles['image-wrapper']}>
            <img className={styles.image} src={data.image.url}/>
          </div>
          <div className={styles.name}>{data.name}</div>
        </div>
      );
    case 1:
      return (
        <div className={styles['cell-author']}>
          <div className={styles['author-photo-wrapper']}>
            <img className={styles['author-photo']} src={data.author.photoUrl}/>
          </div>
          <div className={styles['author-name']}>{data.author.name}</div>
        </div>
      );
    case 2:
      return (
        <div className={styles['cell-type']}>{capitalizeFirstLetter(data.type)}</div>
      );
    case 3: {
      if (!hasFetchedCategories) {
        return 'Loading...';
      }

      const {extraCount, string} = _getCategoriesString(data, categories);

      return (
        <div>
          {string}
          {
            (extraCount > 0) && (
              <span className={styles['categories-extra-count']}> +{extraCount}</span>
            )
          }
        </div>
      );
    }

    case 4:
      return formatDate(data.createdAt);
    case 5:
      return (
        <Badge color={data.isLive ? '#61b100' : '#ff8b4d'} text={data.isLive ? 'Live' : 'Draft'}/>
      );
    default:
      return null;
    }
  };

  const handleAddButtonClick = () => {
    setSelectedRow(null);
    setSelectDialogVisible(true);
  };

  const handleRowClick = ({data}) => {
    setSelectedRow(data);
    setDialogVisible(true);
  };

  const handleRemove = async () => {
    const ref = firebase.firestore().collection('authors').doc(selectedRow.author.id);

    const success = await remove(selectedRow.id, async tx => {
      const doc = await tx.get(ref);

      if (!doc.exists) {
        throw new Error('Author does not exist');
      }

      return doc.data().contentCount;
    }, (tx, contentCount) => {
      return tx.update(ref, {contentCount: contentCount - 1});
    });

    if (!success) {
      throw new Error('Please, try again');
    }

    setDialogVisible(false);
  };

  const handleFormSubmit = async formData => {
    const {
      authorId,
      content,
      data: contentData,
      ...rest
    } = formData;

    const isLive = Boolean(formSubmitCallbackRef.current.live);

    const author = authors.find(({id}) => id === authorId);

    const data = {
      ...excludeFields(rest, 'companyAndBuilding'),
      isLive,
      author: {
        id: authorId,
        name: author.name,
        photoUrl: author.photo.url
      },
      content: content || null,
      data: contentData || null
    };

    if (contentData && contentData.duration) {
      data.data.duration = Number(contentData.duration);
    }

    const ref = firebase.firestore().collection('authors').doc(authorId);
    const oldRef = selectedRow && firebase.firestore().collection('authors').doc(selectedRow.author.id);

    if (selectedRow) {
      data.wholeBuilding = data.tenantIds.length < 1;

      const sameAuthor = authorId === selectedRow.author.id;

      const success = await update(selectedRow.id, data, !sameAuthor && (async tx => {
        const doc = await tx.get(ref);

        if (!doc.exists) {
          throw new Error('Author does not exist');
        }

        const oldDoc = await tx.get(oldRef);

        if (!oldDoc.exists) {
          throw new Error('Author does not exist');
        }

        return {
          contentCount: doc.data().contentCount,
          oldContentCount: oldDoc.data().contentCount
        };
      }), !sameAuthor && (async (tx, {contentCount, oldContentCount}) => {
        await tx.update(oldRef, {contentCount: oldContentCount - 1});
        await tx.update(ref, {contentCount: contentCount + 1});
      }));

      if (!success) {
        throw new Error('Please, try again');
      }
    } else {
      const id = await create({
        ...data,
        buildingId: selectFormData.buildingId,
        createdAt: new Date(),
        tenantIds: selectFormData.tenantIds || [],
        wholeBuilding: !selectFormData.tenantIds || (selectFormData.tenantIds.length < 1)
      }, async tx => {
        const doc = await tx.get(ref);

        if (!doc.exists) {
          throw new Error('Author does not exist');
        }

        return doc.data().contentCount;
      }, (tx, contentCount) => {
        return tx.update(ref, {contentCount: contentCount + 1});
      });

      if (!id) {
        throw new Error('Please, try again');
      }
    }

    setSelectFormData({});
    setDialogVisible(false);
  };

  const defaultValues = selectedRow ? {
    ...exposeFields(selectedRow, 'categoryIds', 'content', 'data', 'file', 'image', 'name', 'tenantIds', 'type'),
    authorId: selectedRow.author.id
  } : {};

  if (defaultValues.data && defaultValues.data.duration) {
    defaultValues.data.duration = String(defaultValues.data.duration);
  }

  // eslint-disable-next-line react/prop-types
  const renderDialogButtons = ({buttonAcceptDisabled, buttonAcceptText, buttonRejectText, onAccept, onReject}) => (
    <>
      <Button
        disabled={buttonAcceptDisabled}
        kind={Button.KIND.SECONDARY}
        text={buttonRejectText}
        onClick={onReject}
      />
      <div className={styles['dialog-actions-right']}>
        <Button
          disabled={buttonAcceptDisabled}
          kind={Button.KIND.SECONDARY}
          text="Save as Draft"
          userClassName={styles['dialog-action-save-as-draft']}
          onClick={onAccept()}
        />
        <Button disabled={buttonAcceptDisabled} text={buttonAcceptText} onClick={onAccept(true)}/>
      </div>
    </>
  );

  const handleAccept = (live = false) => () => {
    const submit = formSubmitCallbackRef.current;

    submit.live = live;

    submit();
  };

  const selectCompanyId = companyId => {
    dispatch(Companies.actions.setCompanyId(companyId));
  };

  const selectBuildingId = buildingId => {
    dispatch(Buildings.actions.setBuildingId(buildingId));
  };

  const selectTenantId = tenantId => {
    dispatch(Tenants.actions.setTenantId(tenantId));
  };

  const companyIdOptions = companies.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const buildingIdOptions = buildings.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const tenantIdOptions = tenants.map(({id, name}) => ({
    label: name,
    value: id
  }));

  tenantIdOptions.unshift({
    label: 'All tenants',
    value: '-'
  });

  const subheader = (
    <div className={styles.subheader}>
      <div className={styles['subheader-item']}>
        <SelectFormField
          filter={defaultFormFieldFilter}
          label="Company"
          name="companyId"
          options={companyIdOptions}
          placeholder="Please select..."
          value={selectedCompanyId}
          setTouched={() => {}}
          setValue={selectCompanyId}
        />
      </div>
      <div className={styles['subheader-item']}>
        <SelectFormField
          disabled={!selectedCompanyId}
          filter={defaultFormFieldFilter}
          label="Building"
          name="buildingId"
          options={buildingIdOptions}
          placeholder="Please select..."
          value={selectedBuildingId}
          setTouched={() => {}}
          setValue={selectBuildingId}
        />
      </div>
      <div className={styles['subheader-item']}>
        <SelectFormField
          disabled={!selectedBuildingId}
          filter={defaultFormFieldFilter}
          label="Tenant"
          name="tenantId"
          options={tenantIdOptions}
          placeholder="Please select..."
          value={selectedTenantId}
          setTouched={() => {}}
          setValue={selectTenantId}
        />
      </div>
    </div>
  );

  const handleSelectDialogAccept = () => selectFormSubmitCallbackRef.current();

  const handleSelectDialogReject = () => {
    setSelectDialogVisible(false);
    setSelectFormData({});
  };

  const selectFormDefaultValues = {
    ...selectFormData,
    companyId: selectedCompanyId,
    buildingId: selectedBuildingId
  };

  const selectFormFields = createContentSelectFormFields({
    buildings,
    companies,
    tenants,
    selectBuildingId,
    selectCompanyId
  });

  const handleSelectFormSubmit = data => {
    setSelectFormData(data);
    setSelectDialogVisible(false);
    setDialogVisible(true);
  };

  const handleDialogBack = () => {
    setDialogVisible(false);
    setSelectDialogVisible(true);
  };

  const renderCompanyAndBuilding = () => {
    if (!selectedRow) {
      return null;
    }

    const building = buildings.find(({id}) => id === selectedRow.buildingId);
    const company = companies.find(({id}) => building && (id === building.companyId));

    return (
      <div>
        <div className={styles['form-extra-info-row']}>
          Company: {company ? company.name : '-'}
        </div>
        <div className={styles['form-extra-info-row']}>
          Building: {building ? building.name : '-'}
        </div>
      </div>
    );
  };

  const fields = createAddContentFormFields({
    authors,
    categories,
    tenants,
    renderCompanyAndBuilding: selectedRow ? renderCompanyAndBuilding : null
  });

  return (
    <Page title="Content">
      <div className={styles.content}>
        <ContentSidebar/>
        <ContentContainer
          addButtonText="New Content"
          search={search}
          subheader={subheader}
          title="Manage Content"
          onAddButtonClick={(hasFetchedAuthors && hasFetchedCategories) ? handleAddButtonClick : null}
          onSearchChange={setSearch}
        >
          <ContentTable
            columns={columns}
            hasFetched={hasFetched}
            rows={rows}
            filterRow={filterRow}
            renderRowCell={renderRowCell}
            onRowClick={(hasFetchedAuthors && hasFetchedCategories) ? handleRowClick : null}
          />
        </ContentContainer>
        {
          isSelectDialogVisible && (
            <Dialog
              buttonAcceptDisabled={!selectedCompanyId || !selectedBuildingId}
              buttonAcceptText="Next"
              subtitle="Step 1/2"
              title="Add a New Content"
              onAccept={handleSelectDialogAccept}
              onReject={handleSelectDialogReject}
            >
              <div className={styles['dialog-content']}>
                <div className={styles['dialog-form-wrapper']}>
                  <Form
                    key={`${companies.length}-${buildings.length}-${tenants.length}`}
                    defaultValues={selectFormDefaultValues}
                    fields={selectFormFields}
                    submitCallbackRef={selectFormSubmitCallbackRef}
                    onSubmit={handleSelectFormSubmit}
                  />
                </div>
              </div>
            </Dialog>
          )
        }
        {
          isDialogVisible && (
            <Dialog
              buttonAcceptDisabled={isCreating || isRemoving || isUpdating}
              buttonAcceptText="Publish"
              buttonRejectDisabled={isCreating || isRemoving || isUpdating}
              buttonRemoveText="Delete Content"
              subtitle={selectedRow ? null : 'Step 2/2'}
              title={selectedRow ? 'Edit Content' : 'Add a New Content'}
              renderButtons={renderDialogButtons}
              onAccept={handleAccept}
              onBack={selectedRow ? null : handleDialogBack}
              onReject={() => setDialogVisible(false)}
              onRemove={selectedRow && handleRemove}
            >
              <div className={styles['dialog-content']}>
                <div className={styles['dialog-form-wrapper']}>
                  <Form
                    defaultValues={defaultValues}
                    fields={fields}
                    submitCallbackRef={formSubmitCallbackRef}
                    onSubmit={handleFormSubmit}
                    onSubmitFailure={handleSubmitFailure(getCreateContentErrors)}
                  />
                </div>
              </div>
            </Dialog>
          )
        }
      </div>
    </Page>
  );
};

export default ContentPage;
