import {promisifiedDispatch} from '@ololoepepe/redux-api';

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getForgotPasswordErrors} from '../../../lib/form/error-getters';
import {FORGOT_PASSWORD_FORM_FIELDS} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';

import Auth from '../../../redux/ducks/auth';

import AuthPage from '../../common/auth-page';
import Button from '../../common/button';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();

  const isSendingPasswordResetEmail = useSelector(state => state.auth.isSendingPasswordResetEmail);

  const sendPasswordResetEmail = async data => {
    await promisifiedDispatch(dispatch, Auth.actions.sendPasswordResetEmail, {data});

    // eslint-disable-next-line no-alert
    alert('Reset password. Please check your inbox and follow the instructions from Reset Password email.');
  };

  const formProps = {
    title: 'Reset Password',
    actions: [{
      buttonWidth: Button.WIDTH.FILL,
      isPrimary: true,
      text: 'Send Password Reset Email'
    }],
    fields: FORGOT_PASSWORD_FORM_FIELDS,
    disabled: isSendingPasswordResetEmail,
    onSubmit: sendPasswordResetEmail,
    onSubmitFailure: handleSubmitFailure(getForgotPasswordErrors)
  };

  return (
    <AuthPage formProps={formProps}/>
  );
};

export default ForgotPasswordPage;
