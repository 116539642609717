import React from 'react';
import PropTypes from 'prop-types';

import ReactCalendar from 'react-calendar';
import classNames from 'classnames';

import {datesEqual} from '../../../lib/helper';
import JustADate from '../../../lib/just-a-date';

import styles from './styles.less';

const Calendar = props => {
  const {
    activeStartDate,
    controlsChangeSelectedDate,
    maxDate,
    minDate,
    selectedDate,
    hasBadge,
    onActiveStartDateChange,
    onSelectedDateChange
  } = props;

  const getTileClassName = () => styles['calendar-cell-wrapper'];

  const isDateDisabled = date => {
    const theDate = new JustADate(date);

    return (minDate && (theDate.getTime() < minDate.getTime())) || (maxDate && (theDate.getTime() > maxDate.getTime()));
  };

  const getTileContent = params => {
    const tileHasBadge = hasBadge && hasBadge(params);

    const theDate = new JustADate(params.date);

    const isActive = datesEqual(theDate, selectedDate);
    const isDisabled = (theDate.getFullYear() !== activeStartDate.getFullYear()) ||
      (theDate.getMonth() !== activeStartDate.getMonth());

    const cellClassNames = classNames({
      [styles['calendar-cell']]: true,
      [styles['calendar-cell-active']]: isActive,
      [styles['calendar-cell-disabled']]: isDateDisabled(params.date)
    });

    const cellDateClassNames = classNames({
      [styles['calendar-cell-date']]: true,
      [styles['calendar-cell-date-active']]: isActive,
      [styles['calendar-cell-date-disabled']]: isDisabled
    });

    const cellBadgeClassNames = classNames({
      [styles['calendar-cell-badge']]: true,
      [styles['calendar-cell-badge-invisible']]: !tileHasBadge,
      [styles['calendar-cell-badge-active']]: isActive,
      [styles['calendar-cell-badge-disabled']]: isDisabled
    });

    return (
      <div className={cellClassNames}>
        <div className={cellDateClassNames}>{params.date.getDate()}</div>
        {
          Boolean(hasBadge) && (
            <div className={cellBadgeClassNames}/>
          )
        }
      </div>
    );
  };

  const handleCalendarChange = (date, control = false) => {
    if (isDateDisabled(date) && (!control || controlsChangeSelectedDate)) {
      return;
    }

    const theDate = new JustADate(date);

    if ((theDate.getFullYear() !== activeStartDate.getFullYear()) ||
      (theDate.getMonth() !== activeStartDate.getMonth())) {
      const d = new JustADate(date);

      d.setDate(1);

      onActiveStartDateChange(d);
    }

    if (!control || controlsChangeSelectedDate) {
      onSelectedDateChange(theDate);
    }
  };

  const handlePreviousMonthClick = () => {
    const newDate = new JustADate(controlsChangeSelectedDate ? selectedDate : activeStartDate);

    newDate.setMonth(newDate.getMonth() - 1);

    if (controlsChangeSelectedDate) {
      while ((selectedDate.getFullYear() === newDate.getFullYear()) &&
      (selectedDate.getMonth() - newDate.getMonth()) < 1) {
        newDate.setDate(newDate.getDate() - 1);
      }
    }

    handleCalendarChange(newDate, true);
  };

  const handleNextMonthClick = () => {
    const newDate = new JustADate(controlsChangeSelectedDate ? selectedDate : activeStartDate);

    newDate.setMonth(newDate.getMonth() + 1);

    if (controlsChangeSelectedDate) {
      while ((selectedDate.getFullYear() === newDate.getFullYear()) &&
      (newDate.getMonth() - selectedDate.getMonth()) > 1) {
        newDate.setDate(newDate.getDate() - 1);
      }
    }

    handleCalendarChange(newDate, true);
  };

  const value = controlsChangeSelectedDate ?
    selectedDate :
    (
      ((selectedDate.getFullYear() === activeStartDate.getFullYear()) &&
        (selectedDate.getMonth() === activeStartDate.getMonth())) ? selectedDate : undefined
    );

  return (
    <div>
      <div className={styles['calendar-header']}>
        <div className={styles['calendar-title']}>
          {`${activeStartDate.toLocaleDateString('en', {month: 'long'})} ${activeStartDate.getFullYear()}`}
        </div>
        <div className={styles['calendar-controls']}>
          <div
            className={classNames(styles['calendar-control'], styles['calendar-control-previous'])}
            onClick={handlePreviousMonthClick}
          />
          <div
            className={classNames(styles['calendar-control'], styles['calendar-control-next'])}
            onClick={handleNextMonthClick}
          />
        </div>
      </div>
      <ReactCalendar
        activeStartDate={activeStartDate.toDate()}
        className={styles.calendar}
        locale="en"
        showNavigation={false}
        tileClassName={getTileClassName}
        tileContent={getTileContent}
        value={value && value.toDate()}
        onChange={handleCalendarChange}
      />
    </div>
  );
};

Calendar.propTypes = {
  activeStartDate: PropTypes.instanceOf(JustADate).isRequired,
  controlsChangeSelectedDate: PropTypes.bool,
  maxDate: PropTypes.instanceOf(JustADate),
  minDate: PropTypes.instanceOf(JustADate),
  selectedDate: PropTypes.instanceOf(JustADate).isRequired,
  hasBadge: PropTypes.func,
  onActiveStartDateChange: PropTypes.func.isRequired,
  onSelectedDateChange: PropTypes.func.isRequired
};

Calendar.defaultProps = {
  controlsChangeSelectedDate: false,
  maxDate: null,
  minDate: null,
  hasBadge: null
};

export default Calendar;
