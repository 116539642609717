import {put, takeLatest} from 'redux-saga/effects';

import Auth from '../ducks/auth';
import Session from '../ducks/session';

function * handleLogIn() {
  yield put(Session.actions.logIn());
}

function * handleLogOut() {
  yield put(Session.actions.logOut());
}

export default function * auth() {
  yield takeLatest(Auth.actionTypes.logIn.SUCCESS, handleLogIn);
  yield takeLatest(Auth.actionTypes.logOut.SUCCESS, handleLogOut);
  yield takeLatest(Auth.actionTypes.logOut.ERROR, handleLogOut);
}
