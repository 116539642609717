import {createDuck} from '../../lib/firebase-provider';

export default createDuck('companies', {
  collectionName: 'companies',
  createQuery: collection => collection
}, {
  customActionHandlers: {
    setCompanyId: (state, {payload}) => ({
      ...state,
      companyId: payload
    })
  },
  reducerOptions: {
    customInitialState: {
      companyId: null
    }
  }
});
