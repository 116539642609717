import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import JustADate from '../../../../../lib/just-a-date';

import Calendar from '../../../calendar';
import Menu from '../../../menu';

import styles from './styles.less';

function defaultFormatDate(date) {
  const dateString = date.getDate().toString().padStart(2, '0');
  const monthString = (date.getMonth() + 1).toString().padStart(2, '0');

  return `${dateString}/${monthString}/${date.getFullYear()}`;
}

const DateFormField = props => {
  const {
    description,
    disabled,
    error,
    label,
    maxDate,
    minDate,
    placeholder,
    touched,
    value,
    formatDate,
    setTouched,
    setValue,
    onChange
  } = props;

  const hasError = Boolean(touched && error);

  const menuCallbacksRef = useRef(null);

  const d = new JustADate();

  d.setDate(1);

  const [isMenuVisible, setMenuVisible] = useState(false);
  const [activeStartDate, setActiveStartDate] = useState(d);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles['label-disabled']]: disabled
  });
  const inputClassNames = classNames({
    [styles.input]: true,
    [styles['input-disabled']]: disabled,
    [styles['input-active']]: isMenuVisible,
    [styles['input-with-error']]: hasError
  });
  const placeholderClassNames = classNames({
    [styles.placeholder]: true,
    [styles['placeholder-disabled']]: disabled
  });
  const valueClassNames = classNames({
    [styles.value]: true,
    [styles['value-disabled']]: disabled
  });

  const theValue = (() => {
    if (!value) {
      return new JustADate();
    }

    if (value instanceof JustADate) {
      return value;
    }

    return new JustADate(value._utcMidnightDateObj);
  })();

  const menuItems = [({setMenuVisible}) => {
    const handleSelectedDateChange = date => {
      setMenuVisible(false);

      const theDate = new JustADate(date);

      if (onChange) {
        onChange(theDate, setValue);
      } else {
        setValue(theDate);
      }

      setTouched(true);
    };

    return (
      <Calendar
        activeStartDate={activeStartDate}
        maxDate={maxDate}
        minDate={minDate}
        selectedDate={theValue ? new JustADate(theValue) : new JustADate()}
        onActiveStartDateChange={setActiveStartDate}
        onSelectedDateChange={handleSelectedDateChange}
      />
    );
  }];

  const handleInputContainerClick = () => {
    if (disabled) {
      return;
    }

    menuCallbacksRef.current.toggle();
  };

  return (
    <div className={styles['date-form-field']}>
      {
        label && (
          <div className={labelClassNames}>
            {label}
          </div>
        )
      }
      <Menu callbacksRef={menuCallbacksRef} items={menuItems} minMenuWidth="100%" onVisibilityChange={setMenuVisible}>
        <div className={inputClassNames} onClick={handleInputContainerClick}>
          {
            theValue ? (
              <div className={valueClassNames}>{formatDate(new JustADate(theValue))}</div>
            ) : (
              placeholder && (
                <div className={placeholderClassNames}>{placeholder}</div>
              )
            )
          }
        </div>
      </Menu>
      {
        Boolean(description) && (
          <div className={styles.description}>
            {description}
          </div>
        )
      }
      {
        hasError && (
          <div className={styles.error}>{error}</div>
        )
      }
    </div>
  );
};

DateFormField.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  maxDate: PropTypes.instanceOf(JustADate),
  minDate: PropTypes.instanceOf(JustADate),
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(JustADate), PropTypes.shape({
    _utcMidnightDateObj: PropTypes.string.isRequired
  })]),
  formatDate: PropTypes.func,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

DateFormField.defaultProps = {
  description: null,
  disabled: false,
  error: null,
  formatDate: defaultFormatDate,
  label: null,
  maxDate: null,
  minDate: null,
  placeholder: null,
  touched: false,
  value: null,
  onChange: null
};

DateFormField.defaultFormatDate = defaultFormatDate;

export default DateFormField;
