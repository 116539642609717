import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';

class Modal extends React.Component {
  render() {
    const {children} = this.props;

    return (
      <div ref={this._refOverlay} className={styles.overlay} onClick={this._handleClick}>
        <div className={styles.modal}>
          {children}
        </div>
      </div>
    );
  }

  _handleClick = e => {
    const {noCloseOnClick, onCloseRequested} = this.props;

    e.stopPropagation();

    if (noCloseOnClick || (e.target !== this._elOverlay)) {
      return;
    }

    if (onCloseRequested) {
      onCloseRequested();
    }
  };

  _refOverlay = el => {
    this._elOverlay = el;
  };
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  noCloseOnClick: PropTypes.bool,
  onCloseRequested: PropTypes.func
};

Modal.defaultProps = {
  noCloseOnClick: false,
  onCloseRequested: null
};

export default Modal;
