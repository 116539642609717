import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import * as firebase from 'firebase/app';

import classNames from 'classnames';

import Button from '../button';
import OverlaySpinner from '../overlay-spinner';

import styles from './styles.less';

function _defaultRenderButton({disabled}) {
  return (
    <Button disabled={disabled} text="Upload"/>
  );
}

_defaultRenderButton.propTypes = {
  disabled: PropTypes.bool.isRequired
};

const UploadContainer = props => {
  const {accept, createPath, disabled, userClassName, userStyle, renderButton, transform, onError, onUpload} = props;

  const inputRef = useRef(null);

  const [isUploading, setUploading] = useState(false);

  const fileUploadClassNames = classNames({
    [styles['file-upload']]: true,
    [styles['file-upload-disabled']]: disabled || isUploading,
    [userClassName]: Boolean(userClassName)
  });

  const handleFileSelect = async e => {
    const file = e.target.files[0];

    const path = createPath(file.name);

    setUploading(true);

    try {
      const ref = firebase.storage()
        .ref()
        .child(path);

      const blob = await transform(file);

      await ref.put(blob);

      if (onUpload) {
        await onUpload(ref, file);
      }
    } catch (err) {
      console.warn(err.message);

      if (onError) {
        onError(err);
      }

      inputRef.current.value = '';
    }

    setUploading(false);
  };

  return (
    <div className={fileUploadClassNames} style={userStyle}>
      {
        renderButton({
          disabled: disabled || isUploading
        })
      }
      <input
        ref={inputRef}
        disabled={disabled}
        type="file"
        accept={accept}
        className={styles.input}
        title=""
        onChange={disabled ? null : handleFileSelect}
      />
      <OverlaySpinner text="Uploading..." visible={isUploading}/>
    </div>
  );
};

UploadContainer.propTypes = {
  accept: PropTypes.string,
  createPath: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  userClassName: PropTypes.string,
  userStyle: PropTypes.string,
  renderButton: PropTypes.func,
  transform: PropTypes.func,
  onUpload: PropTypes.func,
  onError: PropTypes.func
};

UploadContainer.defaultProps = {
  accept: null,
  disabled: false,
  userClassName: null,
  userStyle: null,
  renderButton: _defaultRenderButton,
  transform: value => value,
  onUpload: null,
  onError: null
};

export default UploadContainer;
