import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

const NavigationLink = ({path, text}) => {
  const navigationLinkClassNames = classNames({
    [styles['navigation-link']]: true,
    [styles['navigation-link-active']]: path === window.location.pathname
  });

  return (
    <Link className={navigationLinkClassNames} to={path}>{text}</Link>
  );
};

NavigationLink.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

const ContentSidebar = () => {
  return (
    <div className={styles.component}>
      <NavigationLink path="/content" text="Content"/>
      <NavigationLink path="/categories" text="Categories"/>
      <NavigationLink path="/authors" text="Authors"/>
    </div>
  );
};

export default ContentSidebar;
