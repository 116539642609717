import {all} from 'redux-saga/effects';

import {createApiSaga} from '@ololoepepe/redux-api';

import Session from '../ducks/session';

import auth from './auth';
import init from './init';
import resubscribe from './resubscribe';
import session from './session';

export default function * root() {
  yield all([
    createApiSaga({
      subscribeAction: Session.actionTypes.FETCH_COMMON_DATA,
      unsubscribeAction: Session.actionTypes.LOG_OUT
    })(),
    auth(),
    init(),
    resubscribe(),
    session()
  ]);
}
