// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/logo/logo-medium.png");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".jG2wHKFE{width:100%;height:100%;position:absolute;justify-content:center;padding-top:64px;box-sizing:border-box;background-color:#fff}.jG2wHKFE,._1W_V2Dva{-js-display:flex;display:flex}._1W_V2Dva{width:424px;flex-direction:column;align-items:center}._2Y9kLoCd{margin-bottom:96px;height:120px;width:120px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain}._2C-c-SJL{width:100%;-js-display:flex;display:flex;justify-content:center;align-items:center;box-sizing:border-box}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,UACE,UAAW,CACX,WAAY,CACZ,iBAAkB,CAElB,sBAAuB,CACvB,gBAAiB,CACjB,qBAAsB,CACtB,qBACF,CACA,qBANE,gBAAa,CAAb,YAWF,CALA,WACE,WAAY,CAEZ,qBAAsB,CACtB,kBACF,CACA,WACE,kBAAmB,CACnB,YAAa,CACb,WAAY,CACZ,wDAAiE,CACjE,uBACF,CACA,WACE,UAAW,CACX,gBAAa,CAAb,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,qBACF","file":"styles.less","sourcesContent":[".auth-page {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  padding-top: 64px;\n  box-sizing: border-box;\n  background-color: #fff;\n}\n.auth-panel {\n  width: 424px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.logo {\n  margin-bottom: 96px;\n  height: 120px;\n  width: 120px;\n  background-image: url('../../../assets/img/logo/logo-medium.png');\n  background-size: contain;\n}\n.body {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-sizing: border-box;\n}\n"]}]);
// Exports
exports.locals = {
	"auth-page": "jG2wHKFE",
	"auth-panel": "_1W_V2Dva",
	"logo": "_2Y9kLoCd",
	"body": "_2C-c-SJL"
};
module.exports = exports;
