import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

const InputFormField = props => {
  const {
    description,
    disabled,
    error,
    label,
    max,
    min,
    name,
    placeholder,
    touched,
    type,
    userClassName,
    userInputClassName,
    value,
    withSearchIcon,
    setTouched,
    setValue,
    onChange
  } = props;

  const hasError = Boolean(touched && error);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles['label-disabled']]: disabled
  });
  const inputClassNames = classNames({
    [styles.input]: true,
    [styles['input-disabled']]: disabled,
    [styles['input-with-search-icon']]: withSearchIcon,
    [styles['input-with-error']]: hasError,
    [userInputClassName]: userInputClassName
  });

  const inputProps = {
    disabled,
    name,
    type,
    value,
    className: inputClassNames,
    onBlur() {
      // Prevent passing arguments to setValue

      setTouched();
    },
    onChange(e) {
      const {value} = e.target;

      if (onChange) {
        onChange(value, setValue);
      } else {
        setValue(value);
      }
    }
  };

  if (placeholder) {
    inputProps.placeholder = placeholder;
  }

  if (min !== null) {
    inputProps.min = min;
  }

  if (max !== null) {
    inputProps.max = max;
  }

  return (
    <div className={classNames(styles['input-form-field'], userClassName)}>
      {
        label && (
          <div className={labelClassNames}>
            {label}
          </div>
        )
      }
      <input {...inputProps}/>
      {
        withSearchIcon && (
          <div className={styles['search-icon']}/>
        )
      }
      {
        Boolean(description) && (
          <div className={styles.description}>
            {description}
          </div>
        )
      }
      {
        hasError && (
          <div className={styles.error}>{error}</div>
        )
      }
    </div>
  );
};

InputFormField.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  type: PropTypes.string.isRequired,
  userClassName: PropTypes.string,
  userInputClassName: PropTypes.string,
  value: PropTypes.string,
  withSearchIcon: PropTypes.bool,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

InputFormField.defaultProps = {
  description: null,
  disabled: false,
  error: null,
  label: null,
  max: null,
  min: null,
  placeholder: null,
  touched: false,
  userClassName: null,
  userInputClassName: null,
  value: '',
  withSearchIcon: false,
  onChange: null
};

export default InputFormField;
