import {updateItem} from '@ololoepepe/entity-list-reducer';

import {getInstance as getApiProviderInstance} from '../../lib/api-provider';
import {createDuck} from '../../lib/firebase-provider';
import JustADate from '../../lib/just-a-date';

const _getIdFrom = ({id}) => id;

export default createDuck('events', {
  collectionName: 'events',
  createQuery: (collection, {state}) => {
    const {buildingId} = state.buildings;
    const {tenantId} = state.tenants;

    let q = collection;

    if (buildingId) {
      q = q.where('buildingId', '==', buildingId);
    }

    if (tenantId && (tenantId !== '-')) {
      q = q.where('tenantIds', 'array-contains', tenantId);
    }

    return q;
  },
  mapData: ({date, dateEnd, ...rest}) => ({
    ...rest,
    date: new JustADate(date.toDate()),
    dateEnd: new JustADate(dateEnd.toDate())
  })
}, {
  customActionHandlers: {
    setMonthFirstDay: (state, {payload}) => ({
      ...state,
      monthFirstDay: new JustADate(payload)
    })
  },
  customApiActionHandlers: {
    removeEvent: {
      handleRequest: params => {
        const {id} = params.action.payload;

        return getApiProviderInstance().customRequest(`/events/${id}`, 'remove', params);
      },
      handlePending: (state, {payload}) => updateItem(_getIdFrom, _getIdFrom, state, payload, {
        isRemoving: true
      }),
      handleSuccess: state => state,
      handleError: (state, {payload}) => updateItem(_getIdFrom, _getIdFrom, state, payload, {
        isRemoving: false,
        removeError: payload.error
      })
    }
  },
  customAuthorizedActions: ['removeEvent'],
  reducerOptions: {
    customFilter: (entity, state) => !state.items.find(item => item.id === entity.id),
    customInitialState: state => ({
      monthFirstDay: state ? state.monthFirstDay : (() => {
        const date = new JustADate();

        date.setDate(1);

        return date;
      })()
    })
  }
});
