// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3rAT41qT{height:100%;-js-display:flex;display:flex}._3I50VZQg{font-size:16px;font-weight:500;color:#111214}._3I50VZQg,._2k778LbL{height:100%;-js-display:flex;display:flex;align-items:center}._2k778LbL{width:350px}._1XHxEd7S{width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}._12C95dyg{min-height:100%;width:100%;padding:52px;box-sizing:border-box;-js-display:flex;display:flex;flex-direction:column;align-items:center}._1VnzROD6{width:664px}._2bxmmtOZ{margin-top:20px;width:664px}._1z85Wz9T{margin-bottom:8px;font-size:13px;font-weight:500;line-height:20px;color:#6e6f76}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,WAAY,CACZ,gBAAa,CAAb,YACF,CACA,WAIE,cAAe,CACf,eAAgB,CAChB,aACF,CACA,sBAPE,WAAY,CACZ,gBAAa,CAAb,YAAa,CACb,kBAUF,CALA,WAEE,WAGF,CACA,WACE,UAAW,CACX,eAAgB,CAChB,kBAAmB,CACnB,sBACF,CACA,WACE,eAAgB,CAChB,UAAW,CACX,YAAa,CACb,qBAAsB,CACtB,gBAAa,CAAb,YAAa,CACb,qBAAsB,CACtB,kBACF,CACA,WACE,WACF,CACA,WACE,eAAgB,CAChB,WACF,CACA,WACE,iBAAkB,CAClB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,aACF","file":"styles.less","sourcesContent":[".content {\n  height: 100%;\n  display: flex;\n}\n.cell-name {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  font-size: 16px;\n  font-weight: 500;\n  color: #111214;\n}\n.cell-waiver {\n  height: 100%;\n  width: 350px;\n  display: flex;\n  align-items: center;\n}\n.waiver {\n  width: 100%;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.dialog-content {\n  min-height: 100%;\n  width: 100%;\n  padding: 52px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.dialog-form-wrapper {\n  width: 664px;\n}\n.dialog-content-wrapper {\n  margin-top: 20px;\n  width: 664px;\n}\n.dialog-content-title {\n  margin-bottom: 8px;\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 20px;\n  color: #6e6f76;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "_3rAT41qT",
	"cell-name": "_3I50VZQg",
	"cell-waiver": "_2k778LbL",
	"waiver": "_1XHxEd7S",
	"dialog-content": "_12C95dyg",
	"dialog-form-wrapper": "_1VnzROD6",
	"dialog-content-wrapper": "_2bxmmtOZ",
	"dialog-content-title": "_1z85Wz9T"
};
module.exports = exports;
