import {createDuck} from '../../lib/firebase-provider';
import JustADate from '../../lib/just-a-date';

export default createDuck('bookings', {
  collectionName: 'bookings',
  createQuery: (collection, {state}) => {
    const {selectedDate} = state.bookings;

    return collection.where('date', '==', selectedDate.toUTCDate());
  },
  mapData: ({createdAt, date, ...rest}) => ({
    ...rest,
    createdAt: createdAt.toDate(),
    date: new JustADate(date.toDate())
  })
}, {
  customActionHandlers: {
    setSelectedDate: (state, {payload}) => ({
      ...state,
      selectedDate: new JustADate(payload)
    })
  },
  reducerOptions: {
    customFilter: (entity, state) => !state.items.find(item => item.id === entity.id),
    customInitialState: state => ({
      selectedDate: state ? state.selectedDate : new JustADate()
    })
  }
});
