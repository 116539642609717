// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ZDnaSoVX{position:fixed;bottom:24px;right:24px;height:64px;width:64px;border-radius:50%;background-color:#61b100;box-shadow:0 8px 24px 0 rgba(67,67,74,.2);cursor:pointer}.ZDnaSoVX:before{top:calc(50% - 12px);left:calc(50% - 2px);height:24px;width:4px}.ZDnaSoVX:after,.ZDnaSoVX:before{content:\"\";position:absolute;border-radius:2px;background-color:#fff}.ZDnaSoVX:after{top:calc(50% - 2px);left:calc(50% - 12px);height:4px;width:24px}.ZDnaSoVX._2N7O8Q1z{background-color:rgba(0,0,0,.1);cursor:not-allowed}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,UACE,cAAe,CACf,WAAY,CACZ,UAAW,CACX,WAAY,CACZ,UAAW,CACX,iBAAkB,CAClB,wBAAyB,CACzB,yCAA8C,CAC9C,cACF,CACA,iBAGE,oBAAqB,CACrB,oBAAqB,CACrB,WAAY,CACZ,SAGF,CACA,iCATE,UAAW,CACX,iBAAkB,CAKlB,iBAAkB,CAClB,qBAWF,CATA,gBAGE,mBAAoB,CACpB,qBAAsB,CACtB,UAAW,CACX,UAGF,CACA,oBACE,+BAAoC,CACpC,kBACF","file":"styles.less","sourcesContent":[".fab {\n  position: fixed;\n  bottom: 24px;\n  right: 24px;\n  height: 64px;\n  width: 64px;\n  border-radius: 50%;\n  background-color: #61b100;\n  box-shadow: 0 8px 24px 0 rgba(67, 67, 74, 0.2);\n  cursor: pointer;\n}\n.fab::before {\n  content: '';\n  position: absolute;\n  top: calc(50% - 12px);\n  left: calc(50% - 2px);\n  height: 24px;\n  width: 4px;\n  border-radius: 2px;\n  background-color: #fff;\n}\n.fab::after {\n  content: '';\n  position: absolute;\n  top: calc(50% - 2px);\n  left: calc(50% - 12px);\n  height: 4px;\n  width: 24px;\n  border-radius: 2px;\n  background-color: #fff;\n}\n.fab.disabled {\n  background-color: rgba(0, 0, 0, 0.1);\n  cursor: not-allowed;\n}\n"]}]);
// Exports
exports.locals = {
	"fab": "ZDnaSoVX",
	"disabled": "_2N7O8Q1z"
};
module.exports = exports;
