import firebase from 'firebase/app';
import React, {useRef, useState} from 'react';

import {getCreateTenantErrors} from '../../../lib/form/error-getters';
import {createAddTenantFormFields} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';

import {exposeFields} from '../../../lib/helper';

import useFirebaseEntity from '../../../hooks/use-firebase-entity';

import ContentContainer from '../../common/content-container';
import ContentTable from '../../common/content-table';
import Dialog from '../../common/dialog';
import Form from '../../common/form';
import Page from '../../common/page';
import Sidebar from '../../common/sidebar';

import styles from './styles.less';

const MAX_BUILDINGS_STRING_LENGTH = 25;

function _getBuildingsString(data, buildings) {
  const list = data.buildingIds
    .map(buildingId => buildings.find(({id}) => id === buildingId))
    .filter(category => category);

  let string = '';
  let extraCount = list.length;

  for (const {name} of list) {
    if ((string.length + name.length) > MAX_BUILDINGS_STRING_LENGTH) {
      break;
    }

    if (string.length > 0) {
      string += ', ';
    }

    string += name;

    --extraCount;
  }

  return {extraCount, string};
}

function _hasBuildingIdsChanged(oldIds, newIds) {
  return oldIds.some(id => !newIds.includes(id)) || newIds.some(id => !oldIds.includes(id));
}

const TenantsPage = () => {
  const [
    items,
    {hasFetched, isCreating, isUpdating},
    {create, update}
  ] = useFirebaseEntity('tenants');

  const [buildings, {hasFetched: hasFetchedBuildings}] = useFirebaseEntity('buildings');

  const formSubmitCallbackRef = useRef(null);

  const [search, setSearch] = useState('');
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [{
    style: {
      textAlign: 'left'
    },
    title: 'Name'
  }, {
    style: {
      textAlign: 'left',
      width: '250px'
    },
    title: 'Email Domain'
  }, {
    style: {
      textAlign: 'left',
      width: '250px'
    },
    title: 'Buildings'
  }, {
    style: {
      textAlign: 'left',
      width: '350px'
    },
    title: 'Waiver'
  }];

  const rows = items.map(({id, ...rest}) => ({
    key: id,
    data: {
      id,
      ...rest
    }
  }));

  const filterRow = data => {
    return data.name.toLowerCase().includes(search.toLowerCase());
  };

  const renderRowCell = (data, index) => {
    switch (index) {
    case 0:
      return (
        <div className={styles['cell-name']}>
          <div>{data.name}</div>
        </div>
      );
    case 1:
      return data.emailDomain;
    case 2: {
      if (!hasFetchedBuildings) {
        return 'Loading...';
      }

      const {extraCount, string} = _getBuildingsString(data, buildings);

      return (
        <div>
          {string}
          {
            (extraCount > 0) && (
              <span className={styles['buildings-extra-count']}> +{extraCount}</span>
            )
          }
        </div>
      );
    }

    case 3:
      return (
        <div className={styles['cell-waiver']} title={data.waiver}>
          <div className={styles.waiver}>{data.waiver}</div>
        </div>
      );
    default:
      return null;
    }
  };

  const handleAddButtonClick = () => {
    setSelectedRow(null);
    setDialogVisible(true);
  };

  const handleRowClick = ({data}) => {
    setSelectedRow(data);
    setDialogVisible(true);
  };

  const handleFormSubmit = async ({isSubscribed, waiver, ...rest}) => {
    const data = {
      ...rest,
      subscriptions: {
        ...selectedRow?.subscriptions,
        content: Boolean(isSubscribed)
      },
      waiver: waiver || null
    };

    if (selectedRow) {
      let usersRefs = [];

      if (_hasBuildingIdsChanged(selectedRow.buildingIds, data.buildingIds)) {
        const usersSnapshot = await firebase.firestore().collection('users')
          .where('tenantId', '==', selectedRow.id)
          .get();

        usersRefs = usersSnapshot.docs.map(item => item.ref);
      }

      const success = await update(selectedRow.id, data, () => {}, async tx => {
        /* eslint-disable no-await-in-loop */
        for (const ref of usersRefs) {
          await tx.update(ref, {buildingId: null});
        }
        /* eslint-enable no-await-in-loop */
      });

      if (!success) {
        throw new Error('Please, try again');
      }
    } else {
      const id = await create({
        ...data,
        timezoneOffset: -660
      });

      if (!id) {
        throw new Error('Please, try again');
      }
    }

    setDialogVisible(false);
  };

  const defaultValues = selectedRow ? {
    ...exposeFields(selectedRow, 'name', 'emailDomain', 'buildingIds', 'waiver'),
    isSubscribed: Boolean(selectedRow?.subscriptions?.content)
  } : {
    isSubscribed: false
  };

  return (
    <Page title="Tenants">
      <div className={styles.content}>
        <Sidebar
          items={[{
            path: '/tenants',
            text: 'Tenants'
          }, {
            path: '/buildings',
            text: 'Buildings'
          }, {
            path: '/companies',
            text: 'Companies'
          }]}
        />
        <ContentContainer
          addButtonText="New Tenant"
          search={search}
          title="Manage Tenants"
          onAddButtonClick={hasFetchedBuildings ? handleAddButtonClick : null}
          onSearchChange={setSearch}
        >
          <ContentTable
            columns={columns}
            hasFetched={hasFetched}
            rows={rows}
            filterRow={filterRow}
            renderRowCell={renderRowCell}
            onRowClick={hasFetchedBuildings ? handleRowClick : null}
          />
        </ContentContainer>
        {
          isDialogVisible && (
            <Dialog
              buttonAcceptDisabled={isCreating || isUpdating}
              buttonAcceptText={selectedRow ? 'Save' : 'Create Tenant'}
              title={selectedRow ? 'Edit Tenant' : 'Add a New Tenant'}
              onAccept={() => formSubmitCallbackRef.current()}
              onReject={() => setDialogVisible(false)}
            >
              <div className={styles['dialog-content']}>
                <div className={styles['dialog-form-wrapper']}>
                  <Form
                    defaultValues={defaultValues}
                    fields={createAddTenantFormFields({buildings})}
                    submitCallbackRef={formSubmitCallbackRef}
                    onSubmit={handleFormSubmit}
                    onSubmitFailure={handleSubmitFailure(getCreateTenantErrors)}
                  />
                </div>
              </div>
            </Dialog>
          )
        }
      </div>
    </Page>
  );
};

export default TenantsPage;
