import {createDuck} from '../../lib/firebase-provider';

export default createDuck('templates', {
  collectionName: 'templates',
  createQuery: collection => collection
}, {
  reducerOptions: {
    customFilter: (entity, state) => !state.items.find(item => item.id === entity.id)
  }
});
