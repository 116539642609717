import {createDuck} from '../../lib/firebase-provider';

export default createDuck('tags', {
  collectionName: 'tags',
  createQuery: collection => collection
}, {
  reducerOptions: {
    customFilter: (entity, state) => !state.items.find(item => (item.id === entity.id) || (item.name === entity.name))
  }
});
