import {createBrowserHistory} from 'history';
import url from 'url';

const history = createBrowserHistory();

export default history;

export function parseHref(href) {
  if (!href) {
    const {pathname, search, hash} = history.location;

    href = pathname + search + (hash || '');
  }

  return url.parse(href, true);
}
