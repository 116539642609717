import {createDuck} from '../../lib/firebase-provider';

export default createDuck('buildings', {
  collectionName: 'buildings',
  createQuery: (collection, {state}) => {
    const {companyId} = state.companies;

    return companyId && collection.where('companyId', '==', companyId);
  }
}, {
  customActionHandlers: {
    setBuildingId: (state, {payload}) => ({
      ...state,
      buildingId: payload
    })
  },
  reducerOptions: {
    customInitialState: {
      buildingId: null
    }
  }
});
