import React from 'react';
import PropTypes from 'prop-types';

import {Redirect} from 'react-router-dom';

export default WrappedComponent => {
  class AuthProxy extends React.Component {
    render() {
      const {_isLoggedIn, _redirect, ...rest} = this.props;

      if (_isLoggedIn) {
        return (
          <Redirect to={_redirect}/>
        );
      }

      return (
        <WrappedComponent {...rest}/>
      );
    }
  }

  AuthProxy.propTypes = {
    _isLoggedIn: PropTypes.bool.isRequired,
    _redirect: PropTypes.string.isRequired
  };

  return AuthProxy;
};
