// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._34If2q5O{position:relative;cursor:pointer}._34If2q5O ._3xb2J6iQ{position:absolute;left:0;top:0;width:100%;height:100%;opacity:0;cursor:pointer}._34If2q5O._2ovKHL45,._34If2q5O._2ovKHL45 ._3xb2J6iQ{cursor:not-allowed}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,iBAAkB,CAClB,cACF,CACA,sBACE,iBAAkB,CAClB,MAAO,CACP,KAAM,CACN,UAAW,CACX,WAAY,CACZ,SAAU,CACV,cACF,CAIA,qDACE,kBACF","file":"styles.less","sourcesContent":[".file-upload {\n  position: relative;\n  cursor: pointer;\n}\n.file-upload .input {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  opacity: 0;\n  cursor: pointer;\n}\n.file-upload.file-upload-disabled {\n  cursor: not-allowed;\n}\n.file-upload.file-upload-disabled .input {\n  cursor: not-allowed;\n}\n"]}]);
// Exports
exports.locals = {
	"file-upload": "_34If2q5O",
	"input": "_3xb2J6iQ",
	"file-upload-disabled": "_2ovKHL45"
};
module.exports = exports;
