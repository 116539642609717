import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import UploadContainer from '../../../upload-container';

import styles from './styles.less';

const UploadFormField = props => {
  const {accept, disabled, error, label, touched, value, createPath, setTouched, setValue, transform, onChange} = props;

  const hasError = Boolean(touched && error);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles['label-disabled']]: disabled
  });
  const previewPlaceholderClassNames = classNames({
    [styles['preview-placeholder']]: true,
    [styles['preview-placeholder-disabled']]: disabled,
    [styles['preview-placeholder-with-error']]: hasError
  });
  const previewPlaceholderContentTitleClassNames = classNames({
    [styles['preview-placeholder-content-title']]: true,
    [styles['preview-placeholder-content-title-disabled']]: disabled
  });
  const previewPlaceholderContentDescriptionClassNames = classNames({
    [styles['preview-placeholder-content-description']]: true,
    [styles['preview-placeholder-content-description-disabled']]: disabled
  });
  const previewPlaceholderContentDescriptionAccentClassNames = classNames({
    [styles['preview-placeholder-content-description-accent']]: true,
    [styles['preview-placeholder-content-description-accent-disabled']]: disabled
  });

  const handleUpload = async ref => {
    const url = await ref.getDownloadURL();

    if (onChange) {
      onChange(url, setValue);
    } else {
      setValue(url);
    }

    setTouched(true);
  };

  const handleError = err => {
    // eslint-disable-next-line no-alert
    alert(err);
  };

  const renderButton = () => {
    return (
      <div className={styles['preview-wrapper']}>
        {
          value ? (
            <img src={value} className={styles.preview}/>
          ) : (
            <div className={previewPlaceholderClassNames}>
              <div className={styles['preview-placeholder-content']}>
                <div className={previewPlaceholderContentTitleClassNames}>Drag and drop to upload</div>
                <div className={previewPlaceholderContentDescriptionClassNames}>
                  Or <span className={previewPlaceholderContentDescriptionAccentClassNames}>Browse</span> to
                  choose a file
                </div>
              </div>
            </div>
          )
        }
      </div>
    );
  };

  return (
    <div className={styles['upload-form-field']}>
      {
        label && (
          <div className={labelClassNames}>
            {label}
          </div>
        )
      }
      <UploadContainer
        accept={accept}
        disabled={disabled}
        userClassName={styles['upload-container']}
        createPath={createPath}
        renderButton={renderButton}
        transform={transform}
        onError={handleError}
        onUpload={handleUpload}
      />
      {
        hasError && (
          <div className={styles.error}>{error}</div>
        )
      }
    </div>
  );
};

UploadFormField.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null])
  ]),
  createPath: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  transform: PropTypes.func,
  onChange: PropTypes.func
};

UploadFormField.defaultProps = {
  accept: 'image/png, image/jpeg',
  disabled: false,
  error: null,
  label: null,
  touched: false,
  transform: value => value,
  value: null,
  onChange: null
};

export default UploadFormField;
