import React from 'react';
import PropTypes from 'prop-types';

import Form from '../form';

import styles from './styles.less';

const AuthPage = ({formProps}) => {
  return (
    <div className={styles['auth-page']}>
      <div className={styles['auth-panel']}>
        <div className={styles.logo}/>
        <div className={styles.body}>
          <Form {...formProps}/>
        </div>
      </div>
    </div>
  );
};

AuthPage.propTypes = {
  formProps: PropTypes.object.isRequired
};

export default AuthPage;
