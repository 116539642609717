import React from 'react';
import PropTypes from 'prop-types';

import {Field} from 'react-form';

import JustADate from '../../../../lib/just-a-date';

import CheckboxFormField from './checkbox';
import ContentModulesFormField from './content-modules';
import DateFormField from './date';
import InputFormField from './input';
import RadioFormField from './radio';
import RichTextFormField from './rich-text';
import SelectFormField from './select';
import TagsFormField from './tags';
import TextareaFormField from './textarea';
import UploadCompactFormField from './upload-compact';
import UploadFormField from './upload';

// TODO import styles from './styles.less';

const TYPE = {
  CHECKBOX: 'checkbox',
  CONTENT_MODULES: 'content_modules',
  CUSTOM: 'custom',
  DATE: 'date',
  EMAIL: 'email',
  HIDDEN: 'hidden',
  NUMBER: 'number',
  PASSWORD: 'password',
  RADIO: 'radio',
  RICH_TEXT: 'rich_text',
  SELECT: 'select',
  SPACER: 'spacer',
  TAGS: 'tags',
  TEXT: 'text',
  TEXT_MULTILINE: 'text_multiline',
  UPLOAD: 'upload',
  UPLOAD_COMPACT: 'upload_compact'
};

const _renderCheckbox = props => ({error, touched, value, setTouched, setValue}) => {
  const {description, disabled, label, name, onChange} = props;

  return (
    <CheckboxFormField
      error={error}
      disabled={disabled}
      description={description}
      touched={touched}
      label={label}
      name={name}
      value={value}
      setTouched={setTouched}
      setValue={setValue}
      onChange={onChange}
    />
  );
};

const _renderContentModules = props => ({error, touched, value, setTouched, setValue}) => {
  const {description, disabled, label, onChange} = props;

  return (
    <ContentModulesFormField
      description={description}
      disabled={disabled}
      error={error}
      label={label}
      name={name}
      touched={touched}
      value={value}
      setTouched={setTouched}
      setValue={setValue}
      onChange={onChange}
    />
  );
};

const _renderCustom = ({render, ...rest}) => fieldApi => {
  return render({
    fieldApi,
    fieldProps: rest
  });
};

const _renderDate = props => ({error, touched, value, setTouched, setValue}) => {
  const {description, disabled, label, maxDate, minDate, placeholder, formatDate, onChange} = props;

  return (
    <DateFormField
      description={description}
      disabled={disabled}
      error={error}
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      placeholder={placeholder}
      touched={touched}
      value={value}
      formatDate={formatDate}
      setTouched={setTouched}
      setValue={setValue}
      onChange={onChange}
    />
  );
};

const _renderHidden = ({name}) => ({value}) => {
  return (
    <input name={name} type="hidden" value={value}/>
  );
};

const _renderInput = props => ({error, touched, value, setTouched, setValue}) => {
  const {description, disabled, label, max, min, name, placeholder, type, onChange} = props;

  return (
    <InputFormField
      description={description}
      disabled={disabled}
      error={error}
      label={label}
      max={max}
      min={min}
      name={name}
      placeholder={placeholder}
      touched={touched}
      type={type}
      value={value}
      setTouched={setTouched}
      setValue={setValue}
      onChange={onChange}
    />
  );
};

const _renderRadio = props => ({error, touched, value, setTouched, setValue}) => {
  const {description, disabled, label, options, onChange} = props;

  return (
    <RadioFormField
      description={description}
      disabled={disabled}
      error={error}
      label={label}
      options={options}
      touched={touched}
      value={value}
      setTouched={setTouched}
      setValue={setValue}
      onChange={onChange}
    />
  );
};

const _renderRichText = props => ({error, touched, value, setValue, setTouched}) => {
  const {disabled, label, onChange} = props;

  return (
    <RichTextFormField
      disabled={disabled}
      error={error}
      label={label}
      touched={touched}
      value={value}
      setTouched={setTouched}
      setValue={setValue}
      onChange={onChange}
    />
  );
};

const _renderSelect = props => ({error, touched, value, setTouched, setValue}) => {
  const {
    description,
    disabled,
    label,
    name,
    options,
    placeholder,
    searchPlaceholder,
    filter,
    normalize,
    parse,
    onChange
  } = props;

  return (
    <SelectFormField
      description={description}
      disabled={disabled}
      error={error}
      label={label}
      name={name}
      options={options}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      touched={touched}
      value={value}
      filter={filter}
      normalize={normalize}
      parse={parse}
      setTouched={setTouched}
      setValue={setValue}
      onChange={onChange}
    />
  );
};

const _renderSpacer = () => () => {
  return (
    <div/>
  );
};

const _renderTags = props => ({error, touched, value, setTouched, setValue}) => {
  const {description, disabled, label, placeholder, tags, onChange, onCustomTagAdded} = props;

  return (
    <TagsFormField
      description={description}
      disabled={disabled}
      error={error}
      label={label}
      name={name}
      placeholder={placeholder}
      tags={tags}
      touched={touched}
      value={value}
      setTouched={setTouched}
      setValue={setValue}
      onChange={onChange}
      onCustomTagAdded={onCustomTagAdded}
    />
  );
};

const _renderTextarea = props => ({error, touched, value, setValue, setTouched}) => {
  const {description, disabled, label, name, placeholder, rowCount, onChange} = props;

  return (
    <TextareaFormField
      description={description}
      disabled={disabled}
      error={error}
      label={label}
      name={name}
      placeholder={placeholder}
      rowCount={rowCount}
      touched={touched}
      value={value}
      setTouched={setTouched}
      setValue={setValue}
      onChange={onChange}
    />
  );
};

const _renderUpload = props => ({error, touched, value, setTouched, setValue}) => {
  const {accept, disabled, label, createPath, transform, onChange} = props;

  return (
    <UploadFormField
      accept={accept}
      disabled={disabled}
      error={error}
      label={label}
      touched={touched}
      value={value}
      createPath={createPath}
      setTouched={setTouched}
      setValue={setValue}
      transform={transform}
      onChange={onChange}
    />
  );
};

const _renderUploadCompact = props => ({error, touched, value, setTouched, setValue}) => {
  const {accept, description, disabled, label, createPath, transform, onChange} = props;

  return (
    <UploadCompactFormField
      accept={accept}
      description={description}
      disabled={disabled}
      error={error}
      label={label}
      touched={touched}
      value={value}
      createPath={createPath}
      setTouched={setTouched}
      setValue={setValue}
      transform={transform}
      onChange={onChange}
    />
  );
};

function _getRenderMethod(props) {
  switch (props.type) {
  case TYPE.CHECKBOX:
    return _renderCheckbox(props);
  case TYPE.CONTENT_MODULES:
    return _renderContentModules(props);
  case TYPE.CUSTOM:
    return _renderCustom(props);
  case TYPE.DATE:
    return _renderDate(props);
  case TYPE.HIDDEN:
    return _renderHidden(props);
  case TYPE.RADIO:
    return _renderRadio(props);
  case TYPE.RICH_TEXT:
    return _renderRichText(props);
  case TYPE.SELECT:
    return _renderSelect(props);
  case TYPE.SPACER:
    return _renderSpacer(props);
  case TYPE.TAGS:
    return _renderTags(props);
  case TYPE.TEXT_MULTILINE:
    return _renderTextarea(props);
  case TYPE.UPLOAD:
    return _renderUpload(props);
  case TYPE.UPLOAD_COMPACT:
    return _renderUploadCompact(props);
  default:
    return _renderInput(props);
  }
}

const FormField = props => {
  const {name, asyncValidate, preValidate, validate} = props;

  return (
    <Field field={name} preValidate={preValidate} validate={validate} asyncValidate={asyncValidate}>
      {
        _getRenderMethod(props)
      }
    </Field>
  );
};

/* eslint-disable react/no-unused-prop-types */
FormField.propTypes = {
  accept: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  max: PropTypes.number,
  maxDate: PropTypes.instanceOf(JustADate),
  min: PropTypes.number,
  minDate: PropTypes.instanceOf(JustADate),
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(SelectFormField.OPTION_SHAPE),
  placeholder: PropTypes.string,
  rowCount: PropTypes.number,
  searchPlaceholder: PropTypes.string,
  tags: PropTypes.arrayOf(TagsFormField.TAG_PROP_TYPE),
  type: PropTypes.oneOf(Object.values(TYPE)).isRequired,
  asyncValidate: PropTypes.func,
  createPath: PropTypes.func,
  filter: PropTypes.func,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  preValidate: PropTypes.func,
  formatDate: PropTypes.func,
  validate: PropTypes.func,
  transform: PropTypes.func,
  onChange: PropTypes.func,
  onCustomTagAdded: PropTypes.func
};
/* eslint-enable react/no-unused-prop-types */

FormField.defaultProps = {
  accept: 'image/png, image/jpeg',
  description: null,
  disabled: false,
  label: null,
  max: null,
  maxDate: null,
  min: null,
  minDate: null,
  options: null,
  placeholder: null,
  rowCount: null,
  searchPlaceholder: null,
  tags: [],
  asyncValidate: null,
  createPath: null,
  filter: null,
  formatDate: DateFormField.defaultFormatDate,
  normalize: null,
  parse: null,
  preValidate: null,
  validate: null,
  transform: value => value,
  onChange: null,
  onCustomTagAdded: null
};

FormField.TYPE = TYPE;

export default FormField;
