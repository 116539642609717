import {createDuck} from '../../lib/firebase-provider';

export default createDuck('tenants', {
  collectionName: 'tenants',
  createQuery: (collection, {state}) => {
    const {buildingId} = state.buildings;

    return buildingId && collection.where('buildingIds', 'array-contains', buildingId);
  }
}, {
  customActionHandlers: {
    setTenantId: (state, {payload}) => ({
      ...state,
      tenantId: payload
    })
  },
  reducerOptions: {
    customInitialState: {
      tenantId: null
    }
  }
});
