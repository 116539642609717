import CKEditor from '@ckeditor/ckeditor5-react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';

import CustomEditor from '../../../../../../lib/ckeditor';
import firebaseUploadAdapterPlugin from '../../../../../lib/ck/firebase-upload-adapter-plugin';

import styles from './styles.less';

const EDITOR_CONFIG = {
  extraPlugins: [firebaseUploadAdapterPlugin],
  toolbar: [
    'bold',
    'italic',
    'bulletedList',
    'numberedList',
    '|',
    'alignment:left',
    'alignment:center',
    'alignment:right',
    '|',
    'link',
    'imageUpload',
    '|',
    'undo',
    'redo'
  ]
};

const RichTextFormField = props => {
  const {disabled, error, label, touched, value, setTouched, setValue, onChange} = props;

  const hasError = Boolean(touched && error);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles['label-disabled']]: disabled
  });

  const handleBlur = () => setTouched();

  const handleChange = (event, editor) => {
    const data = editor.getData();

    if (onChange) {
      onChange(data, setValue);
    } else {
      setValue(data);
    }
  };

  const handleInit = editor => {
    editor.editing.view.change(writer => {
      const root = editor.editing.view.document.getRoot();

      writer.setStyle('min-height', '497.33px', root);
      writer.setStyle('max-height', '1100px', root);
    });
  };

  return (
    <div className={styles['textarea-form-field']}>
      {
        label && (
          <div className={labelClassNames}>{label}</div>
        )
      }
      <div className={styles.content}>
        <CKEditor
          config={EDITOR_CONFIG}
          data={value || ''}
          disabled={disabled}
          editor={CustomEditor}
          onBlur={handleBlur}
          onChange={handleChange}
          onInit={handleInit}
        />
      </div>
      {
        hasError && (
          <div className={styles.error}>{error}</div>
        )
      }
    </div>
  );
};

RichTextFormField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.string,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

RichTextFormField.defaultProps = {
  disabled: false,
  error: null,
  label: null,
  touched: false,
  value: '',
  onChange: null
};

export default RichTextFormField;
