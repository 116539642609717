import React from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

const TableRow = ({columns, disabled, index, row, renderRowCell, onReorder, onRowClick}) => {
  const rowClassNames = classNames({
    [styles['table-row']]: true,
    [styles['table-row-clickable']]: onRowClick
  });

  const renderContent = provided => (
    <tr
      ref={provided && provided.innerRef}
      className={rowClassNames}
      onClick={onRowClick && (() => onRowClick(row))}
      {...(provided && provided.draggableProps)}
    >
      {
        columns.map((column, index) => {
          /* eslint-disable react/no-array-index-key */
          return (
            <td key={`${row.key}-${index}`} className={styles['table-cell']} style={column.style}>
              {renderRowCell(row.data, index, provided && provided.dragHandleProps)}
            </td>
          );
          /* eslint-enable react/no-array-index-key */
        })
      }
    </tr>
  );

  if (!onReorder) {
    return renderContent();
  }

  return (
    <Draggable draggableId={row.key} index={index} isDragDisabled={disabled}>
      {renderContent}
    </Draggable>
  );
};

TableRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    style: PropTypes.object,
    title: PropTypes.string.isRequired
  })).isRequired,
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  row: PropTypes.shape({
    key: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  }).isRequired,
  renderRowCell: PropTypes.func.isRequired,
  onReorder: PropTypes.func,
  onRowClick: PropTypes.func
};

TableRow.defaultProps = {
  disabled: false,
  onReorder: null,
  onRowClick: null
};

const ContentTable = props => {
  const {columns, disabled, hasFetched, rows, filterRow, renderRowCell, onReorder, onRowClick} = props;

  const renderContent = provided => (
    <table className={styles.table}>
      <thead className={styles['table-header']}>
        <tr>
          {
            columns.map(column => (
              <th
                key={column.title}
                className={classNames(styles['table-cell'], styles['table-header-cell'])}
                style={column.style}
              >
                {column.title}
              </th>
            ))
          }
        </tr>
      </thead>
      <tbody ref={provided && provided.innerRef} {...(provided && provided.droppableProps)}>
        {
          rows
            .filter(row => filterRow(row.data))
            .map((row, index) => (
              <TableRow
                key={row.key}
                columns={columns}
                disabled={disabled}
                index={index}
                row={row}
                renderRowCell={renderRowCell}
                onReorder={onReorder}
                onRowClick={onRowClick}
              />
            ))
        }
        {
          (!hasFetched || (rows.length < 1)) && (
            <tr className={styles['table-row']}>
              <td className={classNames(styles['table-cell'], styles['table-cell-colspan'])} colSpan={columns.length}>
                {hasFetched ? 'No content' : 'Loading...'}
              </td>
            </tr>
          )
        }
        {provided && provided.placeholder}
      </tbody>
    </table>
  );

  if (!onReorder) {
    return renderContent();
  }

  const handleDragEnd = ({destination, source}) => {
    if (!destination) {
      return;
    }

    onReorder(rows[source.index], destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {renderContent}
      </Droppable>
    </DragDropContext>
  );
};

ContentTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    style: PropTypes.object,
    title: PropTypes.string.isRequired
  })).isRequired,
  disabled: PropTypes.bool,
  hasFetched: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  })).isRequired,
  filterRow: PropTypes.func,
  renderRowCell: PropTypes.func.isRequired,
  onReorder: PropTypes.func,
  onRowClick: PropTypes.func
};

ContentTable.defaultProps = {
  disabled: false,
  filterRow: () => true,
  onReorder: null,
  onRowClick: null
};

export default ContentTable;
