import React from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';

import classNames from 'classnames';

import {isExternalUrl} from '../../../lib/helper';

import styles from './styles.less';

const ClickableText = ({domRef, disabled, href, target, text, title, userClassName, userStyle, onClick}) => {
  const clickableTextClassNames = classNames({
    [userClassName]: Boolean(userClassName),
    [styles['clickable-text']]: true,
    [styles.disabled]: disabled // TODO
  });

  if (disabled) {
    return (
      <span ref={domRef} className={clickableTextClassNames} style={userStyle} title={title}>
        {text}
      </span>
    );
  }

  if (!href) {
    return (
      <span ref={domRef} className={clickableTextClassNames} style={userStyle} title={title} onClick={onClick}>
        {text}
      </span>
    );
  }

  if (isExternalUrl(href) || target) {
    const linkProps = {
      href,
      title,
      ref: domRef,
      className: clickableTextClassNames,
      style: userStyle
    };

    if (target) {
      linkProps.target = target;
    }

    return (
      <a {...linkProps}>
        {text}
      </a>
    );
  }

  return (
    <Link innerRef={domRef} className={clickableTextClassNames} style={userStyle} title={title} to={href}>
      {text}
    </Link>
  );
};

ClickableText.propTypes = {
  domRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  disabled: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onClick: PropTypes.func
};

ClickableText.defaultProps = {
  domRef: null,
  disabled: false,
  href: null,
  target: null,
  title: '',
  userClassName: null,
  userStyle: null,
  onClick: null
};

export default ClickableText;
