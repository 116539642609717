// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1O2F8Zrf{width:100%;height:100%;position:absolute;-js-display:flex;display:flex;justify-content:center;align-items:center;background-color:#fff}.SQB85sQU{color:#fff;font-size:15px}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,UAAW,CACX,WAAY,CACZ,iBAAkB,CAClB,gBAAa,CAAb,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,qBACF,CACA,UACE,UAAW,CACX,cACF","file":"styles.less","sourcesContent":[".not-found-page {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #fff;\n}\n.not-found-message {\n  color: #fff;\n  font-size: 15px;\n}\n"]}]);
// Exports
exports.locals = {
	"not-found-page": "_1O2F8Zrf",
	"not-found-message": "SQB85sQU"
};
module.exports = exports;
