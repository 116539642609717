import React from 'react';
import * as PropTypes from 'prop-types';

import styles from './styles.less';

const OverlaySpinner = ({text, visible}) => {
  if (!visible) {
    return null;
  }

  return (
    <div className={styles.component}>
      <div className={styles.container}>
        <div className={styles.spinner}/>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};

OverlaySpinner.propTypes = {
  text: PropTypes.string,
  visible: PropTypes.bool
};

OverlaySpinner.defaultProps = {
  text: 'Loading...',
  visible: false
};

export default OverlaySpinner;
