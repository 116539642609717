// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1UATemSg,.AjotPP_M{height:100%;-js-display:flex;display:flex}._1UATemSg{align-items:center;font-size:16px;font-weight:500;color:#111214}.quRtKePG{position:relative;height:48px;width:48px;border-radius:50%}._2ZCegbEy{position:absolute;top:0;left:0;height:100%;width:100%;border-radius:50%;object-fit:cover}._1h90YR8W{margin-left:16px}.j0927on1{color:#dddde3}.LZBpamp0{min-height:100%;width:100%;padding:52px;box-sizing:border-box;-js-display:flex;display:flex;flex-direction:column;align-items:center}._3guB42ta{width:664px}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAIA,qBAHE,WAAY,CACZ,gBAAa,CAAb,YASF,CAPA,WAGE,kBAAmB,CACnB,cAAe,CACf,eAAgB,CAChB,aACF,CACA,UACE,iBAAkB,CAClB,WAAY,CACZ,UAAW,CACX,iBACF,CACA,WACE,iBAAkB,CAClB,KAAM,CACN,MAAO,CACP,WAAY,CACZ,UAAW,CACX,iBAAkB,CAClB,gBACF,CACA,WACE,gBACF,CACA,UACE,aACF,CACA,UACE,eAAgB,CAChB,UAAW,CACX,YAAa,CACb,qBAAsB,CACtB,gBAAa,CAAb,YAAa,CACb,qBAAsB,CACtB,kBACF,CACA,WACE,WACF","file":"styles.less","sourcesContent":[".content {\n  height: 100%;\n  display: flex;\n}\n.cell-name {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  font-size: 16px;\n  font-weight: 500;\n  color: #111214;\n}\n.photo-wrapper {\n  position: relative;\n  height: 48px;\n  width: 48px;\n  border-radius: 50%;\n}\n.photo {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  border-radius: 50%;\n  object-fit: cover;\n}\n.name {\n  margin-left: 16px;\n}\n.cell-content-count-zero {\n  color: #dddde3;\n}\n.dialog-content {\n  min-height: 100%;\n  width: 100%;\n  padding: 52px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.dialog-form-wrapper {\n  width: 664px;\n}\n"]}]);
// Exports
exports.locals = {
	"cell-name": "_1UATemSg",
	"content": "AjotPP_M",
	"photo-wrapper": "quRtKePG",
	"photo": "_2ZCegbEy",
	"name": "_1h90YR8W",
	"cell-content-count-zero": "j0927on1",
	"dialog-content": "LZBpamp0",
	"dialog-form-wrapper": "_3guB42ta"
};
module.exports = exports;
