import {createDuck as reduxApiCreateDuck} from '@ololoepepe/redux-api';

import axios from 'axios';
import * as firebase from 'firebase/app';

import AxiosApiProvider from './axios-api-provider';

const DEFAULT_AXIOS_OPTIONS = {
  baseURL: 'https://us-central1-goodmooddudes-2f451.cloudfunctions.net/express',
  timeout: 15 * 1000
};

class ApiProvider extends AxiosApiProvider {
  constructor({axiosInstance}) {
    super({axiosInstance, canRetry: true});
  }

  authorizedSubscribe() {
    return true;
  }

  createHeaders({authorizationData}) {
    if (!authorizationData) {
      return {};
    }

    return {
      Authorization: `Bearer ${authorizationData}`
    };
  }

  async customRequest(url, method, {action, ...rest}) {
    const {payload} = action;

    return this._request({
      ...rest,
      action: {
        ...action,
        payload: {
          ...payload,
          url
        }
      },
      method
    }, this._canRetry);
  }

  async getAuthorizationData() {
    const {currentUser} = firebase.auth();

    if (!currentUser) {
      throw new Error('currentUser is null');
    }

    return currentUser.getIdToken(true);
  }

  shouldClearOnUnsubscribe() {
    return false;
  }
}

let apiProvider = null;

export function createDuck(name, providerOptions, options = {}) {
  const {
    customActionHandlers,
    customApiActionHandlers,
    customAuthorizedActions = [],
    isAuthorized = true,
    reducerOptions,
    single
  } = options;

  const authorizedActions = isAuthorized ?
    ['create', 'fetch', 'remove', 'update', ...customAuthorizedActions] :
    customAuthorizedActions;

  return reduxApiCreateDuck(name, getInstance(), {
    authorizedActions,
    customActionHandlers,
    customApiActionHandlers,
    providerOptions,
    reducerOptions,
    single
  });
}

export function getInstance() {
  if (!apiProvider) {
    throw new Error('API Provider is not initialized');
  }

  return apiProvider;
}

export function init() {
  if (apiProvider) {
    throw new Error('API Provider already initialized');
  }

  const axiosInstance = axios.create(DEFAULT_AXIOS_OPTIONS);

  apiProvider = new ApiProvider({axiosInstance});
}
