import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import useWindowSize from '../../../hooks/use-window-size';

import Button from '../button';
import Modal from '../modal';

import styles from './styles.less';

const Dialog = props => {
  const {
    buttonAcceptDisabled,
    buttonAcceptText,
    buttonRejectDisabled,
    buttonRejectText,
    buttonRemoveText,
    children,
    subtitle,
    title,
    renderButtons,
    onAccept,
    onBack,
    onReject,
    onRemove
  } = props;

  const [{height, width}] = useWindowSize();

  const removeButtonClassNames = classNames({
    [styles['remove-button']]: true,
    [styles['remove-button-disabled']]: buttonAcceptDisabled
  });

  const contentStyles = {
    height: `${height}px`,
    width: `${width}px`
  };

  return (
    <Modal noCloseOnClick onCloseRequested={onReject}>
      <div className={styles.content} style={contentStyles}>
        <div className={styles.header}>
          {
            Boolean(title || subtitle) && (
              <div className={styles.title}>
                {title}
                {
                  Boolean(subtitle) && (
                    <span className={styles.subtitle}> – {subtitle}</span>
                  )
                }
              </div>
            )
          }
          {
            onBack && (
              <div className={styles['back-button']} onClick={onBack}/>
            )
          }
          {
            onRemove && (
              <div
                className={removeButtonClassNames}
                onClick={buttonAcceptDisabled ? null : onRemove}
              >
                {buttonRemoveText}
              </div>
            )
          }
        </div>
        <div className={styles.body}>
          {children}
        </div>
        <div className={styles['action-container']}>
          {
            renderButtons ?
              renderButtons({
                buttonAcceptDisabled,
                buttonAcceptText,
                buttonRejectDisabled,
                buttonRejectText,
                onAccept,
                onReject
              }) :
              (
                <>
                  <Button
                    disabled={buttonRejectDisabled}
                    kind={Button.KIND.SECONDARY}
                    text={buttonRejectText}
                    onClick={onReject}
                  />
                  <Button disabled={buttonAcceptDisabled} text={buttonAcceptText} onClick={onAccept}/>
                </>
              )
          }
        </div>
      </div>
    </Modal>
  );
};

Dialog.propTypes = {
  buttonAcceptDisabled: PropTypes.bool,
  buttonAcceptText: PropTypes.string,
  buttonRejectDisabled: PropTypes.bool,
  buttonRejectText: PropTypes.string,
  buttonRemoveText: PropTypes.string,
  children: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  renderButtons: PropTypes.func,
  onAccept: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onReject: PropTypes.func.isRequired,
  onRemove: PropTypes.func
};

Dialog.defaultProps = {
  buttonAcceptDisabled: false,
  buttonAcceptText: 'OK',
  buttonRejectDisabled: false,
  buttonRejectText: 'Cancel',
  buttonRemoveText: 'Delete',
  subtitle: null,
  title: null,
  renderButtons: null,
  onBack: null,
  onRemove: null
};

export default Dialog;
