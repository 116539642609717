import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';

import 'whatwg-fetch';

import * as firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import {createStore} from './redux';

import App from './components/app';

import './css/index.less';

firebase.initializeApp({
  apiKey: 'AIzaSyAaZcF9cSXngILDcD5MOtE37uDAfQM0dC4',
  authDomain: 'goodmooddudes-2f451.firebaseapp.com',
  databaseURL: 'https://goodmooddudes-2f451.firebaseio.com',
  projectId: 'goodmooddudes-2f451',
  storageBucket: 'goodmooddudes-2f451.appspot.com',
  messagingSenderId: '693128282902',
  appId: '1:693128282902:web:7dc08c571bb059c6c3c637',
  measurementId: 'G-TMT15Q1Q1Z'
});

const {store, persistor} = createStore();

ReactDOM.render((
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App/>
    </PersistGate>
  </Provider>
), document.getElementById('app'));
