import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

const NavigationLink = ({path, text}) => {
  const navigationLinkClassNames = classNames({
    [styles['navigation-link']]: true,
    [styles['navigation-link-active']]: path === window.location.pathname
  });

  return (
    <Link className={navigationLinkClassNames} to={path}>{text}</Link>
  );
};

NavigationLink.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

const Sidebar = ({items}) => {
  return (
    <div className={styles.component}>
      {
        items.map(({path, text}) => (
          <NavigationLink key={path} path={path} text={text}/>
        ))
      }
    </div>
  );
};

Sidebar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  })).isRequired
};

export default Sidebar;
