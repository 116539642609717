import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Menu from '../../../menu';

import styles from './styles.less';

const SelectFormField = props => {
  const {
    description,
    disabled,
    error,
    label,
    options,
    name,
    placeholder,
    searchPlaceholder,
    touched,
    value,
    filter,
    normalize,
    parse,
    setTouched,
    setValue,
    onChange
  } = props;

  const hasError = Boolean(touched && error);

  const menuCallbacksRef = useRef(null);
  const inputRef = useRef(null);

  const [searchString, setSearchString] = useState('');
  const [isEditable, setEditable] = useState(false);
  const [isMenuVisible, setMenuVisible] = useState(false);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles['label-disabled']]: disabled
  });
  const inputClassNames = classNames({
    [styles.input]: true,
    [styles['input-disabled']]: disabled,
    [styles['input-with-filter']]: filter || parse,
    [styles['input-active']]: isMenuVisible || isEditable,
    [styles['input-with-error']]: hasError
  });
  const chevronClassNames = classNames({
    [styles.chevron]: true,
    [styles['chevron-disabled']]: disabled,
    [styles['chevron-active']]: isMenuVisible || isEditable
  });

  const doSetValue = v => {
    if (onChange) {
      onChange(v, setValue);
    } else {
      setValue(v);
    }
  };

  const handleInputBlur = () => {
    setEditable(false);

    const v = parse(searchString);

    doSetValue(v);

    if (normalize) {
      setSearchString(normalize(v));
    }

    setTouched();
  };

  const handleInputChange = e => {
    const {value} = e.target;

    setSearchString(value);
  };

  const menuItems = options
    .filter(option => filter ? filter(searchString, option) : true)
    .map(option => ({
      text: option.label || option.value,
      active: option.value === value,
      onClick() {
        setSearchString(parse ? (option.label || option.value) : '');

        doSetValue(option.value);

        setTouched();

        menuCallbacksRef.current.hide();

        if (parse) {
          setEditable(true);

          setTimeout(() => {
            inputRef.current.focus();
          });
        }
      }
    }));

  const selectedOption = options.find(option => option.value === value);

  const handleInputContainerClick = () => {
    if (disabled) {
      return;
    }

    if (isEditable) {
      return;
    }

    if (filter || parse) {
      menuCallbacksRef.current.show();

      if (parse) {
        setEditable(false);
      }
    } else {
      menuCallbacksRef.current.toggle();
    }
  };

  const inputPlaceholder = isMenuVisible ?
    (filter ? (searchPlaceholder || placeholder || '') : (placeholder || '')) :
    (placeholder || '');
  const inputValue = isMenuVisible ?
    (filter ? searchString : '') :
    (
      parse ?
        (searchString || (value ? (normalize ? normalize(value) : value) : '')) :
        (selectedOption ? (selectedOption.label || selectedOption.value) : '')
    );

  return (
    <div className={styles['select-form-field']}>
      {
        label && (
          <div className={labelClassNames}>
            {label}
          </div>
        )
      }
      <Menu callbacksRef={menuCallbacksRef} items={menuItems} minMenuWidth="100%" onVisibilityChange={setMenuVisible}>
        <div className={styles['input-container']} onClick={handleInputContainerClick}>
          <input
            ref={inputRef}
            className={inputClassNames}
            disabled={disabled || (!filter && (!parse || !isEditable))}
            name={name}
            placeholder={inputPlaceholder}
            value={inputValue}
            onBlur={parse ? handleInputBlur : null}
            onChange={handleInputChange}
          />
          <div className={chevronClassNames}/>
        </div>
      </Menu>
      {
        Boolean(description) && (
          <div className={styles.description}>
            {description}
          </div>
        )
      }
      {
        hasError && (
          <div className={styles.error}>{error}</div>
        )
      }
    </div>
  );
};

const OPTION_SHAPE = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string
});

SelectFormField.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(OPTION_SHAPE).isRequired,
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.string,
  filter: PropTypes.func,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

SelectFormField.defaultProps = {
  description: null,
  disabled: false,
  error: null,
  label: null,
  placeholder: null,
  searchPlaceholder: null,
  touched: false,
  value: '',
  filter: null,
  normalize: null,
  parse: null,
  onChange: null
};

SelectFormField.OPTION_SHAPE = OPTION_SHAPE;

export default SelectFormField;
