import {promisifiedDispatch} from '@ololoepepe/redux-api';

import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import {getCreateEventErrors} from '../../../lib/form/error-getters';
import {createEventForm1Fields, createEventForm2Fields} from '../../../lib/form/fields';
import {defaultFormFieldFilter, handleSubmitFailure} from '../../../lib/form/helper';

import {
  createTimeString,
  datesEqual,
  excludeFields,
  exposeFields,
  getTime
} from '../../../lib/helper';
import JustADate from '../../../lib/just-a-date';

import Bookings from '../../../redux/ducks/bookings';
import Buildings from '../../../redux/ducks/buildings';
import Companies from '../../../redux/ducks/companies';
import Events from '../../../redux/ducks/events';
import Tags from '../../../redux/ducks/tags';
import Tenants from '../../../redux/ducks/tenants';

import Calendar from '../../common/calendar';
import ClickableText from '../../common/clickable-text';
import FloatingActionButton from '../../common/floating-action-button';
import Form from '../../common/form';
import SelectFormField from '../../common/form/form-field/select';
import Dialog from '../../common/dialog';
import Page from '../../common/page';

import styles from './styles.less';

function _isWithinInterval(date, event) {
  return (event.date.getTime() <= date.getTime()) && (date.getTime() <= event.dateEnd.getTime());
}

const _filterEvent = date => event => {
  if (event.repeat === 'daily') {
    return _isWithinInterval(date, event);
  }

  if (event.repeat === 'weekly') {
    return (event.date.getDay() === date.getDay()) && _isWithinInterval(date, event);
  }

  if (event.repeat === 'once') {
    return datesEqual(event.date, date);
  }

  return false;
};

const _hasEventEnded = (date, building) => event => {
  const currentDate = new Date();

  const currentTime = getTime(currentDate) + currentDate.getTimezoneOffset();
  const eventTime = event.timeStart + (building ? building.timezoneOffset : currentDate.getTimezoneOffset());

  const currentMsecs = JustADate.now();
  const eventMsecs = ((event.repeat === 'once') ? event.date : date).getTime();

  if (currentMsecs > eventMsecs) {
    return true;
  }

  if (currentMsecs < eventMsecs) {
    return false;
  }

  return currentTime > eventTime;
};

const EventList = ({date, ended, events, onEventSelected}) => {
  const bookings = useSelector(state => state.bookings.items);

  const titleClassNames = classNames({
    [styles['event-list-title']]: true,
    [styles['event-list-title-ended']]: ended
  });
  const importantTextClassNames = classNames(
    styles['event-list-table-cell-text'],
    styles['event-list-table-cell-text-important']
  );

  const dateString = date.getDate().toString().padStart(2, '0') +
    '/' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '/' +
    date.getFullYear();

  const handleEventSelected = id => () => onEventSelected(id);

  return (
    <div className={styles['event-list']}>
      <div className={titleClassNames}>
        {ended ? 'Ended' : 'Upcoming'} ({events.length})
      </div>
      <div className={styles['event-list-table']}>
        <div className={styles['event-list-table-header']}>
          <div className={styles['event-list-table-cell']} style={{flexBasis: '33%'}}>
            <div className={styles['event-list-table-header-text']}>Class</div>
          </div>
          <div className={styles['event-list-table-cell']} style={{flexBasis: '19%'}}>
            <div className={styles['event-list-table-header-text']}>Instructor</div>
          </div>
          <div className={styles['event-list-table-cell']} style={{flexBasis: '17%'}}>
            <div className={styles['event-list-table-header-text']}>Date</div>
          </div>
          <div className={styles['event-list-table-cell']} style={{flexBasis: '23%'}}>
            <div className={styles['event-list-table-header-text']}>Time</div>
          </div>
          <div className={styles['event-list-table-cell']} style={{flexBasis: '8%'}}>
            <div className={styles['event-list-table-header-text']}>Spots</div>
          </div>
        </div>
        {
          events.map(({capacity, id, imageUrl, instructor, name, timeStart, timeEnd}) => (
            <div key={id} className={styles['event-list-table-row']} onClick={handleEventSelected(id)}>
              <div className={styles['event-list-table-cell']} style={{flexBasis: '33%'}}>
                <img className={styles['event-list-table-cell-image']} src={imageUrl}/>
                <div className={importantTextClassNames}>{name}</div>
              </div>
              <div className={styles['event-list-table-cell']} style={{flexBasis: '19%'}}>
                <div className={styles['event-list-table-cell-text']}>{instructor.name}</div>
              </div>
              <div className={styles['event-list-table-cell']} style={{flexBasis: '17%'}}>
                <div className={styles['event-list-table-cell-text']}>{dateString}</div>
              </div>
              <div className={styles['event-list-table-cell']} style={{flexBasis: '23%'}}>
                <div className={styles['event-list-table-cell-text']}>
                  {createTimeString(timeStart, date)} - {createTimeString(timeEnd, date)}
                </div>
              </div>
              <div className={styles['event-list-table-cell']} style={{flexBasis: '8%'}}>
                <div className={styles['event-list-table-cell-text']}>
                  {bookings.filter(({eventId}) => eventId === id).length}/{capacity}
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

EventList.propTypes = {
  date: PropTypes.instanceOf(JustADate).isRequired,
  ended: PropTypes.bool,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEventSelected: PropTypes.func.isRequired
};

EventList.defaultProps = {
  ended: false
};

const SchedulesPage = () => {
  const dispatch = useDispatch();

  const companies = useSelector(state => state.companies.items);
  const selectedCompanyId = useSelector(state => state.companies.companyId);

  const buildings = useSelector(state => state.buildings.items);
  const selectedBuildingId = useSelector(state => state.buildings.buildingId);
  const selectedBuilding = selectedBuildingId && buildings.find(item => item.id === selectedBuildingId);

  const tenants = useSelector(state => state.tenants.items);
  const selectedTenantId = useSelector(state => state.tenants.tenantId || '-');

  const tags = useSelector(state => state.tags.items.map(({name}) => name));

  const templates = useSelector(state => state.templates.items);

  const instructors = useSelector(state => state.instructors.items);

  const events = useSelector(state => state.events.items);
  const monthFirstDay = useSelector(state => state.events.monthFirstDay);
  const isCreatingEvent = useSelector(state => state.events.isCreating);

  const selectedDate = useSelector(state => state.bookings.selectedDate);

  const [isEventDialog1Visible, setEventDialog1Visible] = useState(false);
  const [isEventDialog2Visible, setEventDialog2Visible] = useState(false);
  const [eventForm1Data, setEventForm1Data] = useState({});
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [dateStart, setDateStart] = useState(selectedDate);
  const [repeat, setRepeat] = useState('once');

  const selectedEvent = selectedEventId && events.find(({id}) => id === selectedEventId);

  const eventForm1SubmitCallbackRef = useRef(null);
  const eventForm2SubmitCallbackRef = useRef(null);

  const filteredEvents = events.filter(event => {
    return (event.buildingId === selectedBuildingId) && _filterEvent(selectedDate)(event);
  });
  const upcomingEvents = filteredEvents.filter(event => !_hasEventEnded(selectedDate, selectedBuilding)(event));
  const endedEventsEvents = filteredEvents.filter(_hasEventEnded(selectedDate, selectedBuilding));

  const selectCompanyId = companyId => {
    dispatch(Companies.actions.setCompanyId(companyId));
  };

  const selectBuildingId = buildingId => {
    dispatch(Buildings.actions.setBuildingId(buildingId));
  };

  const selectTenantId = tenantId => {
    dispatch(Tenants.actions.setTenantId(tenantId));
  };

  const handleAddEventClick = () => {
    setEventDialog1Visible(true);
  };

  const handleEventDialog1Accept = () => {
    eventForm1SubmitCallbackRef.current();
  };

  const handleEventDialog1Reject = () => {
    setEventDialog1Visible(false);
    setEventForm1Data({});
  };

  const handleEventForm1Submit = data => {
    setEventForm1Data(data);
    setEventDialog1Visible(false);
    setEventDialog2Visible(true);
  };

  const handleEventDialog2Accept = () => {
    eventForm2SubmitCallbackRef.current();
  };

  const handleEventDialog2Back = () => {
    setEventDialog2Visible(false);
    setRepeat('once');
    setDateStart(selectedDate);
    setEventDialog1Visible(true);
  };

  const handleEventDialog2Reject = () => {
    setEventDialog2Visible(false);
    setRepeat('once');
    setDateStart(selectedDate);
    setEventForm1Data({});
    setSelectedEventId(null);
  };

  const handleEventForm2Submit = async data => {
    const {
      capacity,
      date,
      dateEnd,
      description,
      duration,
      imageUrl,
      instructorId,
      location,
      name,
      repeat,
      tags,
      timeStart
    } = data;

    const instructor = instructors.find(({id}) => id === instructorId);

    const dateStart = new JustADate(date._utcMidnightDateObj);

    const newData = {
      description,
      imageUrl,
      location,
      name,
      repeat,
      capacity: Number(capacity),
      date: dateStart.toUTCDate(),
      dateEnd: ((repeat === 'once') ? dateStart : new JustADate(dateEnd._utcMidnightDateObj)).toUTCDate(),
      instructor: exposeFields(instructor, 'id', 'name', 'photoUrl'),
      tags: tags || [],
      timeEnd: Number(timeStart) + Number(duration),
      timeStart: Number(timeStart)
    };

    if (selectedEvent) {
      await promisifiedDispatch(dispatch, Events.actions.update, {
        data: newData,
        id: selectedEventId
      });
    } else {
      newData.buildingId = eventForm1Data.buildingId;
      newData.tenantIds = eventForm1Data.tenantIds || [];
      newData.wholeBuilding = !eventForm1Data.tenantIds || (eventForm1Data.tenantIds.length < 1);

      await promisifiedDispatch(dispatch, Events.actions.create, {data: newData});
    }

    setEventDialog2Visible(false);
    setRepeat('once');
    setDateStart(selectedDate);
    setEventForm1Data({});
  };

  const handleCustomTagAdded = async tag => {
    const result = await promisifiedDispatch(dispatch, Tags.actions.create, {
      data: {
        name: tag
      }
    });

    return result.data.item.name;
  };

  const companyIdOptions = companies.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const buildingIdOptions = buildings.map(({id, name}) => ({
    label: name,
    value: id
  }));

  const tenantIdOptions = tenants.map(({id, name}) => ({
    label: name,
    value: id
  }));

  tenantIdOptions.unshift({
    label: 'All tenants',
    value: '-'
  });

  const eventListInnerContainerClassNames = classNames({
    [styles['event-list-inner-container']]: true,
    [styles['event-list-inner-container-empty']]: !selectedCompanyId || !selectedBuildingId
  });

  const hasBadge = params => events.some(_filterEvent(new JustADate(params.date)));

  const handleActiveStartDateChange = date => {
    dispatch(Events.actions.setMonthFirstDay(date));
  };

  const handleSelectedDateChange = date => {
    dispatch(Bookings.actions.setSelectedDate(date));
  };

  const handleEventSelected = id => {
    const event = events.find(event => event.id === id);

    setSelectedEventId(id);
    setRepeat(event.repeat);
    setEventDialog2Visible(true);
  };

  const handleDateStartChange = (value, setValue) => {
    setDateStart(value);

    setValue(value);
  };

  const handleRepeatChange = (value, setValue) => {
    setRepeat(value);

    setValue(value);
  };

  const eventForm1DefaultValues = {
    ...eventForm1Data,
    companyId: selectedCompanyId,
    buildingId: selectedBuildingId
  };
  const eventForm1Fields = createEventForm1Fields({
    buildings,
    companies,
    templates,
    tenants,
    selectBuildingId,
    selectCompanyId
  });

  const eventForm2DefaultValues = selectedEvent ? (() => {
    return {
      ...excludeFields(selectedEvent, 'id', 'instructor', 'isUpdating', 'updateError'),
      capacity: selectedEvent.capacity.toString(),
      duration: (selectedEvent.timeEnd - selectedEvent.timeStart).toString(),
      instructorId: selectedEvent.instructor.id,
      timeStart: selectedEvent.timeStart.toString()
    };
  })() : {
    date: new JustADate(),
    dateEnd: new JustADate(),
    repeat: 'once'
  };

  if (eventForm1Data.templateId) {
    const template = templates.find(({id}) => id === eventForm1Data.templateId);

    Object.assign(eventForm2DefaultValues, excludeFields(template, 'id', 'capacity', 'duration'));

    eventForm2DefaultValues.capacity = template.capacity.toString();
    eventForm2DefaultValues.duration = template.duration.toString();
    eventForm2DefaultValues.timeStart = template.timeStart.toString();
  }

  const handleEventDelete = async () => {
    await promisifiedDispatch(dispatch, Events.actions.removeEvent, {id: selectedEventId});

    setSelectedEventId(null);
    setEventDialog2Visible(false);
    setRepeat('once');
    setDateStart(selectedDate);
    setEventForm1Data({});
  };

  const isUpdatingOrRemoving = Boolean(selectedEvent && (selectedEvent.isUpdating || selectedEvent.isRemoving));

  const renderDeleteEventField = () => {
    return (
      <div className={styles['delete-event-field']}>
        <ClickableText
          disabled={isUpdatingOrRemoving || isCreatingEvent}
          text="Delete Event"
          userClassName={styles['delete-event-text']}
          onClick={handleEventDelete}
        />
      </div>
    );
  };

  const eventForm2Fields = createEventForm2Fields({
    dateStart,
    instructors,
    repeat,
    tags,
    renderDeleteEventField: selectedEvent && renderDeleteEventField,
    handleCustomTagAdded,
    handleDateStartChange,
    handleRepeatChange
  });

  const validateEventForm2 = ({repeat, date, dateEnd}) => {
    if (repeat !== 'once' && ((new JustADate(date)).getTime() > (new JustADate(dateEnd)).getTime())) {
      return {
        dateEnd: 'End Date must be after Start Date'
      };
    }
  };

  return (
    <Page title="Schedules">
      <div className={styles.content}>
        <div className={styles['calendar-container']}>
          <Calendar
            controlsChangeSelectedDate
            activeStartDate={monthFirstDay}
            selectedDate={selectedDate}
            hasBadge={hasBadge}
            onActiveStartDateChange={handleActiveStartDateChange}
            onSelectedDateChange={handleSelectedDateChange}
          />
        </div>
        <div className={styles['event-list-container']}>
          <div className={styles['event-list-container-title']}>Class Schedules</div>
          <div className={styles['event-list-filter-container']}>
            <div className={styles['event-list-filter-item']}>
              <SelectFormField
                filter={defaultFormFieldFilter}
                label="Company"
                name="companyId"
                options={companyIdOptions}
                placeholder="Please select..."
                value={selectedCompanyId}
                setTouched={() => {}}
                setValue={selectCompanyId}
              />
            </div>
            <div className={styles['event-list-filter-item']}>
              <SelectFormField
                disabled={!selectedCompanyId}
                filter={defaultFormFieldFilter}
                label="Building"
                name="buildingId"
                options={buildingIdOptions}
                placeholder="Please select..."
                value={selectedBuildingId}
                setTouched={() => {}}
                setValue={selectBuildingId}
              />
            </div>
            <div className={styles['event-list-filter-item']}>
              <SelectFormField
                disabled={!selectedBuildingId}
                filter={defaultFormFieldFilter}
                label="Tenant"
                name="tenantId"
                options={tenantIdOptions}
                placeholder="Please select..."
                value={selectedTenantId}
                setTouched={() => {}}
                setValue={selectTenantId}
              />
            </div>
          </div>
          <div className={eventListInnerContainerClassNames}>
            {
              (selectedCompanyId && selectedBuildingId) ? (
                <>
                  <EventList date={selectedDate} events={upcomingEvents} onEventSelected={handleEventSelected}/>
                  <EventList
                    ended
                    date={selectedDate}
                    events={endedEventsEvents}
                    onEventSelected={handleEventSelected}
                  />
                </>
              ) : (
                <div className={styles['event-list-dummy']}>
                  <div className={styles['event-list-dummy-image']}/>
                  <div className={styles['event-list-dummy-title']}>No classes to display</div>
                  <div className={styles['event-list-dummy-description']}>
                    Select a company and building to view available classes.
                  </div>
                </div>
              )
            }
          </div>
        </div>
        {
          isEventDialog1Visible && (
            <Dialog
              buttonAcceptDisabled={!selectedCompanyId || !selectedBuildingId}
              buttonAcceptText="Next"
              subtitle="Step 1/2"
              title="Add a New Class"
              onAccept={handleEventDialog1Accept}
              onReject={handleEventDialog1Reject}
            >
              <div className={styles['event-dialog-content']}>
                <div className={styles['event-dialog-1-form-wrapper']}>
                  <Form
                    key={`${companies.length}-${buildings.length}-${tenants.length}`}
                    defaultValues={eventForm1DefaultValues}
                    fields={eventForm1Fields}
                    submitCallbackRef={eventForm1SubmitCallbackRef}
                    onSubmit={handleEventForm1Submit}
                  />
                </div>
              </div>
            </Dialog>
          )
        }
        {
          isEventDialog2Visible && (
            <Dialog
              buttonAcceptDisabled={isCreatingEvent || Boolean(selectedEvent && selectedEvent.isUpdating)}
              buttonAcceptText={selectedEvent ? 'Update Class' : 'Create Class'}
              subtitle={selectedEvent ? null : 'Step 2/2'}
              title={selectedEvent ? selectedEvent.name : 'Add a New Class'}
              onAccept={handleEventDialog2Accept}
              onBack={selectedEvent ? null : handleEventDialog2Back}
              onReject={handleEventDialog2Reject}
            >
              <div className={styles['event-dialog-content']}>
                <div className={styles['event-dialog-2-form-wrapper']}>
                  <Form
                    defaultValues={eventForm2DefaultValues}
                    fields={eventForm2Fields}
                    submitCallbackRef={eventForm2SubmitCallbackRef}
                    validate={validateEventForm2}
                    onSubmit={handleEventForm2Submit}
                    onSubmitFailure={handleSubmitFailure(getCreateEventErrors)}
                  />
                </div>
              </div>
            </Dialog>
          )
        }
        <FloatingActionButton title="Add Event" onClick={handleAddEventClick}/>
      </div>
    </Page>
  );
};

export default SchedulesPage;
