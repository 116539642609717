import * as firebase from 'firebase/app';
import uuid from 'uuid/v4';

export default class FirebaseUploadAdapter {
  constructor(loader) {
    this._loader = loader;
    this._task = null;
  }

  async upload() {
    const file = await this._loader.file;

    try {
      const ref = firebase.storage()
        .ref()
        .child(`ck5Files/${uuid()}-${file.name}`);

      this._task = ref.put(file);

      this._task.on('state_changed', snapshot => {
        this._loader.uploadTotal = snapshot.totalBytes;
        this._loader.uploaded = snapshot.bytesTransferred;
      });

      await this._task;

      const url = await ref.getDownloadURL();

      return {default: url};
    } catch (err) {
      console.warn(err);

      throw new Error(`Couldn't upload file: ${file.name}`);
    } finally {
      this._task = null;
    }
  }

  abort() {
    if (!this._task) {
      return;
    }

    this._task.cancel();
  }
}
