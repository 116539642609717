// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".XUeNPpP9{height:100%;-js-display:flex;display:flex}._1yg4sfPv{flex-grow:1;padding:48px 56px;-js-display:flex;display:flex;flex-direction:column}._1OBJo9R8{font-size:26px;font-weight:700;line-height:32px;letter-spacing:-.8px;color:#111214}.AYPkx4oY{margin-top:28px;-js-display:flex;display:flex}._3h5IhT-C{flex-grow:1}._3h5IhT-C+._3h5IhT-C{margin-left:20px}.qHOQ6USq{-js-display:flex;display:flex;flex-direction:column;margin-top:20px}.kMSMuL4Q+.kMSMuL4Q{margin-top:20px}._2IJ--AGO{padding:0 56px;-js-display:flex;display:flex;justify-content:flex-end}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,UACE,WAAY,CACZ,gBAAa,CAAb,YACF,CACA,WACE,WAAY,CACZ,iBAAkB,CAClB,gBAAa,CAAb,YAAa,CACb,qBACF,CACA,WACE,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,oBAAsB,CACtB,aACF,CACA,UACE,eAAgB,CAChB,gBAAa,CAAb,YACF,CACA,WACE,WACF,CACA,sBACE,gBACF,CACA,UACE,gBAAa,CAAb,YAAa,CACb,qBAAsB,CACtB,eACF,CACA,oBACE,eACF,CACA,WACE,cAAe,CACf,gBAAa,CAAb,YAAa,CACb,wBACF","file":"styles.less","sourcesContent":[".content {\n  height: 100%;\n  display: flex;\n}\n.event-list-container {\n  flex-grow: 1;\n  padding: 48px 56px;\n  display: flex;\n  flex-direction: column;\n}\n.event-list-container-title {\n  font-size: 26px;\n  font-weight: 700;\n  line-height: 32px;\n  letter-spacing: -0.8px;\n  color: #111214;\n}\n.event-list-filter-container {\n  margin-top: 28px;\n  display: flex;\n}\n.event-list-filter-item {\n  flex-grow: 1;\n}\n.event-list-filter-item + .event-list-filter-item {\n  margin-left: 20px;\n}\n.field-list-container {\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n}\n.field-list-item + .field-list-item {\n  margin-top: 20px;\n}\n.button-container {\n  padding: 0 56px;\n  display: flex;\n  justify-content: flex-end;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "XUeNPpP9",
	"event-list-container": "_1yg4sfPv",
	"event-list-container-title": "_1OBJo9R8",
	"event-list-filter-container": "AYPkx4oY",
	"event-list-filter-item": "_3h5IhT-C",
	"field-list-container": "qHOQ6USq",
	"field-list-item": "kMSMuL4Q",
	"button-container": "_2IJ--AGO"
};
module.exports = exports;
