import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

const TextareaFormField = props => {
  const {
    description,
    disabled,
    error,
    label,
    name,
    placeholder,
    rowCount,
    touched,
    value,
    setTouched,
    setValue,
    onChange
  } = props;

  const hasError = Boolean(touched && error);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles['label-disabled']]: disabled
  });
  const textareaClassNames = classNames({
    [styles.textarea]: true,
    [styles['textarea-disabled']]: disabled,
    [styles['textarea-no-resize']]: rowCount > 0,
    [styles['textarea-with-error']]: hasError
  });

  const textareaProps = {
    name,
    value,
    disabled,
    className: textareaClassNames,
    onBlur() {
      // Prevent passing arguments to setValue

      setTouched();
    },
    onChange(e) {
      const {value} = e.target;

      if (onChange) {
        onChange(value, setValue);
      } else {
        setValue(value);
      }
    }
  };

  if (placeholder) {
    textareaProps.placeholder = placeholder;
  }

  if (rowCount > 0) {
    textareaProps.rows = rowCount;
  }

  return (
    <div className={styles['textarea-form-field']}>
      {
        label && (
          <div className={labelClassNames}>{label}</div>
        )
      }
      {
        <textarea {...textareaProps}/>
      }
      {
        Boolean(description) && (
          <div className={styles.description}>
            {description}
          </div>
        )
      }
      {
        hasError && (
          <div className={styles.error}>{error}</div>
        )
      }
    </div>
  );
};

TextareaFormField.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rowCount: PropTypes.number,
  touched: PropTypes.bool,
  value: PropTypes.string,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

TextareaFormField.defaultProps = {
  description: null,
  disabled: false,
  error: null,
  label: null,
  placeholder: null,
  rowCount: null,
  touched: false,
  value: '',
  onChange: null
};

export default TextareaFormField;
