import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import uuid from 'uuid/v4';

import Button from '../../../button';

import InputFormField from '../input';
import RichTextFormField from '../rich-text';
import UploadCompactFormField from '../upload-compact';

import styles from './styles.less';

const FILE_FIELD_DESCRIPTION = 'Optionally, you can upload a file here for the user to download, which will apear ' +
  'below the content. File formats supported are images (jpg, png, etc) as well as PDF.';

const ContentModulesFormField = props => {
  const {
    description,
    disabled,
    error,
    label,
    touched,
    value,
    setTouched,
    setValue,
    onChange
  } = props;

  const [isDialogVisible, setDialogVisible] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [moduleName, setModuleName] = useState('');
  const [moduleContent, setModuleContent] = useState('');
  const [moduleFile, setModuleFile] = useState(null);
  const [moduleNameError, setModuleNameError] = useState(null);
  const [moduleContentError, setModuleContentError] = useState(null);

  const hasError = Boolean(touched && error);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles['label-disabled']]: disabled
  });

  const contentClassNames = classNames({
    [styles.content]: true,
    [styles['content-disabled']]: disabled,
    [styles['content-with-error']]: hasError
  });

  const doSetValue = v => {
    if (onChange) {
      onChange(v, setValue);
    } else {
      setValue(v);
    }

    setTouched();
  };

  const handleDragEnd = ({destination, source}) => {
    if (!destination) {
      return;
    }

    const v = [...value];

    const [module] = v.splice(source.index, 1);

    v.splice(destination.index, 0, module);

    doSetValue(v);
  };

  const handleModuleClick = module => () => {
    setSelectedModuleId(module.id);
    set(setModuleContent, setModuleContentError)(module.content);
    setModuleFile(module.file);
    set(setModuleName, setModuleNameError)(module.name);
    setDialogVisible(true);
  };

  const addModule = () => {
    setSelectedModuleId(null);
    set(setModuleContent, setModuleContentError)('');
    setModuleFile(null);
    set(setModuleName, setModuleNameError)('');
    setDialogVisible(true);
  };

  const set = (setter, errorSetter) => value => {
    errorSetter(null);
    setter(value);
  };

  const saveModule = () => {
    let hasErr = false;

    if (!moduleName) {
      setModuleNameError('Module name is required');
      hasErr = true;
    }

    if (!moduleContent.trim()) {
      setModuleContentError('Content is required');
      hasErr = true;
    }

    if (hasErr) {
      return;
    }

    const v = [...value];

    const data = {
      content: moduleContent,
      file: moduleFile,
      name: moduleName
    };

    if (selectedModuleId) {
      const index = v.findIndex(({id}) => id === selectedModuleId);

      v[index] = {
        ...v[index],
        ...data
      };
    } else {
      v.push({
        ...data,
        id: uuid()
      });
    }

    doSetValue(v);

    setDialogVisible(false);
  };

  const removeModule = () => {
    const v = [...value];

    const index = v.findIndex(({id}) => id === selectedModuleId);

    v.splice(index, 1);

    doSetValue(v);

    setDialogVisible(false);
  };

  return (
    <div className={styles.component}>
      {
        label && (
          <div className={labelClassNames}>{label}</div>
        )
      }
      <div className={contentClassNames}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {
              provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {
                    value.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={disabled}>
                        {
                          provided => (
                            <div
                              ref={provided.innerRef}
                              className={styles.module}
                              onClick={handleModuleClick(item)}
                              {...provided.draggableProps}
                            >
                              <div className={styles['drag-handle']} {...provided.dragHandleProps}/>
                              <div className={styles['module-index']}>{index + 1}:</div>
                              <div className={styles['module-name']}>{item.name}</div>
                            </div>
                          )
                        }
                      </Draggable>
                    ))
                  }
                  {provided.placeholder}
                </div>
              )
            }
          </Droppable>
        </DragDropContext>
        <div className={styles['add-module-button']} onClick={addModule}>
          <div className={styles.plus}/>
          <div className={styles['add-module-button-text']}>Add a Module</div>
        </div>
      </div>
      {
        Boolean(description) && (
          <div className={styles.description}>
            {description}
          </div>
        )
      }
      {
        hasError && (
          <div className={styles.error}>{error}</div>
        )
      }
      {
        isDialogVisible && (
          <div className={styles.overlay}>
            <div className={styles['overlay-content']}>
              <div className={styles['overlay-form-wrapper']}>
                <div className={styles['overlay-form-field']}>
                  <InputFormField
                    touched
                    error={moduleNameError}
                    label="Module Name"
                    name="name"
                    type="text"
                    value={moduleName}
                    setTouched={() => {}}
                    setValue={set(setModuleName, setModuleNameError)}
                  />
                </div>
                <div className={styles['overlay-form-field']}>
                  <RichTextFormField
                    touched
                    error={moduleContentError}
                    label="Content"
                    value={moduleContent}
                    setTouched={() => {}}
                    setValue={set(setModuleContent, setModuleContentError)}
                  />
                </div>
                <div className={styles['overlay-form-field']}>
                  <UploadCompactFormField
                    accept="image/png, image/jpeg, application/pdf"
                    createPath={name => `moduleFiles/${uuid()}-${name}`}
                    description={FILE_FIELD_DESCRIPTION}
                    label="File Upload (optional)"
                    value={moduleFile}
                    setTouched={() => {}}
                    setValue={setModuleFile}
                  />
                </div>
              </div>
              <div className={styles['overlay-action-container']}>
                <div className={styles['overlay-actions-left']}>
                  <Button kind={Button.KIND.SECONDARY} text="Cancel" onClick={() => setDialogVisible(false)}/>
                  {
                    selectedModuleId && (
                      <Button
                        kind={Button.KIND.SECONDARY}
                        text="Delete Module"
                        userClassName={styles['button-remove']}
                        userTextClassName={styles['button-remove-text']}
                        onClick={removeModule}
                      />
                    )
                  }
                </div>
                <Button text="Save" onClick={saveModule}/>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

ContentModulesFormField.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.shape({
    //
  })),
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

ContentModulesFormField.defaultProps = {
  description: null,
  disabled: false,
  error: null,
  label: null,
  touched: false,
  value: [],
  onChange: null
};

export default ContentModulesFormField;
