import React from 'react';

import history from '../../../lib/history';

import styles from './styles.less';

const NotFoundPage = () => {
  const {pathname, search, hash} = history.location;

  if (!/^\/404\/?/.test(pathname)) {
    window.location = `/404?href=${pathname}${search}${hash}`;
  }

  return (
    <div className={styles['not-found-page']}>
      <div className={styles['not-found-message']}>
        Page not found :(
      </div>
    </div>
  );
};

export default NotFoundPage;
