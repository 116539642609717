import {API_INITIALIZED} from '@ololoepepe/redux-api';

import {put, select, takeLatest} from 'redux-saga/effects';

import Session from '../ducks/session';

function * handleInit() {
  const isLoggedIn = yield select(state => state.session.isLoggedIn);

  if (isLoggedIn) {
    yield put(Session.actions.fetchCommonData());
  }
}

export default function * init() {
  yield takeLatest(API_INITIALIZED.ACTION, handleInit);
}
