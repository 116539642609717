import * as firebase from 'firebase/app';

import {createDuck} from '../../lib/firebase-provider';

export default createDuck('user', {
  collectionName: 'users',
  createQuery: collection => {
    return collection.where(firebase.firestore.FieldPath.documentId(), '==', firebase.auth().currentUser.uid);
  }
}, {
  reducerOptions: {
    customInitialState: {
      item: {
        id: null,
        companyId: null,
        tenantId: null,
        roles: [],
        email: '',
        name: '',
        photoUrl: null,
        buildingId: null
      }
    }
  },
  single: true
});
