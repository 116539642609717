import firebase from 'firebase/app';
import React, {useRef, useState} from 'react';

import {getCreateBuildingErrors} from '../../../lib/form/error-getters';
import {createAddBuildingFormFields} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';

import {excludeFields, exposeFields} from '../../../lib/helper';

import useFirebaseEntity from '../../../hooks/use-firebase-entity';

import ContentContainer from '../../common/content-container';
import ContentTable from '../../common/content-table';
import Dialog from '../../common/dialog';
import Form from '../../common/form';
import Page from '../../common/page';
import Sidebar from '../../common/sidebar';

import styles from './styles.less';

const BuildingsPage = () => {
  const [
    items,
    {hasFetched, isCreating, isUpdating},
    {create, remove, update}
  ] = useFirebaseEntity('buildings');

  const [companies, {hasFetched: hasFetchedCompanies}] = useFirebaseEntity('companies');

  const formSubmitCallbackRef = useRef(null);

  const [search, setSearch] = useState('');
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [{
    style: {
      textAlign: 'left'
    },
    title: 'Name'
  }, {
    style: {
      textAlign: 'left',
      width: '250px'
    },
    title: 'Company'
  }, {
    style: {
      textAlign: 'left',
      width: '350px'
    },
    title: 'Waiver'
  }];

  const rows = items.map(({id, ...rest}) => ({
    key: id,
    data: {
      id,
      ...rest
    }
  }));

  const filterRow = data => {
    return data.name.toLowerCase().includes(search.toLowerCase());
  };

  const renderRowCell = (data, index) => {
    switch (index) {
    case 0:
      return (
        <div className={styles['cell-name']}>
          <div>{data.name}</div>
        </div>
      );
    case 1:
      return hasFetchedCompanies ? companies.find(({id}) => id === data.companyId).name : 'Loading...';
    case 2:
      return (
        <div className={styles['cell-waiver']} title={data.waiver}>
          <div className={styles.waiver}>{data.waiver}</div>
        </div>
      );
    default:
      return null;
    }
  };

  const handleAddButtonClick = () => {
    setSelectedRow(null);
    setDialogVisible(true);
  };

  const handleRowClick = ({data}) => {
    setSelectedRow(data);
    setDialogVisible(true);
  };

  const handleRemove = async () => {
    const tenantsSnapshot = await firebase.firestore().collection('tenants')
      .where('buildingIds', 'array-contains', selectedRow.id)
      .get();

    const usersSnapshot = await firebase.firestore().collection('users')
      .where('buildingId', '==', selectedRow.id)
      .get();

    const tenantsRefs = tenantsSnapshot.docs.map(item => item.ref);
    const usersRefs = usersSnapshot.docs.map(item => item.ref);

    const success = await remove(selectedRow.id, async tx => {
      const filteredTenantsDocs = [];
      const filteredUsersRefs = [];

      /* eslint-disable no-await-in-loop */
      for (const ref of tenantsRefs) {
        const doc = await tx.get(ref);

        if (doc.data().buildingIds.includes(selectedRow.id)) {
          filteredTenantsDocs.push(doc);
        }
      }

      for (const ref of usersRefs) {
        const doc = await tx.get(ref);

        if (doc.data().buildingId === selectedRow.id) {
          filteredUsersRefs.push(ref);
        }
      }
      /* eslint-enable no-await-in-loop */

      return {filteredTenantsDocs, filteredUsersRefs};
    }, async (tx, {filteredTenantsDocs, filteredUsersRefs}) => {
      /* eslint-disable no-await-in-loop */
      for (const doc of filteredTenantsDocs) {
        await tx.update(doc.ref, {
          buildingIds: doc.data().buildingIds.filter(id => id !== selectedRow.id)
        });
      }

      for (const ref of filteredUsersRefs) {
        await tx.update(ref, {buildingId: null});
      }
      /* eslint-enable no-await-in-loop */
    });

    if (!success) {
      throw new Error('Please, try again');
    }

    setDialogVisible(false);
  };

  const handleFormSubmit = async data => {
    if (selectedRow) {
      const success = await update(selectedRow.id, {
        ...excludeFields(data, 'isSubscribed'),
        subscriptions: {
          ...selectedRow?.subscriptions,
          content: Boolean(data.isSubscribed)
        }
      });

      if (!success) {
        throw new Error('Please, try again');
      }
    } else {
      const id = await create({
        ...excludeFields(data, 'isSubscribed'),
        subscriptions: {
          content: Boolean(data.isSubscribed)
        },
        timezoneOffset: -660
      });

      if (!id) {
        throw new Error('Please, try again');
      }
    }

    setDialogVisible(false);
  };

  const defaultValues = selectedRow ? {
    ...exposeFields(selectedRow, 'name', 'companyId', 'waiver'),
    isSubscribed: Boolean(selectedRow?.subscriptions?.content)
  } : {
    isSubscribed: false
  };

  return (
    <Page title="Buildings">
      <div className={styles.content}>
        <Sidebar
          items={[{
            path: '/tenants',
            text: 'Tenants'
          }, {
            path: '/buildings',
            text: 'Buildings'
          }, {
            path: '/companies',
            text: 'Companies'
          }]}
        />
        <ContentContainer
          addButtonText="New Building"
          search={search}
          title="Manage Buildings"
          onAddButtonClick={hasFetchedCompanies ? handleAddButtonClick : null}
          onSearchChange={setSearch}
        >
          <ContentTable
            columns={columns}
            hasFetched={hasFetched}
            rows={rows}
            filterRow={filterRow}
            renderRowCell={renderRowCell}
            onRowClick={hasFetchedCompanies ? handleRowClick : null}
          />
        </ContentContainer>
        {
          isDialogVisible && (
            <Dialog
              buttonAcceptDisabled={isCreating || isUpdating}
              buttonAcceptText={selectedRow ? 'Save' : 'Create Building'}
              buttonRemoveText="Delete Building"
              title={selectedRow ? 'Edit Building' : 'Add a New Building'}
              onAccept={() => formSubmitCallbackRef.current()}
              onReject={() => setDialogVisible(false)}
              onRemove={selectedRow && handleRemove}
            >
              <div className={styles['dialog-content']}>
                <div className={styles['dialog-form-wrapper']}>
                  <Form
                    defaultValues={defaultValues}
                    fields={createAddBuildingFormFields({companies})}
                    submitCallbackRef={formSubmitCallbackRef}
                    onSubmit={handleFormSubmit}
                    onSubmitFailure={handleSubmitFailure(getCreateBuildingErrors)}
                  />
                </div>
              </div>
            </Dialog>
          )
        }
      </div>
    </Page>
  );
};

export default BuildingsPage;
