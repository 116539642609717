import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

const TagsFormField = props => {
  const {
    description,
    disabled,
    error,
    label,
    placeholder,
    tags,
    touched,
    value,
    setTouched,
    setValue,
    onChange,
    onCustomTagAdded
  } = props;

  const inputRef = useRef(null);

  const [customTag, setCustomTag] = useState('');
  const [isAddingTag, setAddingTag] = useState(false);

  const hasError = Boolean(touched && error);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles['label-disabled']]: disabled
  });
  const selectedTagAreaClassNames = classNames({
    [styles['selected-tag-area']]: true,
    [styles['selected-tag-area-disabled']]: disabled,
    [styles['selected-tag-area-with-error']]: hasError
  });
  const tagClassNames = classNames({
    [styles.tag]: true,
    [styles['tag-disabled']]: disabled
  });
  const placeholderClassNames = classNames({
    [styles.placeholder]: true,
    [styles['placeholder-disabled']]: disabled
  });

  const availableTags = tags.filter(tag => !value.includes((typeof tag === 'string') ? tag : tag.value));

  const getTag = tv => {
    const tag = tags.find(t => (typeof t === 'string') ? (t === tv) : (t.value === tv));

    return ((typeof tag === 'string') ? {value: tag} : tag);
  };

  const doSetValue = v => {
    if (onChange) {
      onChange(v, setValue);
    } else {
      setValue(v);
    }

    setTouched();
  };

  const selectTag = tag => () => {
    doSetValue(value.concat(tag));
  };

  const unselectTag = tag => () => {
    doSetValue(value.filter(t => t !== tag));
  };

  const selectCustomTag = async () => {
    if (!customTag) {
      return;
    }

    let tv = customTag;

    if (!getTag(customTag)) {
      setAddingTag(true);

      try {
        tv = await onCustomTagAdded(customTag);

        setAddingTag(false);
      } catch (err) {
        console.error(err);

        setAddingTag(false);

        return;
      }
    }

    selectTag(tv)();

    setCustomTag('');
  };

  const handleInputChange = e => {
    const {value} = e.target;

    setCustomTag(value);
  };

  const handleInputKeyPress = e => {
    switch (e.key) {
    case 'Enter':
      e.preventDefault();

      selectCustomTag();

      break;
    case 'Escape':
      setCustomTag('');

      inputRef.current.blur();

      break;
    default:
      break;
    }
  };

  return (
    <div className={styles['tags-form-field']}>
      {
        label && (
          <div className={labelClassNames}>
            {label}
          </div>
        )
      }
      <div className={styles['tag-area-container']}>
        <div className={selectedTagAreaClassNames}>
          {
            (value.length > 0) ? value.filter(tv => getTag(tv)).map(tv => {
              const tag = getTag(tv);

              return (
                <div key={tag.value} className={styles['tag-wrapper']}>
                  <div className={tagClassNames} onClick={disabled ? null : unselectTag(tag.value)}>
                    {tag.label || tag.value}
                  </div>
                </div>
              );
            }) : (
              Boolean(placeholder) && !customTag && (
                <div className={placeholderClassNames}>{placeholder}</div>
              )
            )
          }
          {
            onCustomTagAdded && !disabled && (
              <div className={styles['input-wrapper']}>
                <input
                  ref={inputRef}
                  className={styles.input}
                  disabled={isAddingTag}
                  value={customTag}
                  onBlur={selectCustomTag}
                  onChange={handleInputChange}
                  onKeyPress={handleInputKeyPress}
                />
              </div>
            )
          }
        </div>
        <div className={styles['available-tag-area']}>
          {
            availableTags.map(tv => {
              const tag = (typeof tv === 'string') ? getTag(tv) : tv;

              return (
                <div key={tag.value} className={styles['tag-wrapper']}>
                  <div className={tagClassNames} onClick={disabled ? null : selectTag(tag.value)}>
                    {tag.label || tag.value}
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
      {
        Boolean(description) && (
          <div className={styles.description}>
            {description}
          </div>
        )
      }
      {
        hasError && (
          <div className={styles.error}>{error}</div>
        )
      }
    </div>
  );
};

const TAG_PROP_TYPE = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
]);

TagsFormField.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  tags: PropTypes.arrayOf(TAG_PROP_TYPE).isRequired,
  touched: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onCustomTagAdded: PropTypes.func
};

TagsFormField.defaultProps = {
  description: null,
  disabled: false,
  error: null,
  label: null,
  placeholder: null,
  touched: false,
  value: [],
  onChange: null,
  onCustomTagAdded: null
};

TagsFormField.TAG_PROP_TYPE = TAG_PROP_TYPE;

export default TagsFormField;
