import {useEffect, useMemo, useReducer, useState} from 'react';

import firebase from 'firebase/app';

function _itemsReducer(state = [], {type, data}) {
  switch (type) {
  case 'added_bulk':
    return [...state, ...data];
  case 'clear':
    return [];
  case 'modified': {
    const {id, ...rest} = data;

    const index = state.findIndex(item => item.id === id);

    if (index < 0) {
      return state;
    }

    const newState = [...state];

    newState.splice(index, 1, {
      id,
      ...newState[index],
      ...rest
    });

    return newState;
  }

  case 'removed': {
    const index = state.findIndex(item => item.id === data.id);

    if (index < 0) {
      return state;
    }

    const newState = [...state];

    newState.splice(index, 1);

    return newState;
  }

  default:
    return state;
  }
}

async function _updateOrdering(tx, collectionName, update) {
  const orderingRef = firebase.firestore().collection('ordering').doc(collectionName);

  const orderingDoc = await tx.get(orderingRef);

  if (!orderingDoc.exists) {
    throw new Error('Ordering does not exist');
  }

  const orderingData = orderingDoc.data();

  update(orderingData);

  tx.set(orderingRef, orderingData);

  return orderingData;
}

const _addToOrdering = (orderingKeys, id) => orderingData => {
  for (const orderingKey of orderingKeys) {
    let order = orderingData[orderingKey];

    if (!order) {
      order = [];

      orderingData[orderingKey] = order;
    }

    order.push(id);
  }
};

const _removeFromOrdering = (orderingKeys, id) => orderingData => {
  for (const orderingKey of orderingKeys) {
    const order = orderingData[orderingKey];

    if (order) {
      const index = order.indexOf(id);

      if (index >= 0) {
        order.splice(index, 1);
      }

      if (order.length > 0) {
        orderingData[orderingKey] = order;
      } else {
        delete orderingData[orderingKey];
      }
    }
  }
};

const useOrderedFirebaseEntity = (collectionName, query = [], options = {}) => {
  const {
    activeOrderingKey = '_default',
    getOrderingKeys = () => (['_default']),
    mapData = item => item
  } = options;

  const [items, dispatchItems] = useReducer(_itemsReducer, []);
  const [hasFetched, setHasFetched] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [ordering, setOrdering] = useState({});
  // This is used to prevent flickering of item list after DnD when Firebase has not yet updated
  const [temporaryOrdering, setTemporaryOrdering] = useState(null);

  const queryJson = JSON.stringify(query);

  useEffect(() => {
    if (!collectionName) {
      return;
    }

    const unsubscribeOrdering = firebase.firestore().collection('ordering')
      .where(firebase.firestore.FieldPath.documentId(), '==', collectionName)
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(({type, doc}) => {
          if (!['added', 'modified'].includes(type)) {
            return;
          }

          setOrdering(doc.data());
        });
      }, err => {
        console.warn('Realtime updates error:', err);
      });

    let q = firebase.firestore().collection(collectionName);

    query.forEach(([key, value, op = '==']) => {
      q = q.where(key, op, value);
    });

    const unsubscribe = q.onSnapshot(snapshot => {
      const list = [];

      snapshot.docChanges().forEach(({type, doc}) => {
        const item = mapData({
          id: doc.id,
          ...doc.data()
        });

        if (type === 'added') {
          list.push(item);
        } else {
          dispatchItems({
            type,
            data: item
          });
        }
      });

      if (list.length > 0) {
        dispatchItems({
          type: 'added_bulk',
          data: list
        });
      }

      setHasFetched(true);
    }, err => {
      console.warn('Realtime updates error:', err);
    });

    return () => {
      unsubscribeOrdering();
      unsubscribe();

      setOrdering([]);
      dispatchItems({type: 'clear'});

      setHasFetched(false);
    };
  }, [collectionName, queryJson]);

  const create = useMemo(() => async (data, extraRead, extraWrite) => {
    setIsCreating(true);

    try {
      const collection = firebase.firestore().collection(collectionName);

      const ref = collection.doc();

      return firebase.firestore().runTransaction(async tx => {
        const doc = await ref.get();

        let extraData = null;

        if (extraRead) {
          extraData = await extraRead(tx);
        }

        await _updateOrdering(tx, collectionName, _addToOrdering(getOrderingKeys(data), doc.id));

        await tx.set(ref, data);

        if (extraWrite) {
          await extraWrite(tx, extraData);
        }

        return doc.id;
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsCreating(false);
    }
  }, [collectionName]);

  const update = useMemo(() => async (id, data, extraRead, extraWrite) => {
    setIsUpdating(true);

    try {
      const ref = firebase.firestore().collection(collectionName).doc(id);

      await firebase.firestore().runTransaction(async tx => {
        const doc = await tx.get(ref);

        if (!doc.exists) {
          throw new Error('Document does not exist');
        }

        const oldData = doc.data();

        let extraData = null;

        if (extraRead) {
          extraData = await extraRead(tx);
        }

        await _updateOrdering(tx, collectionName, orderingData => {
          _removeFromOrdering(getOrderingKeys(oldData), id)(orderingData);
          _addToOrdering(getOrderingKeys({...oldData, ...data}), id)(orderingData);
        });

        if (extraWrite) {
          await extraWrite(tx, extraData);
        }

        await tx.update(ref, data);
      });

      return true;
    } catch (err) {
      console.error(err);

      return false;
    } finally {
      setIsUpdating(false);
    }
  }, [collectionName]);

  const remove = useMemo(() => async (id, extraRead, extraWrite) => {
    setIsRemoving(true);

    try {
      const ref = firebase.firestore().collection(collectionName).doc(id);

      await firebase.firestore().runTransaction(async tx => {
        const doc = await tx.get(ref);

        if (!doc.exists) {
          throw new Error('Document does not exist');
        }

        let extraData = null;

        if (extraRead) {
          extraData = await extraRead(tx);
        }

        await _updateOrdering(tx, collectionName, _removeFromOrdering(getOrderingKeys(doc.data()), id));

        if (extraWrite) {
          await extraWrite(tx, extraData);
        }

        await tx.delete(ref);
      });

      return true;
    } catch (err) {
      console.error(err);

      return false;
    } finally {
      setIsRemoving(false);
    }
  }, [collectionName]);

  const reorder = useMemo(() => async (id, targetIndex) => {
    setIsUpdating(true);

    const tmpOrdering = {...ordering};

    const o = tmpOrdering[activeOrderingKey];

    if (o) {
      const index = o.indexOf(id);

      if (index >= 0) {
        const to = [...o];

        to.splice(index, 1);
        to.splice(targetIndex, 0, id);

        setTemporaryOrdering({
          ...tmpOrdering,
          [activeOrderingKey]: to
        });
      }
    }

    try {
      const ref = firebase.firestore().collection(collectionName).doc(id);

      const newOrdering = await firebase.firestore().runTransaction(async tx => {
        const doc = await tx.get(ref);

        if (!doc.exists) {
          throw new Error('Document does not exist');
        }

        return _updateOrdering(tx, collectionName, orderingData => {
          const ordering = orderingData[activeOrderingKey];

          if (!ordering) {
            throw new Error('Ordering key does not exist');
          }

          const index = ordering.indexOf(id);

          if (index < 0) {
            throw new Error('Ordering item is ot present in list');
          }

          ordering.splice(index, 1);
          ordering.splice(targetIndex, 0, id);
        });
      });

      setOrdering(newOrdering);
    } catch (err) {
      console.error(err);
    } finally {
      setTemporaryOrdering(null);
      setIsUpdating(false);
    }
  }, [collectionName, ordering, activeOrderingKey]);

  const orderedItems = useMemo(() => {
    const order = (temporaryOrdering || ordering)[activeOrderingKey];

    if (!order) {
      return items;
    }

    return items.slice().sort((i1, i2) => {
      const ind1 = order.indexOf(i1.id);
      const ind2 = order.indexOf(i2.id);

      if ((ind1 < 0) || (ind2 < 0)) {
        return ind2 - ind1;
      }

      return ind1 - ind2;
    });
  }, [items, ordering, activeOrderingKey, temporaryOrdering]);

  return [orderedItems, {hasFetched, isCreating, isRemoving, isUpdating, ordering}, {create, remove, reorder, update}];
};

export default useOrderedFirebaseEntity;
