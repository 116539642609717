import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

const CheckboxFormField = props => {
  const {
    description,
    disabled,
    error,
    label,
    touched,
    value,
    setTouched,
    setValue,
    onChange
  } = props;

  const hasError = Boolean(touched && error);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles['label-disabled']]: disabled
  });

  const handleChange = e => {
    const {checked} = e.target;

    if (onChange) {
      onChange(checked, setValue);
    } else {
      setValue(checked);
    }

    setTouched();
  };

  const id = `checkbox-${name}`;

  const descriptionClassNames = classNames({
    [styles.description]: true,
    [styles['description-disabled']]: disabled
  });

  return (
    <div className={styles.component}>
      {
        label && (
          <div className={labelClassNames}>
            {label}
          </div>
        )
      }
      <div className={styles['input-wrapper']}>
        <input
          checked={value}
          className={styles.checkbox}
          disabled={disabled}
          id={id}
          name={name}
          type="checkbox"
          onChange={handleChange}
        />
        {
          Boolean(description) && (
            <label className={descriptionClassNames} htmlFor={id} title={description}>
              {(typeof description === 'string') ? ` ${description}` : description}
            </label>
          )
        }
      </div>
      {
        hasError && (
          <div className={styles.error}>{error}</div>
        )
      }
    </div>
  );
};

CheckboxFormField.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.bool,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

CheckboxFormField.defaultProps = {
  description: null,
  disabled: false,
  error: null,
  label: null,
  touched: false,
  value: false,
  onChange: null
};

export default CheckboxFormField;
