import {promisifiedDispatch} from '@ololoepepe/redux-api';

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getLoginByEmailErrors} from '../../../lib/form/error-getters';
import {createLoginFormFields} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';

import Auth from '../../../redux/ducks/auth';

import AuthPage from '../../common/auth-page';
import Button from '../../common/button';
import ClickableText from '../../common/clickable-text';

import styles from './styles.less';

const LoginPage = () => {
  const dispatch = useDispatch();

  const isLoggingIn = useSelector(state => state.auth.isLoggingIn);

  const loginByEmail = data => promisifiedDispatch(dispatch, Auth.actions.logIn, {data});

  const renderForgotPasswordField = () => {
    return (
      <ClickableText
        href="/forgot-password"
        text="Forgot your password?"
        userClassName={styles['forgot-password-field-text']}
      />
    );
  };

  const formProps = {
    title: 'Log In',
    actions: [{
      buttonWidth: Button.WIDTH.FILL,
      isPrimary: true,
      text: 'Log In'
    }],
    fields: createLoginFormFields({renderForgotPasswordField}),
    disabled: isLoggingIn,
    onSubmit: loginByEmail,
    onSubmitFailure: handleSubmitFailure(getLoginByEmailErrors)
  };

  return (
    <AuthPage formProps={formProps}/>
  );
};

export default LoginPage;
