// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3o-kC5iv{font-size:13px;line-height:16px;color:#9fa0a6;text-decoration:underline}._3o-kC5iv:not(:hover){text-decoration:underline}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,cAAe,CACf,gBAAiB,CACjB,aAAc,CACd,yBACF,CACA,uBACE,yBACF","file":"styles.less","sourcesContent":[".forgot-password-field-text {\n  font-size: 13px;\n  line-height: 16px;\n  color: #9fa0a6;\n  text-decoration: underline;\n}\n.forgot-password-field-text:not(:hover) {\n  text-decoration: underline;\n}\n"]}]);
// Exports
exports.locals = {
	"forgot-password-field-text": "_3o-kC5iv"
};
module.exports = exports;
