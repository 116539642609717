import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import Button from '../../../button';
import UploadContainer from '../../../upload-container';

import styles from './styles.less';

const UploadCompactFormField = props => {
  const {
    accept,
    description,
    disabled,
    error,
    label,
    touched,
    value,
    createPath,
    setTouched,
    setValue,
    transform,
    onChange
  } = props;

  const hasError = Boolean(touched && error);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles['label-disabled']]: disabled
  });

  const doSetValue = v => {
    if (onChange) {
      onChange(v, setValue);
    } else {
      setValue(v);
    }
  };

  const handleUpload = async (ref, file) => {
    const url = await ref.getDownloadURL();

    doSetValue({
      url,
      name: file.name,
      size: file.size,
      type: file.type
    });

    setTouched(true);
  };

  const handleError = err => {
    console.warn(err);

    // eslint-disable-next-line no-alert
    alert(err.message);
  };

  const renderButtonNoValue = () => (
    <div className={styles.placeholder}>
      <div className={styles['placeholder-text-line1']}>Drag and drop to upload</div>
      <div className={styles['placeholder-text-line2']}>
        Or <span className={styles['placeholder-text-accent']}>Browse</span> to choose a file
      </div>
    </div>
  );

  const hasPreview = value && value.type.startsWith('image/');

  const renderButtonWithValue = () => (
    <Button
      height={Button.HEIGHT.SMALL}
      kind={Button.KIND.SECONDARY}
      text={`Replace ${hasPreview ? 'Image' : 'File'}`}
      userTextClassName={styles['button-text']}
    />
  );

  const clear = () => doSetValue(null);

  const contentClassNames = classNames({
    [styles.content]: true,
    [styles['content-no-preview']]: !hasPreview
  });
  const infoContainerClassNames = classNames({
    [styles['info-container']]: true,
    [styles['info-container-no-preview']]: !hasPreview
  });
  const uploadContainerClassNames = classNames({
    [styles['upload-container']]: true,
    [styles['upload-container-with-error']]: hasError
  });

  const content = value ? (
    <div className={contentClassNames}>
      {
        hasPreview && (
          <div className={styles['preview-wrapper']}>
            <img src={value.url} className={styles.preview}/>
          </div>
        )
      }
      <div className={infoContainerClassNames}>
        <div>
          <div className={styles['file-name']}>{value.name}</div>
          <div className={styles['file-size']}>
            {Math.round(value.size / 1024)} KB
          </div>
        </div>
        <div className={styles['control-container']}>
          <UploadContainer
            accept={accept}
            disabled={disabled}
            createPath={createPath}
            renderButton={renderButtonWithValue}
            transform={transform}
            onError={handleError}
            onUpload={handleUpload}
          />
          <Button
            height={Button.HEIGHT.SMALL}
            kind={Button.KIND.SECONDARY}
            text="Delete"
            userClassName={styles['button-remove']}
            userTextClassName={classNames(styles['button-text'], styles['button-remove-text'])}
            onClick={clear}
          />
        </div>
      </div>
    </div>
  ) : (
    <UploadContainer
      accept={accept}
      disabled={disabled}
      userClassName={uploadContainerClassNames}
      createPath={createPath}
      renderButton={renderButtonNoValue}
      transform={transform}
      onError={handleError}
      onUpload={handleUpload}
    />
  );

  return (
    <div className={styles['upload-form-field']}>
      {
        label && (
          <div className={labelClassNames}>
            {label}
          </div>
        )
      }
      {content}
      {
        Boolean(description) && (
          <div className={styles.description}>
            {description}
          </div>
        )
      }
      {
        hasError && (
          <div className={styles.error}>{error}</div>
        )
      }
    </div>
  );
};

UploadCompactFormField.propTypes = {
  accept: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }),
    PropTypes.oneOf([null])
  ]),
  createPath: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  transform: PropTypes.func,
  onChange: PropTypes.func
};

UploadCompactFormField.defaultProps = {
  accept: 'image/png, image/jpeg',
  description: null,
  disabled: false,
  error: null,
  label: null,
  touched: false,
  transform: value => value,
  value: null,
  onChange: null
};

export default UploadCompactFormField;
