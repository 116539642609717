const SERVER_SIDE_ERROR = 'Problems on the server side. Please, repeat the operation later.';
const UNEXPECTED_ERROR =
  'An unexpected error has occurred. Please, repeat the operation later, or contact the developers.';

function _getFiledPath(response) {
  const {extraData} = response.data.originalError;

  if (extraData && extraData.path) {
    return extraData.path.replace(/^_root\./, '');
  }

  return 'unknown';
}

function _getBadRequestError(response) {
  if (!response.data || !response.data.originalError) {
    return {_error: 'Please, check that all fields are valid'};
  }

  switch (response.data.originalError.code) {
  case 'MISSING_FIELD':
    return {_error: `A field is missing (${_getFiledPath(response)})`};
  case 'EXTRA_FIELDS':
    return {_error: `Extra field provided (${_getFiledPath(response)})`};
  case 'TYPE_MISMATCH':
    return {_error: `A field has wrong type (${_getFiledPath(response)})`};
  default:
    return {_error: 'Some fields are invalid or missing.'};
  }
}

export function getLoginByEmailErrors(err) {
  console.warn(err);

  if (err.code === 'auth/wrong-password') {
    return {_error: 'Account does not exist or password is invalid'};
  }

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  if (response.status === 404) {
    return {email: 'Account does not exist'};
  }

  if (response.status === 400) {
    switch (response.data.reason) {
    case 'user_not_active':
      return {email: 'Account is not activated'};
    case 'invalid_password':
      return {password: 'Invalid password'};
    default:
      return _getBadRequestError(response);
    }
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getForgotPasswordErrors(err) {
  console.warn(err);

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  if (response.status === 404) {
    return {email: 'Account does not exist'};
  }

  if (response.status === 400) {
    switch (response.data.reason) {
    case 'user_not_active':
      return {email: 'Account is not activated'};
    case 'invalid_password':
      return {password: 'Invalid password'};
    default:
      return _getBadRequestError(response);
    }
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getCreateTemplateErrors(err) {
  console.warn(err);

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  if (response.status === 404) {
    return {email: 'Account does not exist'};
  }

  if (response.status === 400) {
    switch (response.data.reason) {
    case 'user_not_active':
      return {email: 'Account is not activated'};
    case 'invalid_password':
      return {password: 'Invalid password'};
    default:
      return _getBadRequestError(response);
    }
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getEditTemplateErrors(err) {
  console.warn(err);

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  if (response.status === 404) {
    return {email: 'Account does not exist'};
  }

  if (response.status === 400) {
    switch (response.data.reason) {
    case 'user_not_active':
      return {email: 'Account is not activated'};
    case 'invalid_password':
      return {password: 'Invalid password'};
    default:
      return _getBadRequestError(response);
    }
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getCreateEventErrors(err) {
  console.warn(err);

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  if (response.status === 404) {
    return {email: 'Account does not exist'};
  }

  if (response.status === 400) {
    switch (response.data.reason) {
    case 'user_not_active':
      return {email: 'Account is not activated'};
    case 'invalid_password':
      return {password: 'Invalid password'};
    default:
      return _getBadRequestError(response);
    }
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getCreateCategoryErrors(err) {
  console.warn(err);

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getCreateAuthorErrors(err) {
  console.warn(err);

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getCreateContentErrors(err) {
  console.warn(err);

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getCreateCompanyErrors(err) {
  console.warn(err);

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getCreateBuildingErrors(err) {
  console.warn(err);

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getCreateTenantErrors(err) {
  console.warn(err);

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getCreateInstructorErrors(err) {
  console.warn(err);

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  return {_error: SERVER_SIDE_ERROR};
}
