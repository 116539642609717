// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._30mzbzJ-{display:inline-block;width:100%}._3xogpnsm{margin-bottom:8px;color:#6e6f76;font-size:13px;font-weight:500;line-height:1.54}._3xogpnsm._2zUddoWa{color:rgba(110,111,118,.4)}._2Nf3Nr_r{border-radius:8px}._3KWrmvnb{margin-top:8px;color:#f03d1c;font-size:13px;font-weight:500;line-height:1.54}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,oBAAqB,CACrB,UACF,CACA,WACE,iBAAkB,CAClB,aAAc,CACd,cAAe,CACf,eAAgB,CAChB,gBACF,CACA,qBACE,0BACF,CACA,WACE,iBACF,CACA,WACE,cAAe,CACf,aAAc,CACd,cAAe,CACf,eAAgB,CAChB,gBACF","file":"styles.less","sourcesContent":[".textarea-form-field {\n  display: inline-block;\n  width: 100%;\n}\n.label {\n  margin-bottom: 8px;\n  color: #6e6f76;\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 1.54;\n}\n.label.label-disabled {\n  color: rgba(110, 111, 118, 0.4);\n}\n.content {\n  border-radius: 8px;\n}\n.error {\n  margin-top: 8px;\n  color: #f03d1c;\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 1.54;\n}\n"]}]);
// Exports
exports.locals = {
	"textarea-form-field": "_30mzbzJ-",
	"label": "_3xogpnsm",
	"label-disabled": "_2zUddoWa",
	"content": "_2Nf3Nr_r",
	"error": "_3KWrmvnb"
};
module.exports = exports;
