import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import DocumentTitle from 'react-document-title';
import {Link} from 'react-router-dom';

import Auth from '../../../redux/ducks/auth';

import Loader from '../loader';
import Menu from '../menu';

import styles from './styles.less';

const NavbarLink = ({path, text, getIsActive}) => {
  const navbarItemClassNames = classNames({
    [styles['navbar-link']]: true,
    [styles['navbar-link-active']]: getIsActive(path)
  });

  return (
    <Link className={navbarItemClassNames} to={path}>{text}</Link>
  );
};

NavbarLink.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  getIsActive: PropTypes.func
};

NavbarLink.defaultProps = {
  getIsActive: path => path === window.location.pathname
};

const PageContent = ({children, isLoading}) => {
  const dispatch = useDispatch();

  const {name, photoUrl} = useSelector(state => state.user.item);

  const menuCallbacksRef = useRef(null);

  const contentWrapperClassNames = classNames({
    [styles['content-wrapper']]: true,
    [styles.loading]: isLoading
  });

  const items = [{
    path: '/schedules',
    text: 'Schedules'
  }, {
    path: '/templates',
    text: 'Templates'
  }, {
    path: '/content',
    text: 'Content',
    getIsActive: () => ['/authors', '/categories', '/content'].includes(window.location.pathname)
  }, {
    path: '/tenants',
    text: 'Tenants',
    getIsActive: () => ['/buildings', '/companies', '/tenants'].includes(window.location.pathname)
  }, {
    path: '/instructors',
    text: 'Users',
    getIsActive: () => ['/instructors'].includes(window.location.pathname)
  }, {
    path: '/notifications',
    text: 'Notifications',
    getIsActive: () => ['/notifications'].includes(window.location.pathname)
  }];

  const menuItems = [{
    text: name,
    textColor: '#111214',
    textWeight: 500
  }, {
    text: 'Log Out',
    textColor: '#6e6f76',
    onClick() {
      menuCallbacksRef.current.hide();

      dispatch(Auth.actions.logOut());
    }
  }];

  const handlePhotoClick = () => {
    menuCallbacksRef.current.toggle();
  };

  return (
    <div className={styles.page}>
      <div className={styles['content-container']}>
        <div className={styles.navbar}>
          <div className={styles['navbar-left']}>
            <div className={styles['navbar-item']}>
              <div className={styles['logo-wrapper']}>
                <div className={styles.logo}/>
              </div>
            </div>
            {
              items.map(({path, text, getIsActive}) => (
                <div key={path} className={styles['navbar-item']}>
                  <NavbarLink path={path} text={text} getIsActive={getIsActive}/>
                </div>
              ))
            }
          </div>
          <div className={styles['navbar-item']}>
            <Menu compact callbacksRef={menuCallbacksRef} items={menuItems} position={Menu.MenuPosition.BottomRight}>
              {
                photoUrl ? (
                  <img className={styles.photo} src={photoUrl} onClick={handlePhotoClick}/>
                ) : (
                  <div className={styles['photo-placeholder']} onClick={handlePhotoClick}/>
                )
              }
            </Menu>
          </div>
        </div>
        <main className={contentWrapperClassNames}>
          {
            isLoading ? (
              <Loader/>
            ) : (
              <div className={styles['inner-wrapper']}>
                {children}
              </div>
            )
          }
        </main>
      </div>
    </div>
  );
};

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool
};

PageContent.defaultProps = {
  isLoading: false
};

const Page = ({title, children, isLoading}) => {
  const page = (
    <PageContent isLoading={isLoading}>
      {children}
    </PageContent>
  );

  if (!title) {
    return page;
  }

  return (
    <DocumentTitle title={title}>
      {page}
    </DocumentTitle>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string
};

Page.defaultProps = {
  isLoading: false,
  title: null
};

export default Page;
