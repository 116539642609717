import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

const RadioFormField = props => {
  const {
    description,
    disabled,
    error,
    label,
    options,
    touched,
    value,
    setTouched,
    setValue,
    onChange
  } = props;

  const hasError = Boolean(touched && error);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles['label-disabled']]: disabled
  });

  const selectOption = ov => () => {
    if (onChange) {
      onChange(ov, setValue);
    } else {
      setValue(ov);
    }

    setTouched();
  };

  return (
    <div className={styles['radio-form-field']}>
      {
        label && (
          <div className={labelClassNames}>
            {label}
          </div>
        )
      }
      <div>
        {
          options.map(option => {
            const optionClassNames = classNames({
              [styles.option]: true,
              [styles['option-disabled']]: disabled
            });

            const circleClassNames = classNames({
              [styles['option-circle']]: true,
              [styles['option-circle-active']]: option.value === value
            });

            return (
              <div
                key={option.value}
                className={optionClassNames}
                onClick={disabled ? null : selectOption(option.value)}
              >
                <div className={circleClassNames}/>
                <div className={styles['option-label']}>{option.label || option.value}</div>
              </div>
            );
          })
        }
      </div>
      {
        Boolean(description) && (
          <div className={styles.description}>
            {description}
          </div>
        )
      }
      {
        hasError && (
          <div className={styles.error}>{error}</div>
        )
      }
    </div>
  );
};

const OPTION_SHAPE = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string
});

RadioFormField.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(OPTION_SHAPE).isRequired,
  touched: PropTypes.bool,
  value: PropTypes.string,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

RadioFormField.defaultProps = {
  description: null,
  disabled: false,
  error: null,
  label: null,
  touched: false,
  value: '',
  onChange: null
};

RadioFormField.OPTION_SHAPE = OPTION_SHAPE;

export default RadioFormField;
