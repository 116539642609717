import {Provider} from '@ololoepepe/redux-api';

const METHOD_MAP = new Map([
  ['create', 'POST'],
  ['fetch', 'GET'],
  ['remove', 'DELETE'],
  ['update', 'PATCH']
]);

function _getDuckOptions(method, duck) {
  const duckOptions = duck.providerOptions || {};

  return {...duckOptions.default, ...duckOptions[method]};
}

function _getValue(value, payload) {
  if (typeof value === 'function') {
    return value(payload);
  }

  return value;
}

export default class RestApiProvider extends Provider {
  constructor({canRetry = false} = {}) {
    super();

    this._canRetry = canRetry;
  }

  async create({action, authorizationData, duck, state}) {
    return this._request({
      action,
      authorizationData,
      duck,
      state,
      method: 'create'
    }, this._canRetry);
  }

  createHeaders() {
    throw new Error('Method "createHeaders" is not implemented');
  }

  async fetch({action, authorizationData, duck, state}) {
    return this._request({
      action,
      authorizationData,
      duck,
      state,
      method: 'fetch'
    }, this._canRetry);
  }

  async remove({action, authorizationData, duck, state}) {
    return this._request({
      action,
      authorizationData,
      duck,
      state,
      method: 'remove'
    }, this._canRetry);
  }

  async request() {
    throw new Error('Method "request" is not implemented');
  }

  shouldUpdateSession() {
    return false;
  }

  async update({action, authorizationData, duck, state}) {
    return this._request({
      action,
      authorizationData,
      duck,
      state,
      method: 'update'
    }, this._canRetry);
  }

  async updateSession() {
    throw new Error('Method "updateSession" is not implemented');
  }

  async _request(params, canRetry) {
    const {action, authorizationData, duck, method, state} = params;

    const {payload} = action;

    const duckOptions = _getDuckOptions(method, duck);

    try {
      return await this.request({
        duckOptions,
        payload,
        data: payload.data,
        headers: this.createHeaders({action, authorizationData, duckOptions}),
        method: METHOD_MAP.get(method),
        timeout: payload.timeout || _getValue(duckOptions.timeout, action.payload),
        url: payload.url || _getValue(duckOptions.url, action.payload)
      });
    } catch (error) {
      if (!canRetry) {
        throw error;
      }

      if (!this.shouldUpdateSession({action, authorizationData, duckOptions, error})) {
        throw error;
      }

      const newAuthorizationData = await this.updateSession({state});

      if (!newAuthorizationData) {
        throw error;
      }

      return this._request({
        ...params,
        authorizationData: newAuthorizationData
      }, false);
    }
  }
}
