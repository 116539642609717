import RestApiProvider from './rest-api-provider';

export default class AxiosApiProvider extends RestApiProvider {
  constructor({axiosInstance, canRetry = false}) {
    super({canRetry});

    this._axios = axiosInstance;
  }

  authorizedSubscribe() {
    return false;
  }

  createHeaders() {
    return {};
  }

  async getAuthorizationData() {
    throw new Error('Method "getAuthorizationData" is not implemented');
  }

  getAxiosInstance() {
    return this._axios;
  }

  async request({data, headers, method, timeout, url}) {
    return this._axios.request({data, headers, method, timeout, url});
  }
}
