import {createDuck as reduxApiCreateDuck, Provider} from '@ololoepepe/redux-api';

import * as firebase from 'firebase/app';

let firebaseProvider = null;

function _createItem(data, id, mapData) {
  return {
    id,
    ...(mapData ? mapData(data) : data)
  };
}

function _subscribe({callbacks, duck, state}) {
  const subnamespace = duck.subnamespace.toString();

  const {collectionName, createQuery, mapData} = duck.providerOptions;

  const collection = firebase.firestore().collection(collectionName);

  const query = createQuery(collection, {state});

  if (!query) {
    return () => {};
  }

  const queries = Array.isArray(query) ? query : [query];

  const unsubscribeList = queries.map(q => {
    return q.onSnapshot(snapshot => {
      const list = [];

      snapshot.docChanges().forEach(({type, doc}) => {
        const item = _createItem(doc.data(), doc.id, mapData);

        switch (type) {
        case 'added':
          if (duck.single) {
            callbacks.set(subnamespace, item);
            callbacks.setFetched(subnamespace, true);
          } else {
            list.push(item);
          }

          break;
        case 'modified':
          callbacks.editSingle(subnamespace, item);

          break;
        case 'removed':
          callbacks.deleteSingle(subnamespace, doc.id);

          break;
        default:
          break;
        }
      });

      if (list.length > 0) {
        callbacks.addList(subnamespace, list);
        callbacks.setFetched(subnamespace, true);
      }
    });
  });

  return () => unsubscribeList.forEach(unsubscribe => unsubscribe());
}

class FirebaseProvider extends Provider {
  authorizedSubscribe() {
    return true;
  }

  async create({action, duck}) {
    const {data} = action.payload;

    const {collectionName} = duck.providerOptions;

    const collection = firebase.firestore().collection(collectionName);

    const ref = await collection.add(data);

    return {
      data: {
        item: {
          id: ref.id,
          ...data
        }
      }
    };
  }

  async getAuthorizationData() {
    const {currentUser} = firebase.auth();

    if (currentUser) {
      return currentUser.getIdToken(true);
    }

    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          user.getIdToken(true).then(token => {
            resolve(token);
          }).catch(err => {
            reject(err);
          });
        } else {
          reject(new Error('No current user'));
        }
      });
    });
  }

  async logIn({action}) {
    const {email, password} = action.payload.data;

    const result = await firebase.auth().signInWithEmailAndPassword(email, password);

    const collection = firebase.firestore().collection('users');

    const doc = await collection.doc(result.user.uid).get();

    if (!doc.data().roles.includes('root')) {
      throw new Error('Not enough rights');
    }
  }

  async logOut() {
    await firebase.auth().signOut();
  }

  async remove({action, duck}) {
    const {id} = action.payload;

    const {collectionName} = duck.providerOptions;

    const collection = firebase.firestore().collection(collectionName);

    await collection.doc(id).delete();
  }

  resubscribe({duck, state}) {
    if (!this._subscriptions) {
      return;
    }

    const subnamespace = duck.subnamespace.toString();

    const unsubscribe = this._subscriptions[subnamespace];

    if (!unsubscribe) {
      return;
    }

    unsubscribe();

    if (this.shouldClearOnUnsubscribe()) {
      this._callbacks.clear(subnamespace);
    }

    this._subscriptions[subnamespace] = _subscribe({
      duck,
      state,
      callbacks: this._callbacks
    });
  }

  async sendPasswordResetEmail({action}) {
    const {email} = action.payload.data;

    await firebase.auth().sendPasswordResetEmail(email);
  }

  shouldSubscribe() {
    return true;
  }

  subscribe({getDucks, callbacks, state}) {
    this._callbacks = callbacks;

    this._subscriptions = getDucks()
      .filter(({providerOptions}) => providerOptions.createQuery && providerOptions.collectionName)
      .reduce((acc, duck) => {
        acc[duck.subnamespace.toString()] = _subscribe({callbacks, duck, state});

        return acc;
      }, {});
  }

  unsubscribe() {
    if (this._subscriptions) {
      Object.values(this._subscriptions).forEach(unsubscribe => unsubscribe());
      this._subscriptions = null;
      this._callbacks = null;
    }
  }

  async update({action, duck}) {
    const {id, data} = action.payload;

    const {collectionName} = duck.providerOptions;

    const collection = firebase.firestore().collection(collectionName);

    await collection.doc(id).update(data);

    return {
      data: {
        item: {
          id,
          ...data
        }
      }
    };
  }
}

export function createDuck(name, providerOptions, options = {}) {
  const {
    customActionHandlers,
    customApiActionHandlers,
    customAuthorizedActions = [],
    reducerOptions,
    single
  } = options;

  return reduxApiCreateDuck(name, getInstance(), {
    customActionHandlers,
    customApiActionHandlers,
    providerOptions,
    reducerOptions,
    single,
    authorizedActions: customAuthorizedActions
  });
}

export function getInstance() {
  if (!firebaseProvider) {
    throw new Error('Firebase Provider is not initialized');
  }

  return firebaseProvider;
}

export function init() {
  firebaseProvider = new FirebaseProvider();
}
