import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import {findElement} from '../../../lib/helper';

import styles from './styles.less';

export const MenuPosition = {
  TopLeft: 'top_left',
  TopRight: 'top_right',
  BottomRight: 'bottom_right',
  BottomLeft: 'bottom_left'
};

export const MENU_SEPARATOR = 'separator';

const ITEM_TYPE = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.oneOf([MENU_SEPARATOR]),
  PropTypes.shape({
    active: PropTypes.bool,
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    textWeight: PropTypes.number,
    title: PropTypes.string,
    onClick: PropTypes.func
  })
]);

const Item = ({compact, item, setMenuVisible}) => {
  if (item === MENU_SEPARATOR) {
    return (
      <div className={styles['separator-wrapper']}>
        <div className={styles.separator}/>
      </div>
    );
  }

  if (typeof item === 'function') {
    return item({compact, setMenuVisible});
  }

  const {active, text, textColor, textWeight, title, onClick} = item;

  const itemClassNames = classNames({
    [styles.item]: true,
    [styles['item-compact']]: compact,
    [styles['item-clickable']]: onClick,
    [styles['item-active']]: active
  });

  const handleClick = () => {
    setMenuVisible(false);

    onClick();
  };

  const itemTextStyles = {};

  if (textColor) {
    itemTextStyles.color = textColor;
  }

  if (textWeight > 0) {
    itemTextStyles.fontWeight = textWeight;
  }

  return (
    <div className={itemClassNames} title={title} onClick={onClick ? handleClick : null}>
      <div className={styles['item-text']} style={itemTextStyles}>{text}</div>
    </div>
  );
};

Item.propTypes = {
  compact: PropTypes.bool.isRequired,
  item: ITEM_TYPE.isRequired,
  setMenuVisible: PropTypes.func.isRequired
};

const renderItem = ({compact, setMenuVisible}) => (item, index) => {
  const key = (item === MENU_SEPARATOR) ?
    `separator-${index}` :
    ((typeof item === 'function') ? `item-${index}` : `item-${index}-${item.text}`);

  return (
    <Item key={key} compact={compact} item={item} setMenuVisible={setMenuVisible}/>
  );
};

const Menu = props => {
  const {
    callbacksRef,
    children,
    compact,
    items,
    minMenuWidth,
    position,
    userClassName,
    userStyle,
    onVisibilityChange
  } = props;

  const [isMenuVisible, setMenuVisible] = useState(false);

  const setMenuVisibleProxy = useCallback(visible => {
    setMenuVisible(visible);

    if (onVisibilityChange && (visible !== isMenuVisible)) {
      onVisibilityChange(visible);
    }
  }, [isMenuVisible, onVisibilityChange]);

  const componentRef = useRef(null);

  useEffect(() => {
    const callbacks = {
      show: () => {
        setMenuVisibleProxy(true);
      },
      hide: () => {
        setMenuVisibleProxy(false);
      },
      toggle: () => {
        setMenuVisibleProxy(!isMenuVisible);
      }
    };

    if (typeof callbacksRef === 'function') {
      callbacksRef(callbacks);
    } else if (callbacksRef && (typeof callbacksRef === 'object')) {
      callbacksRef.current = callbacks;
    }
  }, [callbacksRef, isMenuVisible, setMenuVisibleProxy]);

  useEffect(() => {
    const handleClickOutside = e => {
      if (findElement(e.target, componentRef.current)) {
        return;
      }

      setMenuVisibleProxy(false);
    };

    window.document.addEventListener('click', handleClickOutside);

    return () => {
      window.document.removeEventListener('click', handleClickOutside);
    };
  }, [setMenuVisibleProxy]);

  const menuClassNames = classNames({
    [styles.menu]: true,
    [styles['menu-position-top-left']]: position === MenuPosition.TopLeft,
    [styles['menu-position-top-right']]: position === MenuPosition.TopRight,
    [styles['menu-position-bottom-right']]: position === MenuPosition.BottomRight,
    [styles['menu-position-bottom-left']]: position === MenuPosition.BottomLeft
  });

  const menuStyle = {minWidth: minMenuWidth};

  if (!isMenuVisible) {
    menuStyle.display = 'none';
  }

  return (
    <div ref={componentRef} className={classNames(styles.component, userClassName)} style={userStyle}>
      {children}
      <div className={menuClassNames} style={menuStyle}>
        {
          items.map(renderItem({compact, setMenuVisible: setMenuVisibleProxy}))
        }
      </div>
    </div>
  );
};

Menu.propTypes = {
  callbacksRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.node.isRequired,
  compact: PropTypes.bool,
  items: PropTypes.arrayOf(ITEM_TYPE).isRequired,
  minMenuWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  position: PropTypes.oneOf(Object.values(MenuPosition)),
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onVisibilityChange: PropTypes.func
};

Menu.defaultProps = {
  callbacksRef: null,
  compact: false,
  minMenuWidth: 140,
  position: MenuPosition.BottomLeft,
  userClassName: null,
  userStyle: null,
  onVisibilityChange: null
};

Menu.MenuPosition = MenuPosition;

export default Menu;
