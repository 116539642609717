// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PXWaMRDT,._3iug92-x{height:100%;-js-display:flex;display:flex}.PXWaMRDT{align-items:center;font-size:16px;font-weight:500;color:#111214}._2vmGghm_{min-height:100%;width:100%;padding:52px;box-sizing:border-box;-js-display:flex;display:flex;flex-direction:column;align-items:center}._3AKBXwKl{width:664px}._3QxVdjs2{margin-top:20px;width:664px}._1CKsIhfb{margin-bottom:8px;font-size:13px;font-weight:500;line-height:20px;color:#6e6f76}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAIA,qBAHE,WAAY,CACZ,gBAAa,CAAb,YASF,CAPA,UAGE,kBAAmB,CACnB,cAAe,CACf,eAAgB,CAChB,aACF,CACA,WACE,eAAgB,CAChB,UAAW,CACX,YAAa,CACb,qBAAsB,CACtB,gBAAa,CAAb,YAAa,CACb,qBAAsB,CACtB,kBACF,CACA,WACE,WACF,CACA,WACE,eAAgB,CAChB,WACF,CACA,WACE,iBAAkB,CAClB,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,aACF","file":"styles.less","sourcesContent":[".content {\n  height: 100%;\n  display: flex;\n}\n.cell-name {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  font-size: 16px;\n  font-weight: 500;\n  color: #111214;\n}\n.dialog-content {\n  min-height: 100%;\n  width: 100%;\n  padding: 52px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.dialog-form-wrapper {\n  width: 664px;\n}\n.dialog-content-wrapper {\n  margin-top: 20px;\n  width: 664px;\n}\n.dialog-content-title {\n  margin-bottom: 8px;\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 20px;\n  color: #6e6f76;\n}\n"]}]);
// Exports
exports.locals = {
	"cell-name": "PXWaMRDT",
	"content": "_3iug92-x",
	"dialog-content": "_2vmGghm_",
	"dialog-form-wrapper": "_3AKBXwKl",
	"dialog-content-wrapper": "_3QxVdjs2",
	"dialog-content-title": "_1CKsIhfb"
};
module.exports = exports;
