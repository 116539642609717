// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1K_oy1q7{color:#61b100}._1K_oy1q7:not(.KxFaFn2g){cursor:pointer}._1K_oy1q7.KxFaFn2g{cursor:not-allowed}._1K_oy1q7:not(:hover){text-decoration:none}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,aACF,CACA,0BACE,cACF,CACA,oBACE,kBACF,CACA,uBACE,oBACF","file":"styles.less","sourcesContent":[".clickable-text {\n  color: #61b100;\n}\n.clickable-text:not(.disabled) {\n  cursor: pointer;\n}\n.clickable-text.disabled {\n  cursor: not-allowed;\n}\n.clickable-text:not(:hover) {\n  text-decoration: none;\n}\n"]}]);
// Exports
exports.locals = {
	"clickable-text": "_1K_oy1q7",
	"disabled": "KxFaFn2g"
};
module.exports = exports;
