import {apiInitialized} from '@ololoepepe/redux-api';

import localForage from 'localforage';
import {applyMiddleware, createStore as reduxCreateStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistReducer, persistStore} from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import {init as initFirebaseProvider} from '../lib/firebase-provider';
import {init as initApiProvider} from '../lib/api-provider';

let store = null;

initApiProvider();
initFirebaseProvider();

export function createStore() {
  const sagaMiddleware = createSagaMiddleware();
  const persistedReducer = persistReducer({
    key: 'redux-store',
    storage: localForage,
    whitelist: ['session', 'user']
  }, require('./reducers').default);

  store = reduxCreateStore(persistedReducer, composeWithDevTools(
    applyMiddleware(sagaMiddleware)
  ));
  const persistor = persistStore(store, null, () => {
    store.dispatch(apiInitialized());
  });

  sagaMiddleware.run(require('./sagas').default);

  return {store, persistor};
}
