import {createReducer} from '@ololoepepe/redux-tools';

import {createAction} from 'redux-actions';
import {defineAction} from 'redux-define';

const subnamespace = defineAction('session');

const FETCH_COMMON_DATA = defineAction('FETCH_COMMON_DATA', subnamespace);
const LOG_IN = defineAction('LOG_IN', subnamespace);
const LOG_OUT = defineAction('LOG_OUT', subnamespace);

const fetchCommonData = createAction(FETCH_COMMON_DATA.ACTION);
const logIn = createAction(LOG_IN.ACTION);
const logOut = createAction(LOG_OUT.ACTION);

const INITIAL_STATE = {
  isLoggedIn: false
};

function handleLogIn(state) {
  return {
    ...state,
    isLoggedIn: true
  };
}

const DUCK = {
  subnamespace,
  actionTypes: {
    FETCH_COMMON_DATA,
    LOG_IN,
    LOG_OUT
  },
  actions: {
    fetchCommonData,
    logIn,
    logOut
  },
  reducer: createReducer(INITIAL_STATE, {
    [LOG_IN.ACTION]: handleLogIn,
    [LOG_OUT.ACTION]: () => ({...INITIAL_STATE})
  })
};

export default DUCK;
