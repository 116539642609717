import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {defaultFormFieldFilter} from '../../../lib/form/helper';

import {callCloudFunction} from '../../../lib/helper';

import Buildings from '../../../redux/ducks/buildings';
import Companies from '../../../redux/ducks/companies';
import Tenants from '../../../redux/ducks/tenants';

import Button from '../../common/button';
import FormField from '../../common/form/form-field';
import InputFormField from '../../common/form/form-field/input';
import SelectFormField from '../../common/form/form-field/select';
import TextareaFormField from '../../common/form/form-field/textarea';
import Page from '../../common/page';

import styles from './styles.less';

const NOT_SELECTED = '-';

const ALL_COMPANIES = 'all';

const NotificationsPage = () => {
  const [isSending, setIsSending] = useState(false);

  const [title, setTitle] = useState('');

  const [body, setBody] = useState('');

  const dispatch = useDispatch();

  const companies = useSelector(state => state.companies.items);
  const selectedCompanyId = useSelector(state => state.companies.companyId || NOT_SELECTED);

  const buildings = useSelector(state => state.buildings.items);
  const selectedBuildingId = useSelector(state => state.buildings.buildingId || NOT_SELECTED);

  const tenants = useSelector(state => state.tenants.items);
  const selectedTenantId = useSelector(state => state.tenants.tenantId || NOT_SELECTED);

  const selectCompanyId = companyId => {
    dispatch(Companies.actions.setCompanyId(companyId));
  };

  const selectBuildingId = buildingId => {
    dispatch(Buildings.actions.setBuildingId(buildingId));
  };

  const selectTenantId = tenantId => {
    dispatch(Tenants.actions.setTenantId(tenantId));
  };

  const companyIdOptions = [
    {
      label: 'Not selected',
      value: NOT_SELECTED
    },
    {
      label: 'All companies',
      value: ALL_COMPANIES
    },
    ...companies.map(({id, name}) => ({
      label: name,
      value: id
    }))
  ];

  const buildingIdOptions = [
    {
      label: 'Not selected',
      value: NOT_SELECTED
    },
    ...buildings.map(({id, name}) => ({
      label: name,
      value: id
    }))
  ];

  const tenantIdOptions = [
    {
      label: 'Not selected',
      value: NOT_SELECTED
    },
    ...tenants.map(({id, name}) => ({
      label: name,
      value: id
    }))
  ];

  const isSendingButtonDisabled = !title || !body || selectedCompanyId === NOT_SELECTED || isSending;

  const _generateData = () => {
    if (selectedCompanyId === NOT_SELECTED || !title.trim() || !body.trim()) {
      return;
    }

    if (selectedTenantId !== NOT_SELECTED) {
      return {
        title,
        body,
        scope: 'tenant',
        entityId: selectedTenantId
      };
    }

    if (selectedBuildingId !== NOT_SELECTED) {
      return {
        title,
        body,
        scope: 'building',
        entityId: selectedBuildingId
      };
    }

    if (selectedCompanyId === ALL_COMPANIES) {
      return {
        title,
        body,
        scope: 'all'
      };
    }

    if (selectedCompanyId !== NOT_SELECTED || selectedCompanyId !== ALL_COMPANIES) {
      return {
        title,
        body,
        scope: 'company',
        entityId: selectedCompanyId
      };
    }
  };

  const handleSendNotificationsClick = async () => {
    const data = _generateData();

    if (!data) {
      return;
    }

    console.log(data);

    setIsSending(true);

    let result;

    try {
      result = await callCloudFunction('sendNotification', {
        ...data,
        isDevelopment: process.env.NODE_ENV === 'development'
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsSending(false);
    }

    if (isNaN(result)) {
      // eslint-disable-next-line no-alert
      alert('Error while sending');

      return;
    }

    // eslint-disable-next-line no-alert
    alert(`Sent notifications to ${result} users`);
  };

  return (
    <Page title="Push notifications">
      <div className={styles['event-list-container']}>
        <div className={styles['event-list-container-title']}>Push Notifications</div>
        <div className={styles['event-list-filter-container']}>
          <div className={styles['event-list-filter-item']}>
            <SelectFormField
              disabled={isSending}
              filter={defaultFormFieldFilter}
              label="Company"
              name="companyId"
              options={companyIdOptions}
              placeholder="Please select..."
              value={selectedCompanyId}
              setTouched={() => {}}
              setValue={selectCompanyId}
            />
          </div>
          <div className={styles['event-list-filter-item']}>
            <SelectFormField
              disabled={selectedCompanyId === NOT_SELECTED || isSending}
              filter={defaultFormFieldFilter}
              label="Building"
              name="buildingId"
              options={buildingIdOptions}
              placeholder="Please select..."
              value={selectedBuildingId}
              setTouched={() => {}}
              setValue={selectBuildingId}
            />
          </div>
          <div className={styles['event-list-filter-item']}>
            <SelectFormField
              disabled={selectedBuildingId === NOT_SELECTED || isSending}
              filter={defaultFormFieldFilter}
              label="Tenant"
              name="tenantId"
              options={tenantIdOptions}
              placeholder="Please select..."
              value={selectedTenantId}
              setTouched={() => {}}
              setValue={selectTenantId}
            />
          </div>
        </div>
        <div className={styles['field-list-container']}>
          <div className={styles['field-list-item']}>
            <InputFormField
              name="title"
              type={FormField.TYPE.TEXT}
              value={title}
              setTouched={() => {}}
              setValue={setTitle}
              placeholder="Enter notification title..."
            />
          </div>
          <div className={styles['field-list-item']}>
            <TextareaFormField
              name="body"
              value={body}
              setTouched={() => {}}
              setValue={setBody}
              rowCount={6}
              placeholder="Enter notification text..."
            />
          </div>
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          disabled={isSendingButtonDisabled}
          text="Send Notifications"
          onClick={handleSendNotificationsClick}
        />
      </div>
    </Page>
  );
};

export default NotificationsPage;
