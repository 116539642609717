// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2LaTO5yU{height:100%;width:176px;padding:16px 24px;-js-display:flex;display:flex;box-sizing:border-box;flex-direction:column;box-shadow:2px 0 0 0 rgba(0,0,0,.04)}._2FE8geMJ{line-height:40px;font-size:16px;font-weight:400;color:#43434a;text-decoration:none}._2FE8geMJ._29l5rtMI{font-weight:500;color:#61b100}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,WAAY,CACZ,WAAY,CACZ,iBAAkB,CAClB,gBAAa,CAAb,YAAa,CACb,qBAAsB,CACtB,qBAAsB,CACtB,oCACF,CACA,WACE,gBAAiB,CACjB,cAAe,CACf,eAAgB,CAChB,aAAc,CACd,oBACF,CACA,qBACE,eAAgB,CAChB,aACF","file":"styles.less","sourcesContent":[".component {\n  height: 100%;\n  width: 176px;\n  padding: 16px 24px;\n  display: flex;\n  box-sizing: border-box;\n  flex-direction: column;\n  box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.04);\n}\n.navigation-link {\n  line-height: 40px;\n  font-size: 16px;\n  font-weight: 400;\n  color: #43434a;\n  text-decoration: none;\n}\n.navigation-link.navigation-link-active {\n  font-weight: 500;\n  color: #61b100;\n}\n"]}]);
// Exports
exports.locals = {
	"component": "_2LaTO5yU",
	"navigation-link": "_2FE8geMJ",
	"navigation-link-active": "_29l5rtMI"
};
module.exports = exports;
