import React from 'react';

import DocumentTitle from 'react-document-title';
import {Redirect, Router, Route, Switch} from 'react-router-dom';

import history from '../../lib/history';

import AuthorsPage from '../pages/authors';
import BuildingsPage from '../pages/buildings';
import CategoriesPage from '../pages/categories';
import CompaniesPage from '../pages/companies';
import ContentPage from '../pages/content';
import ForgotPasswordPage from '../pages/forgot-password';
import InstructorsPage from '../pages/instructors';
import LoginPage from '../pages/login';
import NotFoundPage from '../pages/not-found';
import NotificationsPage from '../pages/notifications';
import SchedulesPage from '../pages/schedules';
import TemplatesPage from '../pages/templates';
import TenantsPage from '../pages/tenants';

class App extends React.Component {
  render() {
    return (
      <DocumentTitle title="CMS | GoodMoodDudes">
        <Router history={history}>
          <Switch>
            <Route exact path="/" render={() => (<Redirect to="/schedules"/>)}/>
            <Route exact path="/authors" component={AuthorsPage}/>
            <Route exact path="/buildings" component={BuildingsPage}/>
            <Route exact path="/categories" component={CategoriesPage}/>
            <Route exact path="/companies" component={CompaniesPage}/>
            <Route exact path="/content" component={ContentPage}/>
            <Route exact path="/forgot-password" component={ForgotPasswordPage}/>
            <Route exact path="/instructors" component={InstructorsPage}/>
            <Route exact path="/login" component={LoginPage}/>
            <Route exact path="/schedules" component={SchedulesPage}/>
            <Route exact path="/templates" component={TemplatesPage}/>
            <Route exact path="/tenants" component={TenantsPage}/>
            <Route exact path="/tenants" component={TenantsPage}/>
            <Route exact path="/notifications" component={NotificationsPage}/>
            <Route render={NotFoundPage}/>
          </Switch>
        </Router>
      </DocumentTitle>
    );
  }
}

export default App;
