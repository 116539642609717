import firebase from 'firebase/app';
import React, {useRef, useState} from 'react';

import {getCreateCategoryErrors} from '../../../lib/form/error-getters';
import {CREATE_CATEGORY_FORM_FIELDS} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';

import {capitalizeFirstLetter, exposeFields, formatDate} from '../../../lib/helper';

import useFirebaseEntity from '../../../hooks/use-firebase-entity';
import useOrderedFirebaseEntity from '../../../hooks/use-ordered-firebase-entity';

import ContentContainer from '../../common/content-container';
import ContentSidebar from '../../common/content-sidebar';
import ContentTable from '../../common/content-table';
import Dialog from '../../common/dialog';
import Form from '../../common/form';
import Page from '../../common/page';

import styles from './styles.less';

const CategoriesPage = () => {
  const [
    items,
    {hasFetched, isCreating, isRemoving, isUpdating},
    {create, remove, reorder, update}
  ] = useOrderedFirebaseEntity('categories', [], {
    mapData: item => ({
      ...item,
      createdAt: item.createdAt.toDate()
    })
  });

  const [[ordering]] = useFirebaseEntity('ordering', [[firebase.firestore.FieldPath.documentId(), 'content']]);

  const formSubmitCallbackRef = useRef(null);

  const [search, setSearch] = useState('');
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const contentQuery = selectedRow ?
    [['categoryIds', selectedRow.id, 'array-contains']] :
    [[firebase.firestore.FieldPath.documentId(), '-']];

  const [
    contentItems,
    {
      hasFetched: hasFetchedContent,
      isUpdating: isUpdatingContent
    },
    {reorder: reorderContent}
  ] = useOrderedFirebaseEntity('content', contentQuery, {
    activeOrderingKey: selectedRow && selectedRow.id,
    getOrderingKeys: ({categoryIds}) => categoryIds
  });

  const columns = [{
    style: {
      textAlign: 'left'
    },
    title: 'Name'
  }, {
    style: {
      width: '150px'
    },
    title: 'Date Created'
  }, {
    style: {
      width: '120px'
    },
    title: '# of Content Posts'
  }];

  const rows = items.map(({id, ...rest}) => ({
    key: id,
    data: {
      id,
      ...rest
    }
  }));

  const filterRow = data => {
    return data.name.toLowerCase().includes(search.toLowerCase());
  };

  const renderRowCell = (data, index, dragHandleProps) => {
    switch (index) {
    case 0:
      return (
        <div className={styles['cell-name']}>
          {
            dragHandleProps && (
              <div className={styles['drag-handle']}{...dragHandleProps}/>
            )
          }
          <div>{data.name}</div>
        </div>
      );
    case 1:
      return formatDate(data.createdAt);
    case 2:
      return (ordering && ordering[data.id] && ordering[data.id].length) || 0;
    default:
      return null;
    }
  };

  const handleReorder = async ({key}, targetIndex) => {
    await reorder(key, targetIndex);
  };

  const handleAddButtonClick = () => {
    setSelectedRow(null);
    setDialogVisible(true);
  };

  const handleRowClick = ({data}) => {
    setSelectedRow(data);
    setDialogVisible(true);
  };

  const handleRemove = async () => {
    const success = await remove(selectedRow.id);

    if (!success) {
      throw new Error('Please, try again');
    }

    setDialogVisible(false);
  };

  const contentColumns = [{
    style: {
      textAlign: 'left'
    },
    title: 'Name'
  }, {
    style: {
      textAlign: 'left',
      width: '120px'
    },
    title: 'Author'
  }, {
    style: {
      textAlign: 'left',
      width: '80px'
    },
    title: 'Type'
  }];

  const contentRows = contentItems.map(({id, ...rest}) => ({
    key: id,
    data: {
      id,
      ...rest
    }
  }));

  const renderContentRowCell = (data, index, dragHandleProps) => {
    switch (index) {
    case 0:
      return (
        <div className={styles['content-cell-name']}>
          <div className={styles['drag-handle']} {...dragHandleProps}/>
          <div className={styles['content-image-wrapper']}>
            <img className={styles['content-image']} src={data.image.url}/>
          </div>
          <div className={styles['content-name']}>{data.name}</div>
        </div>
      );
    case 1:
      return (
        <div className={styles['content-cell-author']}>
          <div className={styles['content-author-photo-wrapper']}>
            <img className={styles['content-author-photo']} src={data.author.photoUrl}/>
          </div>
          <div className={styles['content-author-name']}>{data.author.name}</div>
        </div>
      );
    case 2:
      return capitalizeFirstLetter(data.type);
    default:
      return null;
    }
  };

  const handleContentReorder = async ({key}, targetIndex) => {
    await reorderContent(key, targetIndex);
  };

  const handleFormSubmit = async data => {
    if (selectedRow) {
      const success = await update(selectedRow.id, data);

      if (!success) {
        throw new Error('Please, try again');
      }
    } else {
      const id = await create({
        ...data,
        createdAt: new Date()
      });

      if (!id) {
        throw new Error('Please, try again');
      }
    }

    setDialogVisible(false);
  };

  return (
    <Page title="Categories">
      <div className={styles.content}>
        <ContentSidebar/>
        <ContentContainer
          addButtonText="New Category"
          search={search}
          title="Manage Categories"
          onAddButtonClick={handleAddButtonClick}
          onSearchChange={setSearch}
        >
          <ContentTable
            columns={columns}
            hasFetched={hasFetched}
            rows={rows}
            filterRow={filterRow}
            renderRowCell={renderRowCell}
            onRowClick={handleRowClick}
            onReorder={handleReorder}
          />
        </ContentContainer>
        {
          isDialogVisible && (
            <Dialog
              buttonAcceptDisabled={isCreating || isRemoving || isUpdating || isUpdatingContent}
              buttonAcceptText={selectedRow ? 'Save' : 'Create Category'}
              buttonRemoveText="Delete Category"
              title={selectedRow ? 'Edit Category' : 'Add a New Category'}
              onAccept={() => formSubmitCallbackRef.current()}
              onReject={() => setDialogVisible(false)}
              onRemove={selectedRow && handleRemove}
            >
              <div className={styles['dialog-content']}>
                <div className={styles['dialog-form-wrapper']}>
                  <Form
                    defaultValues={selectedRow ? exposeFields(selectedRow, 'name') : {}}
                    fields={CREATE_CATEGORY_FORM_FIELDS}
                    submitCallbackRef={formSubmitCallbackRef}
                    onSubmit={handleFormSubmit}
                    onSubmitFailure={handleSubmitFailure(getCreateCategoryErrors)}
                  />
                </div>
                {
                  selectedRow && (
                    <div className={styles['dialog-content-wrapper']}>
                      <div className={styles['dialog-content-title']}>Content Order</div>
                      <ContentTable
                        columns={contentColumns}
                        disabled={isUpdatingContent}
                        hasFetched={hasFetchedContent}
                        rows={contentRows}
                        renderRowCell={renderContentRowCell}
                        onReorder={handleContentReorder}
                      />
                    </div>
                  )
                }
              </div>
            </Dialog>
          )
        }
      </div>
    </Page>
  );
};

export default CategoriesPage;
