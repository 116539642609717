import {connect} from 'react-redux';

import createComponent from './component.jsx';

function mapStateToProps(state, ownProps) {
  const {isLoggedIn: _isLoggedIn} = state.session;

  return {
    _isLoggedIn,
    _redirect: '/schedules',
    ...ownProps
  };
}

export default WrappedComponent => {
  return connect(
    mapStateToProps
  )(createComponent(WrappedComponent));
};
