import {useEffect, useState} from 'react';

import {getWindowSize} from '../lib/helper';

const HANDLERS = new Set();

window.addEventListener('resize', () => {
  const size = getWindowSize();

  HANDLERS.forEach(setSize => {
    setSize(size);
  });
});

export default () => {
  const [size, setSize] = useState(getWindowSize());

  useEffect(() => {
    HANDLERS.add(setSize);

    return () => {
      HANDLERS.delete(setSize);
    };
  }, []);

  return [size];
};
