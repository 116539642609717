import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import InputFormField from '../form/form-field/input';

import styles from './styles.less';

const ContentContainer = ({addButtonText, children, search, subheader, title, onAddButtonClick, onSearchChange}) => {
  return (
    <div className={styles.component}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles['search-container']}>
          <InputFormField
            withSearchIcon
            name="search"
            placeholder="Search..."
            type="text"
            userClassName={styles['search-field']}
            userInputClassName={styles['search-field-input']}
            value={search}
            setTouched={() => {}}
            setValue={onSearchChange}
          />
          <Button
            withPlus
            disabled={!onAddButtonClick}
            height={Button.HEIGHT.SMALL}
            text={addButtonText}
            onClick={onAddButtonClick}
          />
        </div>
      </div>
      {
        subheader && (
          <div className={styles.subheader}>{subheader}</div>
        )
      }
      <div className={styles.children}>
        {children}
      </div>
    </div>
  );
};

ContentContainer.propTypes = {
  addButtonText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  search: PropTypes.string.isRequired,
  subheader: PropTypes.node,
  title: PropTypes.string.isRequired,
  onAddButtonClick: PropTypes.func,
  onSearchChange: PropTypes.func.isRequired
};

ContentContainer.defaultProps = {
  subheader: null,
  onAddButtonClick: null
};

export default ContentContainer;
