import {resubscribe} from '@ololoepepe/redux-api';

import {put, takeLatest} from 'redux-saga/effects';

import Bookings from '../ducks/bookings';
import Buildings from '../ducks/buildings';
import Companies from '../ducks/companies';
import Events from '../ducks/events';
import Tenants from '../ducks/tenants';

const doResubscribe = duck => function * () {
  const ducks = Array.isArray(duck) ? duck : [duck];

  const duckNames = ducks.map(d => d.subnamespace.toString());

  yield put(resubscribe({duckNames}));
};

export default function * session() {
  yield takeLatest(Bookings.actionTypes.setSelectedDate.ACTION, doResubscribe(Bookings));
  yield takeLatest(Buildings.actionTypes.setBuildingId.ACTION, doResubscribe([Events, Tenants]));
  yield takeLatest(Companies.actionTypes.setCompanyId.ACTION, doResubscribe(Buildings));
  yield takeLatest(Tenants.actionTypes.setTenantId.ACTION, doResubscribe(Events));
}
