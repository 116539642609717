import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import {createWindow} from '../../../lib/helper';
import history from '../../../lib/history';

import styles from './styles.less';

class Button extends React.Component {
  static get KIND() {
    return {
      PRIMARY: 'primary',
      SECONDARY: 'secondary'
    };
  }

  static get HEIGHT() {
    return {
      NORMAL: 'normal',
      SMALL: 'small'
    };
  }

  static get WIDTH() {
    return {
      NORMAL: 'normal',
      FILL: 'fill'
    };
  }

  static get TYPE() {
    return {
      BUTTON: 'button',
      RESET: 'reset',
      SUBMIT: 'submit'
    };
  }

  render() {
    const {
      disabled,
      domRef,
      height,
      href,
      kind,
      target,
      text,
      type,
      userClassName,
      userStyle,
      userTextClassName,
      width,
      withPlus,
      onClick
    } = this.props;

    const buttonClassNames = classNames({
      [userClassName]: Boolean(userClassName),
      [styles.button]: true,
      [styles.primary]: kind === Button.KIND.PRIMARY,
      [styles.secondary]: kind === Button.KIND.SECONDARY,
      [styles['height-normal']]: height === Button.HEIGHT.NORMAL,
      [styles['height-small']]: height === Button.HEIGHT.SMALL,
      [styles['width-normal']]: width === Button.WIDTH.NORMAL,
      [styles['width-fill']]: width === Button.WIDTH.FILL,
      [styles.disabled]: disabled
    });

    const clickHandler = e => {
      e.stopPropagation();

      if (href) {
        if (target === '_blank') {
          createWindow(href, true);
        } else {
          history.push(href);
        }
      } else if (onClick) {
        onClick(e);
      }
    };

    /* eslint-disable react/button-has-type */
    return (
      <button
        ref={domRef}
        type={type}
        className={buttonClassNames}
        style={userStyle}
        onClick={disabled ? null : clickHandler}
      >
        <div className={classNames(styles['button-content'], userTextClassName)}>
          {
            withPlus && (
              <div className={styles.plus}/>
            )
          }
          {text}
        </div>
      </button>
    );
    /* eslint-enable react/button-has-type */
  }
}

Button.propTypes = {
  disabled: PropTypes.bool,
  domRef: PropTypes.func,
  height: PropTypes.oneOf(Object.values(Button.HEIGHT)),
  href: PropTypes.string,
  kind: PropTypes.oneOf(Object.values(Button.KIND)),
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(Button.TYPE)),
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  userTextClassName: PropTypes.string,
  width: PropTypes.oneOf(Object.values(Button.WIDTH)),
  withPlus: PropTypes.bool,
  onClick: PropTypes.func
};

Button.defaultProps = {
  disabled: false,
  domRef: null,
  height: Button.HEIGHT.NORMAL,
  href: null,
  kind: Button.KIND.PRIMARY,
  target: null,
  type: Button.TYPE.BUTTON,
  userClassName: null,
  userStyle: null,
  userTextClassName: null,
  width: Button.WIDTH.NORMAL,
  withPlus: false,
  onClick: null
};

export default Button;
