export default class JustADate {
  static now() {
    return (new JustADate()).getTime();
  }

  constructor(initDate = new Date()) {
    this._utcMidnightDateObj = null;

    if ((typeof initDate === 'string') && initDate.match(/[+-]\d{2}:\d{2}|Z$/gm)) {
      this._utcMidnightDateObj = new Date(initDate.substring(0, 10) + 'T00:00:00Z');
    } else if (initDate instanceof JustADate) {
      this._utcMidnightDateObj = new Date(initDate._utcMidnightDateObj);
    } else {
      if (!(initDate instanceof Date)) {
        initDate = new Date(initDate);
      }

      this._utcMidnightDateObj = new Date(Date.UTC(initDate.getFullYear(), initDate.getMonth(), initDate.getDate()));
    }
  }

  toISOString() {
    return this._utcMidnightDateObj.toISOString();
  }

  getTime() {
    return this._utcMidnightDateObj.getTime();
  }

  getUTCDate() {
    return this._utcMidnightDateObj.getUTCDate();
  }

  getDate() {
    return this.getUTCDate();
  }

  getUTCDay() {
    return this._utcMidnightDateObj.getUTCDay();
  }

  getDay() {
    return this.getUTCDay();
  }

  getUTCFullYear() {
    return this._utcMidnightDateObj.getUTCFullYear();
  }

  getFullYear() {
    return this.getUTCFullYear();
  }

  getUTCMonth() {
    return this._utcMidnightDateObj.getUTCMonth();
  }

  getMonth() {
    return this.getUTCMonth();
  }

  setUTCDate(arg) {
    return this._utcMidnightDateObj.setUTCDate(arg);
  }

  setDate(arg) {
    return this.setUTCDate(arg);
  }

  setUTCFullYear(arg) {
    return this._utcMidnightDateObj.setUTCFullYear(arg);
  }

  setFullYear(arg) {
    return this.setUTCFullYear(arg);
  }

  setUTCMonth(arg) {
    return this._utcMidnightDateObj.setUTCMonth(arg);
  }

  setMonth(arg) {
    return this.setUTCMonth(arg);
  }

  addDays(days) {
    return this._utcMidnightDateObj.setUTCDate(this._utcMidnightDateObj.getUTCDate() + days);
  }

  toDate(...args) {
    return new Date(
      this._utcMidnightDateObj.getUTCFullYear(),
      this._utcMidnightDateObj.getUTCMonth(),
      this._utcMidnightDateObj.getUTCDate(),
      ...args
    );
  }

  toUTCDate(...args) {
    return new Date(Date.UTC(
      this._utcMidnightDateObj.getUTCFullYear(),
      this._utcMidnightDateObj.getUTCMonth(),
      this._utcMidnightDateObj.getUTCDate(),
      ...args
    ));
  }

  toString() {
    return this._utcMidnightDateObj.toString();
  }

  toLocaleDateString(locale = 'en', options = {}) {
    return this._utcMidnightDateObj.toLocaleDateString(locale, {
      ...options,
      timeZone: 'UTC'
    });
  }
}
