import crypto from 'crypto';
import firebase from 'firebase/app';
import * as ReactDOM from 'react-dom';

import JustADate from './just-a-date';

const UID_CHARS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
const UID_LENGTH = 28;

let canvas = null;

function _generate(chars, length) {
  return Array.from(crypto.randomBytes(length).values())
    .map(byte => chars[byte % chars.length])
    .join('');
}

export function capitalizeFirstLetter(s) {
  return s[0].toUpperCase() + s.substr(1);
}

export function createWindow(url, open = false) {
  const newWindow = window.open(url);

  if (open && newWindow) {
    newWindow.focus();
  }
}

export function createFullName(firstName, lastName, defaultValue = '') {
  if (!firstName && !lastName) {
    return defaultValue;
  }

  return `${firstName || ''}${(firstName && lastName) ? ' ' : ''}${lastName || ''}`;
}

export function createTimeString(time, date = new JustADate()) {
  const timestamp = (new JustADate(date)).toDate();

  timestamp.setSeconds(time);

  return timestamp.toLocaleTimeString('en', {
    hour: '2-digit',
    minute: '2-digit'
  }).toLowerCase().replace(/\s+/g, '');
}

export function ellipsize(what, when) {
  return (what.length <= (when + 1)) ? what : `${what.substr(0, when)}…`;
}

export function ellipsizeMiddle(what, when) {
  const middle = Math.floor(when / 2);

  return (what.length <= (when + 1)) ? what : `${what.substr(0, middle)}…${what.substr(-middle)}`;
}

export function exposeFields(obj, ...fields) {
  return fields.reduce((acc, propName) => {
    acc[propName] = obj[propName];

    return acc;
  }, {});
}

export function excludeFields(obj, ...fields) {
  const o = {...obj};

  fields
    .filter(propName => hasOwnProperty(obj, propName))
    .forEach(propName => {
      delete o[propName];
    });

  return o;
}

export function findElement(target, element) {
  let el = target;

  while (el) {
    if (el === element) {
      return true;
    }

    el = el.parentNode;
  }

  return false;
}

export function formatDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = `${date.getMonth() + 1}`.toString().padStart(2, '0');
  const year = date.getFullYear().toString().substr(2).toString();

  return `${day}/${month}/${year}`;
}

export function generateUid() {
  return _generate(UID_CHARS, UID_LENGTH);
}

export function getTextWidth(text, font) {
  if (!canvas) {
    canvas = document.createElement('canvas');
  }

  const context = canvas.getContext('2d');

  context.font = font;

  return context.measureText(text).width;
}

export function getWindowSize() {
  return {
    height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  };
}

export function hasOwnProperty(obj, propName) {
  return Object.prototype.hasOwnProperty.call(obj, propName);
}

export function diff(oldObj, newObj) {
  return Object.keys(newObj).reduce((acc, key) => {
    const newValue = newObj[key];

    if (hasOwnProperty(oldObj, key)) {
      const oldValue = oldObj[key];

      const newType = typeof newValue;
      const oldType = typeof oldValue;

      if (newType !== oldType) {
        acc[key] = newValue;
      } else if (newType && (newType === 'object')) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          acc[key] = newValue;
        }
      } else if (newValue !== oldValue) {
        acc[key] = newValue;
      }
    } else {
      acc[key] = newValue;
    }

    return acc;
  }, {});
}

export function measureElement(element) {
  // eslint-disable-next-line react/no-find-dom-node
  const node = ReactDOM.findDOMNode(element);

  return {
    width: node.offsetWidth,
    height: node.offsetHeight
  };
}

export function numberFromString(s) {
  if (s === '') {
    return NaN;
  }

  return Number(s);
}

export function isExternalUrl(href) {
  return /^(https?:)?\/\//.test(href);
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function copyTextToClipboard(text) {
  const textarea = document.createElement('textarea');
  textarea.style.position = 'fixed';
  textarea.style.top = '0';
  textarea.style.left = '0';
  textarea.style.opacity = '0';
  textarea.innerText = text;

  const parentElement = document.getElementsByTagName('body')[0];
  parentElement.appendChild(textarea);

  textarea.select();
  document.execCommand('copy');

  parentElement.removeChild(textarea);
}

export function datesEqual(d1, d2) {
  return (d1.getFullYear() === d2.getFullYear()) &&
    (d1.getMonth() === d2.getMonth()) &&
    (d1.getDate() === d2.getDate());
}

export function getTime(date = new Date()) {
  return (date.getUTCHours() * 60 * 60) + (date.getUTCMinutes() * 60) + date.getUTCSeconds();
}

export function insertBetweenElements(array, element) {
  const newArray = array.slice(0);

  if (newArray.length < 2) {
    return newArray;
  }

  for (let i = newArray.length - 1; i > 0; --i) {
    newArray.splice(i, 0, element);
  }

  return newArray;
}

export function parseTime(timeString) {
  if (!timeString) {
    return null;
  }

  const time = timeString.match(/^(\d+)(:(\d\d))?\s*((a|(p))m?)?$/i);

  if (time === null) {
    return null;
  }

  let hours = parseInt(time[1], 10);

  if (hours === 12 && !time[6]) {
    hours = 0;
  } else {
    hours += ((hours < 12) && time[6]) ? 12 : 0;
  }

  return (hours * 60 * 60) + ((parseInt(time[3], 10) || 0) * 60);
}

export function simpleHash(s) {
  let a = 1;
  let c = 0;
  let h;
  let o;

  if (s) {
    a = 0;

    for (h = s.length - 1; h >= 0; h--) {
      o = s.charCodeAt(h);
      a = ((a << 6) & 268435455) + o + (o << 14);
      c = a & 266338304;
      a = (c === 0) ? a : (a ^ (c >> 21));
    }
  }

  return String(a);
}

export function stringifyQuery(query) {
  return Object.keys(query).map(key => {
    const value = query[key];

    if (!Array.isArray(value)) {
      return `${key}=${encodeURIComponent(value)}`;
    }

    return value.map(v => `${key}=${encodeURIComponent(v)}`).join('&');
  }).join('&');
}

export async function callCloudFunction(name, data) {
  const response = await firebase.functions().httpsCallable(name)(data);

  const {error, result} = response.data;

  if (error) {
    throw new Error(error);
  }

  return result;
}
