import React, {useRef, useState} from 'react';

import {getCreateCompanyErrors} from '../../../lib/form/error-getters';
import {CREATE_COMPANY_FORM_FIELDS} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';

import {excludeFields, exposeFields} from '../../../lib/helper';

import useFirebaseEntity from '../../../hooks/use-firebase-entity';

import ContentContainer from '../../common/content-container';
import ContentTable from '../../common/content-table';
import Dialog from '../../common/dialog';
import Form from '../../common/form';
import Page from '../../common/page';
import Sidebar from '../../common/sidebar';

import styles from './styles.less';

const CompaniesPage = () => {
  const [
    items,
    {hasFetched, isCreating, isUpdating},
    {create, update}
  ] = useFirebaseEntity('companies');

  const formSubmitCallbackRef = useRef(null);

  const [search, setSearch] = useState('');
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = [{
    style: {
      textAlign: 'left'
    },
    title: 'Name'
  }];

  const rows = items.map(({id, ...rest}) => ({
    key: id,
    data: {
      id,
      ...rest
    }
  }));

  const filterRow = data => {
    return data.name.toLowerCase().includes(search.toLowerCase());
  };

  const renderRowCell = (data, index) => {
    switch (index) {
    case 0:
      return (
        <div className={styles['cell-name']}>
          <div>{data.name}</div>
        </div>
      );
    default:
      return null;
    }
  };

  const handleAddButtonClick = () => {
    setSelectedRow(null);
    setDialogVisible(true);
  };

  const handleRowClick = ({data}) => {
    setSelectedRow(data);
    setDialogVisible(true);
  };

  const handleFormSubmit = async data => {
    if (selectedRow) {
      const success = await update(selectedRow.id, {
        ...excludeFields(data, 'isSubscribed'),
        subscriptions: {
          ...selectedRow?.subscriptions,
          content: Boolean(data.isSubscribed)
        }
      });

      if (!success) {
        throw new Error('Please, try again');
      }
    } else {
      const id = await create({
        ...excludeFields(data, 'isSubscribed'),
        subscriptions: {
          content: Boolean(data.isSubscribed)
        }
      });

      if (!id) {
        throw new Error('Please, try again');
      }
    }

    setDialogVisible(false);
  };

  const defaultValues = selectedRow ? {
    ...exposeFields(selectedRow, 'name'),
    isSubscribed: Boolean(selectedRow?.subscriptions?.content)
  } : {
    isSubscribed: false
  };

  return (
    <Page title="Companies">
      <div className={styles.content}>
        <Sidebar
          items={[{
            path: '/tenants',
            text: 'Tenants'
          }, {
            path: '/buildings',
            text: 'Buildings'
          }, {
            path: '/companies',
            text: 'Companies'
          }]}
        />
        <ContentContainer
          addButtonText="New Company"
          search={search}
          title="Manage Companies"
          onAddButtonClick={handleAddButtonClick}
          onSearchChange={setSearch}
        >
          <ContentTable
            columns={columns}
            hasFetched={hasFetched}
            rows={rows}
            filterRow={filterRow}
            renderRowCell={renderRowCell}
            onRowClick={handleRowClick}
          />
        </ContentContainer>
        {
          isDialogVisible && (
            <Dialog
              buttonAcceptDisabled={isCreating || isUpdating}
              buttonAcceptText={selectedRow ? 'Save' : 'Create Company'}
              title={selectedRow ? 'Edit Company' : 'Add a New Company'}
              onAccept={() => formSubmitCallbackRef.current()}
              onReject={() => setDialogVisible(false)}
            >
              <div className={styles['dialog-content']}>
                <div className={styles['dialog-form-wrapper']}>
                  <Form
                    defaultValues={defaultValues}
                    fields={CREATE_COMPANY_FORM_FIELDS}
                    submitCallbackRef={formSubmitCallbackRef}
                    onSubmit={handleFormSubmit}
                    onSubmitFailure={handleSubmitFailure(getCreateCompanyErrors)}
                  />
                </div>
              </div>
            </Dialog>
          )
        }
      </div>
    </Page>
  );
};

export default CompaniesPage;
