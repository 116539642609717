import React from 'react';
import PropTypes from 'prop-types';

import {Form as ReactForm} from 'react-form';

import classNames from 'classnames';

import Button from '../../common/button';
import ClickableText from '../../common/clickable-text';

import FormField from './form-field';

import styles from './styles.less';

const ACTION_ALIGNMENT = {
  CENTER: 'center',
  START: 'start',
  END: 'end'
};

const ACTION_TYPE = {
  ...Button.KIND,
  TEXT: 'text'
};

const ACTION_SHAPE = PropTypes.shape({
  buttonWidth: PropTypes.oneOf(Object.values(Button.WIDTH)),
  href: PropTypes.string,
  isPrimary: PropTypes.bool,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(ACTION_TYPE)),
  onClick: PropTypes.func
});

const FIELD_SHAPE = PropTypes.shape({
  disabled: PropTypes.bool,
  margin: PropTypes.number,
  render: PropTypes.func,
  size: PropTypes.number,
  spacing: PropTypes.number,
  type: PropTypes.oneOf(Object.values(FormField.TYPE).concat(['row', 'spacer'])).isRequired,
  zeroBasis: PropTypes.bool
});

FIELD_SHAPE.columns = PropTypes.arrayOf(PropTypes.shape({
  fields: PropTypes.arrayOf(PropTypes.shape(FIELD_SHAPE)).isRequired,
  weight: PropTypes.number
}));

const Action = ({action, disabled}) => {
  const {buttonWidth, href, isPrimary, text, type, onClick} = action;

  const isText = type === Form.ACTION_TYPE.TEXT;

  if (isPrimary || !isText) {
    const buttonKind = type || (isPrimary ? Button.KIND.PRIMARY : Button.KIND.SECONDARY);
    const buttonType = isPrimary ? Button.TYPE.SUBMIT : Button.TYPE.BUTTON;

    return (
      <Button
        disabled={isPrimary && disabled}
        href={isPrimary ? null : href}
        text={text}
        kind={buttonKind}
        type={buttonType}
        width={buttonWidth || Button.WIDTH.NORMAL}
        onClick={isPrimary ? null : onClick}
      />
    );
  }

  if (isText) {
    return (
      <div className={styles['action-text-wrapper']}>
        <ClickableText href={href} text={text} onClick={onClick}/>
      </div>
    );
  }

  return (
    <Button kind={type} text={text} onClick={onClick}/>
  );
};

Action.propTypes = {
  action: ACTION_SHAPE.isRequired,
  disabled: PropTypes.bool
};

Action.defaultProps = {
  disabled: false
};

const Field = ({disabled, field, formApi}) => {
  const fieldStyles = {};

  if (typeof field.margin === 'number') {
    fieldStyles.marginTop = `${field.margin}px`;
  }

  if (field.type === 'row') {
    const columns = field.columns.filter(({predicate}) => !predicate || predicate(formApi));

    const hasWeights = columns.some(({weight}) => weight >= 0);

    return (
      <div className={styles.row} style={fieldStyles}>
        {
          /* eslint-disable react/no-array-index-key */
          columns.map(({fields, weight}, index) => {
            const columnStyles = {};

            if (field.zeroBasis) {
              columnStyles.flexBasis = 0;
            } else if (!hasWeights) {
              columnStyles.flexBasis = `${100 / columns.length}%`;
            }

            if ((index > 0) && (field.spacing >= 0)) {
              columnStyles.marginLeft = `${field.spacing}px`;
            }

            if (weight >= 0) {
              columnStyles.flexGrow = weight;
            }

            return (
              <div key={index} className={styles.column} style={columnStyles}>
                {
                  fields
                    .filter(({predicate}) => !predicate || predicate(formApi))
                    .map((subField, subIndex) => (
                      <Field
                        key={`field-${subField.name || subField.type}-${subIndex}`}
                        field={subField}
                        formApi={formApi}
                        index={subIndex}
                      />
                    ))
                }
              </div>
            );
          })
          /* eslint-enable react/no-array-index-key */
        }
      </div>
    );
  }

  if (field.type === 'spacer') {
    return (
      <div style={{height: `${field.size}px`, ...fieldStyles}}/>
    );
  }

  return (
    <div className={styles['field-wrapper']} style={fieldStyles}>
      <FormField {...field} disabled={disabled || field.disabled}/>
    </div>
  );
};

Field.propTypes = {
  disabled: PropTypes.bool,
  field: FIELD_SHAPE.isRequired,
  formApi: PropTypes.object.isRequired
};

Field.defaultProps = {
  disabled: false
};

const Form = props => {
  const {
    formApiRef,
    submitCallbackRef,
    actionAlignment,
    actions,
    children,
    defaultValues,
    disabled,
    error,
    fields,
    title,
    validate,
    onChange,
    onSubmit,
    onSubmitFailure
  } = props;

  const setSubmitCallbackRef = el => {
    const f = () => {
      el.click();
    };

    if (typeof submitCallbackRef === 'function') {
      submitCallbackRef(f);
    } else if (submitCallbackRef && (typeof submitCallbackRef === 'object')) {
      submitCallbackRef.current = f;
    }
  };

  const doValidate = values => {
    // Required to reset global form error

    const result = validate ? validate(values) : {};

    return {_error: null, ...result};
  };

  const alignCenter = actionAlignment === ACTION_ALIGNMENT.CENTER;

  const actionsClassNames = classNames({
    [styles.actions]: true,
    [styles['space-between']]: alignCenter && (actions.length > 1),
    [styles.center]: alignCenter && (actions.length === 1),
    [styles.start]: actionAlignment === ACTION_ALIGNMENT.START,
    [styles.end]: actionAlignment === ACTION_ALIGNMENT.END
  });

  return (
    <ReactForm
      defaultValues={defaultValues}
      validate={doValidate}
      onChange={onChange}
      onSubmit={onSubmit}
      onSubmitFailure={onSubmitFailure}
    >
      {
        formApi => {
          const {errors, submitForm} = formApi;

          if (typeof formApiRef === 'function') {
            formApiRef(formApi);
          } else if (formApiRef && (typeof formApiRef === 'object')) {
            formApiRef.current = formApi;
          }

          return (
            <form className={styles.form} onSubmit={submitForm}>
              {
                title && (
                  <h5 className={styles.title}>{title}</h5>
                )
              }
              {
                children && (
                  <div className={styles['children-container']}>
                    {children}
                  </div>
                )
              }
              {
                fields && (fields.length > 0) && (
                  <fieldset className={styles.fields}>
                    {
                      /* eslint-disable react/no-array-index-key */
                      fields
                        .filter(({predicate}) => !predicate || predicate(formApi))
                        .map((field, index) => (
                          <Field
                            key={`field-${field.name || field.type}-${index}`}
                            field={field}
                            formApi={formApi}
                            index={index}
                          />
                        ))
                      /* eslint-enable react/no-array-index-key */
                    }
                  </fieldset>
                )
              }
              {
                (actions.length > 0) && (
                  <div className={actionsClassNames}>
                    {
                      actions.map(action => (
                        <Action
                          key={`action-${action.isPrimary}-${action.type}-${action.text}`}
                          action={action}
                          disabled={disabled}
                        />
                      ))
                    }
                  </div>
                )
              }
              {
                (error || (errors && errors._error)) && (
                  <div className={styles.error}>
                    {error || errors._error}
                  </div>
                )
              }
              <button ref={setSubmitCallbackRef} type="submit" style={{display: 'none'}}/>
            </form>
          );
        }
      }
    </ReactForm>
  );
};

Form.propTypes = {
  formApiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  submitCallbackRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  actionAlignment: PropTypes.oneOf(Object.values(ACTION_ALIGNMENT)),
  actions: PropTypes.arrayOf(PropTypes.shape({
    isPrimary: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(ACTION_TYPE)),
    buttonWidth: PropTypes.oneOf(Object.values(Button.WIDTH)),
    text: PropTypes.string.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func
  })),
  children: PropTypes.node,
  defaultValues: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fields: PropTypes.arrayOf(FIELD_SHAPE),
  title: PropTypes.string,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitFailure: PropTypes.func
};

Form.defaultProps = {
  formApiRef: null,
  submitCallbackRef: null,
  actionAlignment: ACTION_ALIGNMENT.CENTER,
  actions: [],
  children: null,
  defaultValues: null,
  disabled: false,
  error: null,
  fields: null,
  title: null,
  validate: null,
  onChange: null,
  onSubmit: null,
  onSubmitFailure: null
};

Form.ACTION_ALIGNMENT = ACTION_ALIGNMENT;
Form.ACTION_TYPE = ACTION_TYPE;

export default Form;
