import {promisifiedDispatch} from '@ololoepepe/redux-api';

import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import {getCreateTemplateErrors, getEditTemplateErrors} from '../../../lib/form/error-getters';
import {createAddTemplateFormFields} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';

import {excludeFields} from '../../../lib/helper';

import Tags from '../../../redux/ducks/tags';
import Templates from '../../../redux/ducks/templates';

import Button from '../../common/button';
import ClickableText from '../../common/clickable-text';
import Form from '../../common/form';
import Page from '../../common/page';

import styles from './styles.less';

const TemplateListItem = ({active, name, onClick}) => {
  const templateListItemClassNames = classNames({
    [styles['template-list-item']]: true,
    [styles['template-list-item-active']]: active
  });

  return (
    <div className={templateListItemClassNames} onClick={onClick}>
      {name}
    </div>
  );
};

TemplateListItem.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

TemplateListItem.defaultProps = {
  active: false
};

const TemplateNameField = ({fieldApi, fieldProps, isCreatingTemplate, isUpdatingOrRemoving}) => {
  const {error, value, setTouched, setValue} = fieldApi;
  const {disabled, name, placeholder, onChange} = fieldProps;

  const [isNameFieldLineVisible, setNameFieldLineVisible] = useState(false);

  const handleBlur = () => {
    // Prevent passing arguments to setValue

    setTouched();
    setNameFieldLineVisible(false);
  };

  const handleChange = e => {
    const {value} = e.target;

    if (onChange) {
      onChange(value, setValue);
    } else {
      setValue(value);
    }
  };

  const handleFocus = () => {
    setNameFieldLineVisible(true);
  };

  const lineClassNames = classNames({
    [styles['template-name-line']]: true,
    [styles['template-name-line-error']]: error
  });

  return (
    <div className={styles['template-name-field']}>
      <input
        className={styles['template-name-input']}
        disabled={disabled || isUpdatingOrRemoving || isCreatingTemplate}
        name={name}
        placeholder={placeholder}
        value={value || ''}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      {
        (isNameFieldLineVisible || error) && (
          <div className={lineClassNames}/>
        )
      }
    </div>
  );
};

TemplateNameField.propTypes = {
  fieldApi: PropTypes.shape({
    error: PropTypes.any,
    value: PropTypes.string,
    setTouched: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired
  }).isRequired,
  fieldProps: PropTypes.shape({
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
  }).isRequired,
  isCreatingTemplate: PropTypes.bool.isRequired,
  isUpdatingOrRemoving: PropTypes.bool.isRequired
};

const TemplatesPage = () => {
  const dispatch = useDispatch();

  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [isCreateNewTemplateTemplateFormVisible, setCreateNewTemplateTemplateFormVisible] = useState(false);

  const tags = useSelector(state => state.tags.items.map(({name}) => name));

  const templates = useSelector(state => state.templates.items);
  const isCreatingTemplate = useSelector(state => state.templates.isCreating);

  const instructors = useSelector(state => state.instructors.items);

  const templateFormSubmitCallbackRef = useRef(null);

  const handleListItemClick = id => () => {
    setSelectedTemplateId(id);
    setCreateNewTemplateTemplateFormVisible(false);
  };

  const handleAddTemplateClick = () => {
    setSelectedTemplateId(null);
    setCreateNewTemplateTemplateFormVisible(true);
  };

  const selectedTemplate = selectedTemplateId && templates.find(ec => ec.id === selectedTemplateId);

  const templateContainerClassNames = classNames({
    [styles['template-container']]: true,
    [styles['template-container-empty']]: templates.length < 1
  });

  const handleSubmitTemplateForm = async ({capacity, duration, timeStart, ...rest}) => {
    const actionCreator = selectedTemplate ? Templates.actions.update : Templates.actions.create;
    const payload = {
      data: {
        ...rest,
        capacity: Number(capacity),
        duration: Number(duration),
        timeStart: Number(timeStart)
      }
    };

    if (selectedTemplate) {
      payload.id = selectedTemplateId;
    }

    const response = await promisifiedDispatch(dispatch, actionCreator, payload);

    setSelectedTemplateId(response.data.item.id);
    setCreateNewTemplateTemplateFormVisible(false);
  };

  const getTemplateFormErrors = selectedTemplateId ? getEditTemplateErrors : getCreateTemplateErrors;

  const handleTemplateDelete = async () => {
    await promisifiedDispatch(dispatch, Templates.actions.remove, {id: selectedTemplateId});

    setSelectedTemplateId(null);
  };

  const templateFormDefaultValues = selectedTemplate ? {
    ...excludeFields(selectedTemplate, 'id'),
    capacity: selectedTemplate.capacity.toString(),
    duration: selectedTemplate.duration.toString(),
    timeStart: selectedTemplate.timeStart.toString()
  } : null;

  const isUpdatingOrRemoving = Boolean(
    selectedTemplate && (selectedTemplate.isUpdating || selectedTemplate.isRemoving)
  );

  const renderTemplateNameField = props => {
    return (
      <TemplateNameField
        {...props}
        isCreatingTemplate={isCreatingTemplate}
        isUpdatingOrRemoving={isUpdatingOrRemoving}
      />
    );
  };

  const renderDeleteTemplateField = () => {
    return (
      <div className={styles['delete-template-field']}>
        <ClickableText
          disabled={isUpdatingOrRemoving || isCreatingTemplate}
          text="Delete Template"
          userClassName={styles['delete-template-text']}
          onClick={handleTemplateDelete}
        />
      </div>
    );
  };

  const handleSaveChangesClick = () => templateFormSubmitCallbackRef.current();

  const renderSaveChangesField = () => {
    return (
      <div className={styles['save-changes-field']}>
        <Button
          disabled={isUpdatingOrRemoving || isCreatingTemplate}
          text="Save Changes"
          onClick={handleSaveChangesClick}
        />
      </div>
    );
  };

  const handleCustomTagAdded = async tag => {
    const result = await promisifiedDispatch(dispatch, Tags.actions.create, {
      data: {
        name: tag
      }
    });

    return result.data.item.name;
  };

  const templateFormFields = (selectedTemplate && !isCreateNewTemplateTemplateFormVisible) ?
    createAddTemplateFormFields({
      instructors,
      renderDeleteTemplateField,
      renderSaveChangesField,
      renderTemplateNameField,
      tags,
      handleCustomTagAdded
    }) :
    createAddTemplateFormFields({
      instructors,
      renderSaveChangesField,
      renderTemplateNameField,
      tags,
      handleCustomTagAdded
    });

  return (
    <Page title="Templates">
      <div className={styles.content}>
        <div className={styles['template-list-container']}>
          <div className={styles['template-list']}>
            {
              templates.map(ec => (
                <TemplateListItem
                  key={ec.id}
                  active={ec.id === selectedTemplateId}
                  name={ec.name}
                  onClick={handleListItemClick(ec.id)}
                />
              ))
            }
          </div>
          <div className={styles['new-template-button-wrapper']}>
            <Button
              height={Button.HEIGHT.SMALL}
              text="New Template"
              width={Button.WIDTH.FILL}
              onClick={handleAddTemplateClick}
            />
          </div>
        </div>
        <div className={templateContainerClassNames}>
          {
            (templates.length > 0) ? (
              (selectedTemplate || isCreateNewTemplateTemplateFormVisible) ? (
                <Form
                  key={(selectedTemplate && !isCreateNewTemplateTemplateFormVisible) ? selectedTemplateId : 'template'}
                  submitCallbackRef={templateFormSubmitCallbackRef}
                  defaultValues={templateFormDefaultValues}
                  disabled={isCreatingTemplate || isUpdatingOrRemoving}
                  fields={templateFormFields}
                  onSubmit={handleSubmitTemplateForm}
                  onSubmitFailure={handleSubmitFailure(getTemplateFormErrors)}
                />
              ) : null
            ) : (
              <div className={styles['template-dummy']}>
                <div className={styles['template-dummy-image']}/>
                <div className={styles['template-dummy-title']}>No templates to display</div>
                <div className={styles['template-dummy-description']}>
                  Once you create a template it will show up here.
                </div>
                <div className={styles['template-dummy-button-wrapper']}>
                  <Button
                    text="Create Your First Template"
                    width={Button.WIDTH.FILL}
                    onClick={handleAddTemplateClick}
                  />
                </div>
              </div>
            )
          }
        </div>
      </div>
    </Page>
  );
};

export default TemplatesPage;
