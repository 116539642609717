import {createDuck, getInstance} from '../../lib/firebase-provider';

function _createHandler(apiInstanceMethod, flagPropName, errorPropName) {
  return {
    handleRequest: params => getInstance()[apiInstanceMethod](params),
    handlePending: state => {
      return {
        ...state,
        [flagPropName]: true
      };
    },
    handleSuccess: state => {
      return {
        ...state,
        [flagPropName]: false,
        [errorPropName]: null
      };
    },
    handleError: (state, {payload}) => {
      return {
        ...state,
        [flagPropName]: false,
        [errorPropName]: payload.error
      };
    }
  };
}

export default createDuck('auth', {}, {
  customApiActionHandlers: {
    logIn: _createHandler('logIn', 'isLoggingIn', 'loginError'),
    logOut: _createHandler('logOut', 'isLoggingOut', 'logoutError'),
    sendPasswordResetEmail: _createHandler(
      'sendPasswordResetEmail',
      'isSendingPasswordResetEmail',
      'sendPasswordResetEmailError'
    )
  },
  reducerOptions: {
    customInitialState: {
      isLoggingIn: false,
      isLoggingOut: false,
      isSendingPasswordResetEmail: false,
      loginError: null,
      logoutError: null,
      sendPasswordResetEmailError: null
    }
  },
  single: true
});
